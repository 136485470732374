import { faColumns3 } from '@fortawesome/pro-regular-svg-icons'
import { Modify } from '@teinor/erp'
import { useCallback, useState } from 'react'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { ComponentTabObject } from '../../../../utils/hooks/useTabLogic'
import ModalTaskConfigColumns from './ModalTaskConfigColumns'
import ModalTaskConfigColumnsFilters from './ModalTaskConfigColumnsFilters'
import ModalTaskConfigColumnsOrder from './ModalTaskConfigColumnsOrder'
import ModalTabs from '../../../../baseComponents/ModalsLayouts/ModalTabs'
import { ModalComponentPropsWithoutSubmit } from '../../../../baseComponents/ModalsLayouts/types'
import { ConfigColumnsColumn } from '../../../../baseComponents/ModalsLayouts/ModalConfigColumnsTabs/types'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { changeEmployeeParams } from '../../../../utils/reducers/reduxDispatch'
import { ButtonsLoadFiltersStateFull } from '../../../../baseComponents/Button/ButtonLoadFilter'
import { ButtonSecondary } from '../../../../baseComponents/Button'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { ModalOpenedTy } from '../../../../baseComponents/Modal/types'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import { DictTaskTableHeaderTy, ModalTaskConfigColumnsBaseProps } from './types'
import ModalTaskConfigColumnsHeader from './ModalTaskConfigColumnsHeader'
import { getTaskOnlyDefaultColsAndHeader, getTaskTableSelectedColumns, SelectedTaskColsFiltersHeader } from '../../../../utils/hooks/tasks/useTaskConfigColumns'
import { TableInstanceType } from '../../../../baseComponents/TwinTable/types'
import { addHeadersToTmpColumnsMTC } from './functions'

interface ModalTaskConfigColumnsTabsProps extends ModalTaskConfigColumnsTabsLogicProps {
    haveFiltersColumn?: boolean
}

const ModalTaskConfigColumnsTabs: React.FC<ModalTaskConfigColumnsTabsProps> = ({ setOpened, haveFiltersColumn = true, ...rest }) => {
    const { t } = useTwinTranslation()
    const logic = useModalTaskConfigColumnsTabsLogic({ setOpened, ...rest })
    const tabs: ComponentTabObject = {
        'main': { component: ModalTaskConfigColumns, text: t('configColumns', 'Configurar columnas') },
    }
    if (haveFiltersColumn) {
        tabs['filters'] = { component: ModalTaskConfigColumnsFilters, text: t('configFilters', 'Configurar filtros') }
        tabs['order'] = { component: ModalTaskConfigColumnsOrder, text: t('configOrder', 'Configurar orden') }
    }
    tabs['header'] = { component: ModalTaskConfigColumnsHeader, text: t('configHeader', 'Configurar cabecera') }
    return (
        <ModalTabs tabs={tabs} setOpened={setOpened} onSubmit={() => setOpened(null)} extraComponentData={logic}></ModalTabs>
    )
}

interface ModalTaskConfigColumnsTabsLogicProps extends ModalComponentPropsWithoutSubmit {
    setSelectedColsFiltersHeader: React.Dispatch<React.SetStateAction<SelectedTaskColsFiltersHeader>>
    selectedColsFiltersHeader: SelectedTaskColsFiltersHeader
    columns: ConfigColumnsColumn<any>[]
    headers: DictTaskTableHeaderTy
    name: string
    tableInstance: TableInstanceType
}


const useModalTaskConfigColumnsTabsLogic = ({ selectedColsFiltersHeader, columns, setSelectedColsFiltersHeader, setOpened, name, headers, tableInstance }: ModalTaskConfigColumnsTabsLogicProps): ModalTaskConfigColumnsBaseProps => {
    const [tmpColumns, setTmpColumns] = useState<SelectedTaskColsFiltersHeader>(addHeadersToTmpColumnsMTC(selectedColsFiltersHeader, headers))

    const updateFilterOnTable = useCallback(async (columns: SelectedTaskColsFiltersHeader, id: number) => {
        delete columns.id
        delete columns.name
        const myValue = JSON.stringify(columns)
        await twinFetchPostJSON('/api/app/config/filter/updateFilter', { id, value: myValue })
    }, [])

    const updateEmployeeParams = useCallback(async (columns: SelectedTaskColsFiltersHeader) => {
        delete columns.id
        delete columns.name
        const myName = 'table_' + name
        const myValue = JSON.stringify(columns)
        await twinFetchPostJSON('/api/app/config/employee/updateEmployeeParameter', { parameterKey: myName, value: myValue })
        changeEmployeeParams({ [myName]: myValue })
    }, [name])

    const updateParamsDecider = useCallback(async (columns: SelectedTaskColsFiltersHeader) => {
        if (tmpColumns.id && String(tmpColumns.id) !== '0') {
            await updateFilterOnTable(columns, tmpColumns.id)
        } else {
            await updateEmployeeParams(columns)
        }
    }, [tmpColumns.id, updateFilterOnTable, updateEmployeeParams])

    const onSave = useCallback(() => {
        if (!Object.keys(tmpColumns.selectedColumns).length) {
            const defaultCols = getTaskOnlyDefaultColsAndHeader(columns, headers)
            const myColumns = { ...defaultCols, filters: tmpColumns.filters, id: tmpColumns.id, name: tmpColumns.name }
            setSelectedColsFiltersHeader(myColumns)
            updateParamsDecider(myColumns)
        } else {
            setSelectedColsFiltersHeader({ ...tmpColumns })
            updateParamsDecider({ ...tmpColumns })
        }
        tableInstance?.changeOffset(0)
        setOpened(null)
        return false
    }, [tmpColumns, setSelectedColsFiltersHeader, setOpened, columns, updateParamsDecider, headers, tableInstance])

    return { tmpColumns, setTmpColumns, onSave, columns, selectedColsFiltersHeader, name, headers}
}


type ButtonTaskConfigColumnsProps = Modify<ModalTaskConfigColumnsTabsProps, {
    setOpened?: never
    hideLoadFiltersButton?: boolean
}>

export const ButtonTaskConfigColumnsWithModal: React.FC<ButtonTaskConfigColumnsProps> = ({ selectedColsFiltersHeader, setSelectedColsFiltersHeader, hideLoadFiltersButton, tableInstance, ...rest }) => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const { t } = useTwinTranslation()
    const filtersLength = Object.keys(selectedColsFiltersHeader?.filters || {}).length
    const isCustomFilter = selectedColsFiltersHeader.id && String(selectedColsFiltersHeader.id) !== '0'
    const name = isCustomFilter && selectedColsFiltersHeader.name ? selectedColsFiltersHeader.name : t('byDefault', 'Por defecto')
    const defaultValues = getTaskTableSelectedColumns(rest.name, rest.columns, rest.headers)
    const { onChangeFilter } = useButtonTaskConfigColumnsWithModalLogic({ tableInstance,  setSelectedColsFiltersHeader })
    return (
        <div className='button_config_columns_modal flex items-center'>
            {filtersLength > 0 || isCustomFilter ? <span className='medium16 mr-15'><TwinTrans transKey='configuration'>Configuración</TwinTrans>: {name} ({filtersLength})</span> : null}
            {hideLoadFiltersButton ? null : <ButtonsLoadFiltersStateFull allowCreate={false} selectedFilters={selectedColsFiltersHeader} onChangeFilter={(json) => onChangeFilter(json)} tableName={rest.name} defaultValues={defaultValues} className='load_button_filter' />}
            <ButtonSecondary onClick={() => setOpened(true)} className='h-34'>
                <TwinIcon icon={faColumns3} />
                <span className='ml-10 load_columns_button_text'><TwinTrans transKey='configColumns'>Configurar columnas</TwinTrans></span>
            </ButtonSecondary>
            {opened && <ModalTaskConfigColumnsTabs {...rest} setOpened={setOpened} selectedColsFiltersHeader={selectedColsFiltersHeader} setSelectedColsFiltersHeader={setSelectedColsFiltersHeader} tableInstance={tableInstance} />}
        </div>
    )
}

interface ButtonTaskConfigColumnsWithModalLogicProps {
    setSelectedColsFiltersHeader: React.Dispatch<React.SetStateAction<SelectedTaskColsFiltersHeader>>
    tableInstance: TableInstanceType
}

const useButtonTaskConfigColumnsWithModalLogic = ({ setSelectedColsFiltersHeader, tableInstance }: ButtonTaskConfigColumnsWithModalLogicProps) => {
    const onChangeFilter = useCallback((json: TwinDictionary) => {
        setSelectedColsFiltersHeader({ filters: json?.value?.filters || {}, selectedColumns: json?.value?.selectedColumns || {}, id: json.id, name: json.name, order: json?.value?.order || {}, selectedHeader: json?.value?.selectedHeader || {} })
        tableInstance?.changeOffset(0)
    }, [setSelectedColsFiltersHeader, tableInstance])

    return { onChangeFilter }
}

export default ModalTaskConfigColumnsTabs