import { TaskRepositoryModelType } from '@teinor/erp/types/company/task/taskRepository'
import { MediumModalCreateEdit } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import { FormRender } from '../../../../../../../forms/FormRenderer/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { getCustomers } from '../../../../../../../utils/reducers/getters'
import CustomSelectSectionCustomers from '../../../../../../../specificComponents/Customers/CustomSelectSectionCustomers'
import { returnClassOfSpanTemplate } from '../../../../../../../utils/globals/tailwind'


interface ModalCreateTaskRepositoryProps extends ModalEditComponentProps { }

const ModalCreateTaskRepository: React.FC<ModalCreateTaskRepositoryProps> = ({ userPermissions, allRowData, ...rest }) => {
    const { t } = useTwinTranslation()
    const customers = getCustomers()
    const fields: FormRender<TaskRepositoryModelType> = [
        {
            cols: 3,
            elements: [
                {
                    name: 'title',
                    label: t('title', 'Título'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                    className: returnClassOfSpanTemplate(2)
                },
                {
                    name: 'CustomerId',
                    component: 'Custom',
                    render: ({ extraProps }) => <CustomSelectSectionCustomers name='CustomerId' customers={extraProps.customers} label={t('customer', 'Cliente')} />,
                    extraProps: { customers }
                },
            ]
        },
        {
            cols: 1,
            elements: [
                {
                    name: 'description',
                    label: t('description', 'Description'),
                    component: 'TwinEditor',
                    className: 'task_repository_description',

                },
            ]
        }
    ]
    return (<MediumModalCreateEdit fields={fields} url={'/api/app/task/repository/createTaskRepository'} translations={{
        title: t('createIssue', 'Crear tema'),
        button: t('create', 'Crear')
    }} haveButtonPermissions={true} {...rest} />)
}

export default ModalCreateTaskRepository