import { Fragment, useCallback, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ModalOpenedTy } from '../../../../../baseComponents/Modal/types'
import SearchBar from '../../../../../baseComponents/SearchBar'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { stringMatchWithSearch } from '../../../../../utils/globals/search'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { AllReduxAppPayloads, GlobalContext } from '../../../../../utils/reducers'
import { Company, CompanyPayload, UserCompanyInvite, UserFromCompany } from '../../../../../utils/reducers/globals/company'
import UserBox from './UserBox'
import UsersModal from './UsersModal'
import './users_company.sass'
import { ButtonSecondary } from '../../../../../baseComponents/Button'
import { twinFetchPostJSON, twinFetchPostText } from '../../../../../utils/globals/data'
import ModalDeleteFilled from '../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { changeCompany } from '../../../../../utils/reducers/reduxDispatch'
import useEditDeleteModal, { UseEditDeleteModalProps } from '../../../../../utils/hooks/useEditDeleteModal'

type UsersCompanyProps = ReduxUsersCompanyConnector & {

}

const UsersCompany: React.FC<UsersCompanyProps> = ({ company }) => {
    const { t } = useTwinTranslation() 
    const { searchUsers, setSearchUsers, openModal, setOpenModal, handleDelete } = useUsersCompanyLogic({CompanyId: company?.id || 0})
    if (!company) {
        return null
    }
    return (
       <div className='users_company_box bg-white rounded-xl shadow-sm flex flex-auto flex-col'>
            <HeaderUsersCompany company={company} />
            <SearchBar className='mt-32 search_bar_users_company' placeholder={t('searchUserbyEmail', 'Busca usuarios por nombre, email...')} onChange={(value) => setSearchUsers(value)} searchValue={searchUsers} />
            <PrintUsersBox searchValue={searchUsers} users={company.Users} userInvites={company.UserInvites} myUsersType={company.MyUserType} CompanyId={company.id} setOpenModal={setOpenModal}/>
            {openModal && <ModalDeleteFilled opened={openModal?.type === 'delete' ? true : null} setOpened={() => setOpenModal(null)} onCancel={() => setOpenModal(null)} onAccept={handleDelete} translations={{
                title: t('sureDeleteAccount', '¿Seguro quieres eliminar esta cuenta?'),
                subtitle: t('onceDeletedNotRecover', 'Una vez eliminada no podrás recuperarla')
            }} />
            }
        </div>
    )
}

interface UsersCompanyLogicProps  {
    CompanyId: number
}


export const useUsersCompanyLogic = ({ CompanyId }: UsersCompanyLogicProps) => {
    const [searchUsers, setSearchUsers] = useState<string>('')
    const { openModal, setOpenModal } = useEditDeleteModal()
    const getCompanyData = useCallback(async () => {
        const company = await twinFetchPostJSON('/api/company/getCompany', { id: CompanyId, include: true })
        await changeCompany(company)
    }, [CompanyId])

    const deleteInviteUser = useCallback(async (id: number, CompanyId: number) => {
        const res = await twinFetchPostText('/api/company/deleteInviteUser', { CompanyId, id })
        if (res === "true") {
            return true
        } else {
            return false
        }
    }, [])
    const deleteCompanyUser = useCallback(async (UserId: number, CompanyId: number) => {
        const res = await twinFetchPostText('/api/company/deleteCompanyUser', { CompanyId, UserId })
        if (res === "true") {
            return true
        } else {
            return false
        }
    }, [])
    const handleDelete = useCallback(async () => {
        if (openModal?.allRowData) {
            const {type, UserId} = openModal.allRowData
            let isCorrect = false
            if (type === 100) {
                isCorrect = await deleteInviteUser(UserId, CompanyId)
            } else {
                isCorrect = await deleteCompanyUser(UserId, CompanyId)
            }
            if (isCorrect) {
                getCompanyData()
                setOpenModal(null)
            }
        }
    }, [CompanyId, deleteCompanyUser, deleteInviteUser, openModal, getCompanyData, setOpenModal])

    return { searchUsers, setSearchUsers, openModal, setOpenModal, handleDelete }
}

interface HeaderUsersCompanyProps {
    company: Company
}

export const HeaderUsersCompany: React.FC<HeaderUsersCompanyProps> = ({ company}) => {
    const { modalOpened, setModalOpened } = useHeaderUsersCompanyLogic()
    return (
        <Fragment>
            <div className='flex items-center justify-between flex-wrap gap-20'>
                <h2>
                    <TwinTrans transKey={'adminUsers'}>Administración de usuarios</TwinTrans>
                </h2>
                <ButtonSecondary onClick={() => setModalOpened(true)}><TwinTrans transKey={'inviteUser'}>Invitar Usuario</TwinTrans></ButtonSecondary>
            </div>
            {modalOpened && <UsersModal opened={modalOpened} setOpened={setModalOpened} company={company} />}
        </Fragment>
    )
}

const useHeaderUsersCompanyLogic = () => {
    const [modalOpened, setModalOpened] = useState<ModalOpenedTy>(null)
    return { modalOpened, setModalOpened }
}

interface PrintUsersBoxProps {
    searchValue: string
    users: UserFromCompany[]
    userInvites: UserCompanyInvite[]
    myUsersType: number
    CompanyId: number
    setOpenModal: React.Dispatch<React.SetStateAction<UseEditDeleteModalProps>>
}

export const PrintUsersBox: React.FC<PrintUsersBoxProps> = ({ searchValue, users, userInvites, myUsersType, setOpenModal}) => {
    const renderUserBox: JSX.Element[] = []
    for (const key in users) {
        const user = users[key]
        if (stringMatchWithSearch(searchValue, user.email) || stringMatchWithSearch(searchValue, user.name) || stringMatchWithSearch(searchValue, user.surname)) {
            renderUserBox.push(<UserBox key={user.id} setOpenModal={setOpenModal} myType={myUsersType} user={user} type={user.UserCompany.type} />)
        }
    }
    for (const key in userInvites) {
        const user = userInvites[key]
        if (stringMatchWithSearch(searchValue, user.email)) {
            renderUserBox.push(<UserBox key={user.id} setOpenModal={setOpenModal} type={100} user={user} myType={myUsersType} />)
        }
    }
    return (
        <div className={'users_company_container overflow-y-auto pr-20'}>
            {renderUserBox}
        </div>
    )
}

const usersCompanyDispatch = {
    setCompany: (payload: CompanyPayload) => ({ type: 'CHANGE_COMPANY', payload }),
}
const mapUsersCompanyConnector = (state: AllReduxAppPayloads) => ({ company: state.company })
const usersCompanyConnector = connect(mapUsersCompanyConnector, usersCompanyDispatch, null, { context: GlobalContext })
type ReduxUsersCompanyConnector = ConnectedProps<typeof usersCompanyConnector>
export const UsersCompanyConnect = usersCompanyConnector(UsersCompany)

export default UsersCompanyConnect