import { faArrowRotateBack, faLongArrowDown, faLongArrowUp } from '@fortawesome/pro-light-svg-icons'
import { faLongArrowDown as faLongArrowDownSolid, faLongArrowUp as faLongArrowUpSolid } from '@fortawesome/pro-solid-svg-icons'
import { useCallback } from 'react'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { ModalTaskConfigColumnsBaseProps } from '../types'
import TwinIcon from '../../../../../baseComponents/TwinIcon'
import TwinForm from '../../../../../forms/TwinForm'
import { ModalCreateEditStructureLayout } from '../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import HeaderModalTaskConfigColumns from '../HeaderModalTaskConfigColumns'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { getNextOrder } from '../../../../../baseComponents/TwinTable/functions'
import { SelectedTaskColsFiltersHeader } from '../../../../../utils/hooks/tasks/useTaskConfigColumns'


interface ModalTaskConfigColumnsOrderProps extends ModalTaskConfigColumnsOrderLogicProps {
}

const ModalTaskConfigColumnsOrder: React.FC<ModalTaskConfigColumnsOrderProps> = ({ tmpColumns, columns, onSave, ...logic }) => {
    const { changeChecked, resetSelected } = useModalTaskConfigColumnsOrderLogic({ tmpColumns, columns, onSave, ...logic })
    const { t } = useTwinTranslation()

    const firstColumn: JSX.Element[] = []
    for (const key in columns) {
        const item = columns[key]
        if ((item.label || item.simpleLabel) && item.havePermission !== false && item.haveModule !== false && item.sortable) {
            const isDesc = tmpColumns.order[item.id] === 'desc'
            const isAsc = tmpColumns.order[item.id] === 'asc'
            const classNameUp = isDesc ? 'text-green-43' : 'text-gray-B2'
            const classNameDown = isAsc ? 'text-green-43' : 'text-gray-B2'
            const iconUp = isDesc ? faLongArrowUpSolid : faLongArrowUp
            const iconDown = isAsc ? faLongArrowDownSolid : faLongArrowDown
            firstColumn.push(
                <div className='mt-15 flex flex-cols' key={key}>
                    <span>{item.simpleLabel || item.label as any}</span>
                    <div onClick={() => changeChecked(item.id)}>
                        <TwinIcon icon={iconDown} className={classNameDown + ' ml-5 mr-3 cursor-pointer'} size='sm' />
                        <TwinIcon icon={iconUp} className={classNameUp + ' cursor-pointer'} size='sm' />
                    </div>
                </div>)
        }
    }

    return (
        <TwinForm beforeSubmitHandler={onSave} className='flex flex-col flex-auto'>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: <HeaderModalTaskConfigColumns {...{ tmpColumns, columns, setTmpColumns: logic.setTmpColumns, name: logic.name,headers: logic.headers }} />,
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} className='modal_negative_margin'>
                <div className='overflow-auto h-full w-full'>
                    <div className='flex flex-wrap w-full h-fit '>
                        <div className='w-fit'>
                            <div className='flex items-center mb-20'>
                                <h4 className='mr-18 '><TwinTrans transKey='configureElementOrder'>Configurar orden de los elementos</TwinTrans></h4>
                                <div className='flex items-center text-green-21 cursor-pointer' onClick={resetSelected}>
                                    <TwinIcon icon={faArrowRotateBack} fontSize={14} className='mr-8' />
                                    <span className='mt-2 lh-14'><TwinTrans transKey='byDefault'>Por defecto</TwinTrans></span>
                                </div>
                            </div>
                            {firstColumn}
                        </div>
                    </div>
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface ModalTaskConfigColumnsOrderLogicProps extends ModalTaskConfigColumnsBaseProps { }

const useModalTaskConfigColumnsOrderLogic = ({ setTmpColumns }: ModalTaskConfigColumnsOrderLogicProps) => {
    const changeChecked = useCallback((itemKey: string) => {
        setTmpColumns((oldTmp: SelectedTaskColsFiltersHeader) => {
            let tmpCopy: SelectedTaskColsFiltersHeader = JSON.parse(JSON.stringify(oldTmp))
            getNextOrder(itemKey, tmpCopy.order, 'desc')
            return { ...tmpCopy }
        })
    }, [setTmpColumns])

    const resetSelected = useCallback(() => {
        setTmpColumns((oldTmp: SelectedTaskColsFiltersHeader) => {
            let tmpCopy: SelectedTaskColsFiltersHeader = JSON.parse(JSON.stringify(oldTmp))
            tmpCopy.order = {}
            return { ...tmpCopy }
        })
    }, [setTmpColumns])

    return { changeChecked, resetSelected }
}


export default ModalTaskConfigColumnsOrder