import io from 'socket.io-client'
import { twinFetchPostText } from './data';
import { changePermanentErrorMessage } from '../reducers/reduxDispatch';

const socket = io('/', {
    reconnection: true,
    reconnectionDelay: 10000,
    reconnectionAttempts: 10
})

socket.on('disconnect', () => {
    changePermanentErrorMessage('__SOCKET_DISCONNECT')
})

export const connectToSocket = async () => {
    await twinFetchPostText('/api/app/utils/connectWebsocket', {})
}

export const listenSocket = async (propName: string, listenerFunc: (prop?: string) => any , skipRestriction?: boolean) => {
    const socketName = 'socketUpdate-' + propName
    if (!socket.listeners(socketName).length) {
        socket.on(socketName, listenerFunc)
    } else if(skipRestriction){
        socket.off(socketName)
        socket.on(socketName, listenerFunc)
    }
}
export const unlistenSocket = async (propName: string) => {
    const socketName = 'socketUpdate-' + propName
    if (socket.listeners(socketName).length) {
        socket.off(socketName)
    }
}

export default socket;