import { sortItems } from '../../forms/CustomSelect/DropDown/functions'
import { ItemCustomSelectDropDownRow } from '../../forms/CustomSelect/DropDown/types'
import { getUserInfo } from '../reducers/getters'
import { TwinDictionary } from './dictionary'

export const sortGroupsByUser = (groups: TwinDictionary) => {
    const copygroups = JSON.parse(JSON.stringify(groups))
    const result: ItemCustomSelectDropDownRow[] = [{ value: '', text: '---' }]
    const user = getUserInfo()
    const myGroups = user?.Employee?.Groups
    if (myGroups) {
        for (const element of myGroups) {
            if (groups[element.id]) {
                result.push({ value: element.id, text: groups[element.id].name })
                delete copygroups[element.id]
            }
        }
    }
    const sorted = sortItems(copygroups, 'name')
    if (!sorted?.[0]?.value) {
        sorted.shift()
    }
    return [...result, ...sorted]
}