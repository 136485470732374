import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../../../utils/hooks/useTypeIdNumber'
import { getActiveLangs } from '../../../../../../../../../utils/reducers/getters'
import { useMemo } from 'react'
import { addDictionaryExtraLine } from '../../../../../../../../../utils/globals/dictionary'
import { EmployeesPayload } from '../../../../../../../../../utils/reducers/company/employees'
import { connect, ConnectedProps } from 'react-redux'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'

type MainCustomerProfileProps = ComponentWithPermissions & ReduxMainCustomerProfile & {
    basicData: CustomerModelType | null
    getData: () => Promise<void>
}

const MainCustomerProfile: React.FC<MainCustomerProfileProps> = ({ basicData, userPermissions, getData, employees }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const myEmployees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(employees || {}))), [employees])

    const fields: FormRender<CustomerModelType> = [
        {
            cols: 2,
            title: t('generalInformation', 'Información general'),
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'surname',
                    label: t('surname', 'Apellidos'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: basicData?.type_id_number
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'code',
                    label: t('customerCode', 'Código de Cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'customer_card',
                    label: t('customerCard', 'Tarjeta de cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'ResponsibleEmployeeId',
                    label: t('OurResponsibleEmployee', 'Nuestro empleado responsable'),
                    component: 'CustomSelectWithSearchBar',
                    items: myEmployees || {},
                    fieldName: 'fullname_short',
                    sortItems: true
                }
            ]
        },
        {
            cols: 2,
            title: t('contactInformation', 'Información de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email'
                },
                {
                    name: 'web',
                    label: t('web', 'Página web'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'LangId',
                    label: t('language', 'Idioma'),
                    component: 'CustomSelect',
                    items: getActiveLangs(),
                },
                {
                    name: 'newsletter',
                    label: t('newsletter', 'Newsletter'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: basicData?.id
                }
            ]
        },
    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, basicData)

    return (
        <SingleFormLayout action='/api/app/customer/updateCustomer' onSubmit={getData} sections={parsedFields} userPermissions={userPermissions} />
    )
}


const mainCustomerProfileDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxMainCustomerProfile = ConnectedProps<typeof mainCustomerProfileConnect>
const mapMainCustomerProfileTypesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const mainCustomerProfileConnect = connect(mapMainCustomerProfileTypesConnector, mainCustomerProfileDispatch)

const mainCustomerProfileConnectLoading = withLoading(MainCustomerProfile, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const ModalCreateMainCustomerProfileConnect = mainCustomerProfileConnect(mainCustomerProfileConnectLoading)

export default ModalCreateMainCustomerProfileConnect