import { RowData } from '../../../../../baseComponents/TwinTable/types'
import TwinTrans from '../../../../../baseComponents/TwinTrans'

interface RenderMonthOrTotalsProps {
    value: string
    allRowData: RowData
}

export const RenderMonthOrTotals: React.FC<RenderMonthOrTotalsProps> = ({ value, allRowData }) => {
    if (allRowData?.date === '') {
        return (
            <div className='uppercase'>
                <TwinTrans transKey='total'>Total</TwinTrans>
            </div>
        )
    }
    return (
        <div>{value}</div>
    )
}