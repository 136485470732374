import { TwinDictionary } from '../../../utils/globals/dictionary'
import { prepareForSearch } from '../../../utils/globals/search'
import { getUserInfo } from '../../../utils/reducers/getters'

const sortItemsWithImage = (items: TwinDictionary, fieldName = 'name') => {
    const itemsArr: TwinDictionary[] = []
    for (const key in items) {
        itemsArr.push(items[key])
    }
    itemsArr.sort((a, b) => {
        if (typeof a[fieldName] === 'string' && typeof b[fieldName] === 'string') {
            return prepareForSearch(a[fieldName]) > prepareForSearch(b[fieldName]) ? 1 : -1
        }
        return a[fieldName] > b[fieldName] ? 1 : -1
    })
    return itemsArr
}

export const sortEmployeesByUserForProject = (employees: TwinDictionary) => {
    const copyEmployees = JSON.parse(JSON.stringify(employees))
    const result: TwinDictionary[] = []
    const user = getUserInfo()
    if (user && user.Employee) {
        result.push(user.Employee)
        delete copyEmployees[user.Employee.id]
        const sorted = sortItemsWithImage(copyEmployees, 'fullname_short')
        return [...result, ...sorted]
    }
    return []
}
export const sortGroupsByUserForProject = (groups: TwinDictionary) => {
    const copyGroups = JSON.parse(JSON.stringify(groups))
    const result: TwinDictionary[] = []
    const user = getUserInfo()
    const myGroups = user?.Employee?.Groups
    if (myGroups) {
        for (const element of myGroups) {
            if (groups[element.id]) {
                result.push({...element, name: groups[element.id].name, photo: groups[element.id].photo })
                delete copyGroups[element.id]
            }
        }
    }
    const sorted = sortItemsWithImage(copyGroups, 'name')
    return [...result, ...sorted]
}