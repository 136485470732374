import { useCallback } from 'react'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import { SelectedColumnsAndFilters, getTableSelectedColumns } from '../../../../utils/hooks/useConfigColumns'
import { ButtonLoadFiltersStateLess, FiltersConfig, NameAndModifyFilters, useButtonsLoadFiltersStateFullLogic } from '../../../Button/ButtonLoadFilter'
import TwinTrans from '../../../TwinTrans'
import { ConfigColumnsColumn } from '../types'

interface HeaderModalConfigColumnsProps extends HeaderModalConfigColumnsLogicProps  {
    tmpColumns: SelectedColumnsAndFilters
}

const HeaderModalConfigColumns: React.FC<HeaderModalConfigColumnsProps> = ({...rest}) => {
    const defaultValues = getTableSelectedColumns(rest.name, rest.columns)
    const { filters, getAllFilters } = useButtonsLoadFiltersStateFullLogic({ tableName: rest.name, defaultValues })
    const { onChangeFilter } = useHeaderModalConfigColumnsLogic({ ...rest })
    const mySelectedFilter: FiltersConfig = { value: { filters: rest.tmpColumns.filters, selectedColumns: rest.tmpColumns.selectedColumns }, id: rest.tmpColumns.id, name: rest.tmpColumns.name }
    return (
        <div>
            <div className='flex items-center mb-10'>
                <h2>
                    <TwinTrans transKey='configureFiltersOrderAndColumns'>Configurar filtros, orden y columnas</TwinTrans><span>:</span>
                </h2>
                <ButtonLoadFiltersStateLess tableName={rest.name} allowCreate={true} selectedFilters={mySelectedFilter} onChangeFilter={onChangeFilter} filters={filters} getAllFilters={getAllFilters} defaultValues={defaultValues} onOpenModal={getAllFilters}/>
            </div>
            <NameAndModifyFilters getAllFilters={getAllFilters} selectedFilters={mySelectedFilter} onChangeFilter={onChangeFilter} filters={filters}/>
        </div>
    )
}

interface HeaderModalConfigColumnsLogicProps {
    name: string
    columns: ConfigColumnsColumn[]
    setTmpColumns: React.Dispatch<React.SetStateAction<SelectedColumnsAndFilters>>
}

const useHeaderModalConfigColumnsLogic = ({ setTmpColumns }: HeaderModalConfigColumnsLogicProps) => {

    const onChangeFilter = useCallback((json: TwinDictionary) => {
        setTmpColumns({ filters: json?.value?.filters || {}, selectedColumns: json?.value?.selectedColumns || {}, id: json.id, name: json.name, order: json?.value?.order || {} })
    }, [setTmpColumns])

    return { onChangeFilter }
}

export default HeaderModalConfigColumns