import { useCallback } from 'react'
import { ButtonLoadFiltersStateLess, FiltersConfig, NameAndModifyFilters, useButtonsLoadFiltersStateFullLogic } from '../../../../../../../../../../baseComponents/Button/ButtonLoadFilter'
import { ModalCreateEditStructureLayout } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import FormRenderer from '../../../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import MultipleCheckboxSelector from '../../../../../../../../../../forms/MultipleCheckboxSelector'
import TwinForm from '../../../../../../../../../../forms/TwinForm'
import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import { returnClassOfSpanTemplate } from '../../../../../../../../../../utils/globals/tailwind'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { DictionaryEmployee } from '../../../../../../../../../../utils/reducers/company/employees'
import { getEmployees, getGroups } from '../../../../../../../../../../utils/reducers/getters'
import { DictionaryGroup } from '../../../../../../../../../../utils/reducers/groups/groups'
import { parseTaskAdminCalendarFilterData, taskAdminCalendarGetDefaultSelectedData, taskAdminCalendarGetOnlyDefaultData } from '../../../../functions'
import { TaskAdminCalendarSelectedFullDataProps } from '../../../../types'

type ModalTaskCalendarAdminProps = ModalTaskCalendarAdminLogicProps & ModalEditComponentProps & {
    validFilters: { validEmployees: DictionaryEmployee, validGroups: DictionaryGroup } | null
    loading: boolean
    onSave: () => false
}

interface ModalTaskAdminSelectEmployeesAndGroups  {
    'groups': string
    'employees': string
}
const ModalTaskCalendarAdmin: React.FC<ModalTaskCalendarAdminProps> = ({ selectedData, setSelectedData, loading, onSave, validFilters }) => {
    const { t } = useTwinTranslation()
    const defaultValues = taskAdminCalendarGetDefaultSelectedData({ name: 'task_calendar_admin_filter', groups: validFilters?.validGroups || null, employees: validFilters?.validEmployees || null })
    const { handleOnChange, onChangeFilter } = useModalTaskCalendarAdminLogic({selectedData, setSelectedData })
    const { filters, getAllFilters } = useButtonsLoadFiltersStateFullLogic({ tableName: 'task_calendar_admin_filter', defaultValues })
    const mySelectedFilter: FiltersConfig = { value: selectedData, id: selectedData.id, name: selectedData.name }
 
    if (!validFilters) {
        return null
    }
    
    const fields: FormRender<ModalTaskAdminSelectEmployeesAndGroups> = [
        {
            cols: 2,
            title: t('employees', 'Empleados'),
            elements: [
                {
                    name: 'employees',
                    component: 'Custom',
                    render: ({extraProps}) => <MultipleCheckboxSelector subtitles={{
                        subtitleOptions: t('employees', 'Empleados'),
                        subtitleSelectedOptions: t('employeesSelected', 'Empleados seleccionados')
                    }} items={validFilters?.validEmployees || {}} defaultValue={extraProps.employees} onChange={(value) => handleOnChange(value, 'employees')} className={returnClassOfSpanTemplate(2) } keyName='fullname_short'/>,
                    extraProps: { employees: selectedData.filters.employees }
                },
            ]
        },
        {
            cols: 2,
            title: t('groups', 'Grupos'),
            elements: [
                {
                    name: 'groups',
                    component: 'Custom',
                    render: ({extraProps}) => <MultipleCheckboxSelector subtitles={{
                        subtitleOptions: t('groups', 'Grupos'),
                        subtitleSelectedOptions: t('groupsSelected', 'grupos seleccionados')
                    }} items={validFilters?.validGroups || {}} defaultValue={extraProps.groups} onChange={(value) => handleOnChange(value, 'groups')} className={returnClassOfSpanTemplate(2) } />,
                    extraProps: { groups: selectedData.filters.groups }
                },
            ]
        }
    ]
    return (
        <TwinForm beforeSubmitHandler={() => {
            onSave()
            return false
        }} className='flex flex-col flex-auto'>
            <ModalCreateEditStructureLayout loading={loading} translations={{
                title: t('configureFilters', 'Configurar filtros'),
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} className='modal_task_calendar_content flex-col justify-center' >
                <div className='flex mb-25'>
                    <NameAndModifyFilters getAllFilters={getAllFilters} selectedFilters={mySelectedFilter} onChangeFilter={onChangeFilter} filters={filters} />
                    <ButtonLoadFiltersStateLess selectedFilters={mySelectedFilter} onChangeFilter={onChangeFilter} tableName='task_calendar_admin_filter' allowCreate={true} defaultValues={defaultValues} filters={filters} getAllFilters={getAllFilters} onOpenModal={getAllFilters}/>
                </div>
                {fields.length ?
                    <FormRenderer sections={fields} className='grid_mincontent overflow-auto'>
                    </FormRenderer>
                    : null}
                </ModalCreateEditStructureLayout>
            </TwinForm>
    )
}

interface ModalTaskCalendarAdminLogicProps extends TaskAdminCalendarSelectedFullDataProps {

}

const useModalTaskCalendarAdminLogic = ({ selectedData, setSelectedData }: ModalTaskCalendarAdminLogicProps) => {
    const handleOnChange = useCallback((value: string[], type: string) => {
        setSelectedData((old) => {
            if (!old) {
                return old
            }
            const filtersOld = { ...old.filters }
            filtersOld[type] = value
            return { ...old, filters: filtersOld }
        })
    }, [setSelectedData])

    const onChangeFilter = useCallback((json: TwinDictionary) => {
        const groups = getGroups()
        const employees = getEmployees()
        setSelectedData((old) => {
            if (!old) {
                return old
            }
            const copy = JSON.parse(JSON.stringify(json?.value || {}))
            parseTaskAdminCalendarFilterData(copy, groups)
            return {
                ...old,
                filters: copy?.filters || {},
                colors: copy?.colors || taskAdminCalendarGetOnlyDefaultData(groups, employees).colors,
                id: json.id, name: json.name
            }
        })
    }, [setSelectedData])

    return { handleOnChange, onChangeFilter, selectedData }
}
export default ModalTaskCalendarAdmin