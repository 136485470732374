import { useCallback } from 'react'
import { ModalCreateEditStructureLayout } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import TwinTrans from '../../../../../../../../../../baseComponents/TwinTrans'
import InputColor from '../../../../../../../../../../forms/Input/InputColor'
import TwinForm from '../../../../../../../../../../forms/TwinForm'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { DictionaryEmployee } from '../../../../../../../../../../utils/reducers/company/employees'
import { DictionaryGroup } from '../../../../../../../../../../utils/reducers/groups/groups'
import { TaskAdminCalendarSelectedDataSetProps, TaskAdminCalendarSelectedFullDataProps } from '../../../../types'
import './taskcalendaremployeegroup.sass'
import { ButtonLoadFiltersStateLess, FiltersConfig, NameAndModifyFilters, useButtonsLoadFiltersStateFullLogic } from '../../../../../../../../../../baseComponents/Button/ButtonLoadFilter'
import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import { getEmployees, getGroups } from '../../../../../../../../../../utils/reducers/getters'
import { taskAdminCalendarGetOnlyDefaultData, taskAdminCalendarGetDefaultSelectedData, parseTaskAdminCalendarFilterData } from '../../../../functions'

type ModalTaskCalendarAdminColorPickerProps = ModalEditComponentProps & TaskAdminCalendarSelectedFullDataProps & {
    loading: boolean
    onSave: () => false
    validFilters: { validEmployees: DictionaryEmployee, validGroups: DictionaryGroup } | null
}

const ModalTaskCalendarAdminColorPicker: React.FC<ModalTaskCalendarAdminColorPickerProps> = ({ selectedData, setSelectedData, loading, onSave, validFilters }) => {
    const { t } = useTwinTranslation()
    const defaultValues = taskAdminCalendarGetDefaultSelectedData({ name: 'task_calendar_admin_filter', groups: validFilters?.validGroups || null, employees: validFilters?.validEmployees || null })
    const { filters, getAllFilters } = useButtonsLoadFiltersStateFullLogic({ tableName: 'task_calendar_admin_filter', defaultValues })
    const mySelectedFilter: FiltersConfig = { value: selectedData, id: selectedData.id, name: selectedData.name }
    const { onChangeFilter, handleOnChange } = useModalTaskCalendarColorPickerLogic({setSelectedData})
    const renderThisEmployees: JSX.Element[] = []
    const renderThisGroups: JSX.Element[] = []
    if (validFilters?.validEmployees)
        for (const key in validFilters?.validEmployees) {
            const employee = validFilters?.validEmployees[key]
            renderThisEmployees.push(<TaskCalendarAdminColorConfig key={key} name={employee.name} onChange={(value) => handleOnChange(value, 'employees', employee.id)} color={selectedData.colors.employees[employee.id]} />)
        }
    if (validFilters?.validGroups) {
        for (const key in validFilters?.validGroups) {
            const group = validFilters?.validGroups[key]         
            renderThisGroups.push(<TaskCalendarAdminColorConfig key={key} name={group.name} onChange={(value) => handleOnChange(value, 'groups', group.id)} color={selectedData.colors.groups[group.id]} />)
        }
    }
    return (
        <TwinForm beforeSubmitHandler={() => {
            onSave()
            return false
        }} className='flex flex-col flex-auto'>
            <ModalCreateEditStructureLayout loading={loading} translations={{
                title: t('assignColors', 'Definir colores'),
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} className='flex-auto flex flex-col h-1'>
                <div className='flex mb-25'>
                    <NameAndModifyFilters getAllFilters={getAllFilters} selectedFilters={mySelectedFilter} onChangeFilter={onChangeFilter} filters={filters} />
                    <ButtonLoadFiltersStateLess selectedFilters={mySelectedFilter} onChangeFilter={onChangeFilter} tableName='task_calendar_admin_filter' allowCreate={true} defaultValues={defaultValues} filters={filters} getAllFilters={getAllFilters} onOpenModal={getAllFilters} />
                </div>
                <div className='flex flex-col mt-20 gap-20 overflow-y-auto'>
                    <div>
                        <h3><TwinTrans transKey='employees'>Empleados</TwinTrans></h3>
                        {renderThisEmployees}
                    </div>
                    <div>
                        <h3><TwinTrans transKey='groups'>Grupos</TwinTrans></h3>
                        {renderThisGroups}
                    </div>
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface ModalTaskCalendarColorPickerLogicProps extends TaskAdminCalendarSelectedDataSetProps {
}

const useModalTaskCalendarColorPickerLogic = ({setSelectedData}: ModalTaskCalendarColorPickerLogicProps) => {
    const onChangeFilter = useCallback((json: TwinDictionary) => {
        const groups = getGroups()
        const employees = getEmployees()
        setSelectedData((old) => {
            if (!old) {
                return old
            }
            const copy = JSON.parse(JSON.stringify(json?.value || {}))
            parseTaskAdminCalendarFilterData(copy, groups)
            return {
                filters: copy?.filters || {},
                colors: copy?.colors || taskAdminCalendarGetOnlyDefaultData(groups, employees).colors,
                id: json.id,
                name: json.name
            }
        })
    }, [setSelectedData])

    const handleOnChange = useCallback((value: string, type: string, id: number) => {
        setSelectedData((old) => {
            if (!old) {
                return old
            }
            const colorsOld = { ...old.colors }
            colorsOld[type][id] = value
            return { ...old, colors: colorsOld }
        })
    }, [setSelectedData])


    return { onChangeFilter, handleOnChange }
}


interface EmployeesColorConfigProps {
    name: string
    color: string
    onChange: (value: string) => void
}

const TaskCalendarAdminColorConfig: React.FC<EmployeesColorConfigProps> = ({ name, color, onChange }) => {
    return (
        <div className='flex justify-between items-center mt-10'>
            <span>{name}</span>
            <InputColor  
                onChange={(value) => onChange(value)}
                className='taskcalendar_input_color'
                value={color}
            />
        </div>
    )
}

export default ModalTaskCalendarAdminColorPicker