import { faCirclePlus, faXmark } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useMemo, useRef, useState } from 'react'
import { ListRowRenderer } from 'react-virtualized'
import SearchBar from '../../../baseComponents/SearchBar'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { TwinAutoSizer, TwinList } from '../../../baseComponents/TwinTable/Adapters'
import TwinTrans from '../../../baseComponents/TwinTrans'
import { CustomChangeFunction } from '../../Input/types'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { Modify } from '../../../utils/globals/types'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import { stringMatchWithSearch } from '../../../utils/globals/search'
import { CustomSelectMultipleImageBaseProps, CustomSelectMultipleImageBasePropsNotOpt, CustomSelectMultipleImageItems } from './types'
import { CustomerProjectRenderImageTooltip } from '../../../specificComponents/Customers/Subcomponents'
import './customSelectMultipleImage.sass'

type CustomSelectMultipleImageStateFullProps = Modify<CustomSelectMultipleImageStateLessProps, CustomSelectMultipleImageStateFullLogicProps & {
}>

export const CustomSelectMultipleImageStateFull: React.FC<CustomSelectMultipleImageStateFullProps> = ({  itemsSelected, onAdd, onDelete, type, ...rest }) => {
    const { data, myOnAdd, myOnDelete } = useCustomSelectMultipleImageStateFullLogic({ itemsSelected, onAdd, onDelete, ...rest })
    return (
        <CustomSelectMultipleImageStateLess {...rest} onAdd={myOnAdd} itemsSelected={data} onDelete={myOnDelete} type={type} />
    )
}

interface CustomSelectMultipleImageStateFullLogicProps extends CustomSelectMultipleImageItems {
    onAdd?: (value: string) => void
    onDelete?: (value: string) => void
}

const useCustomSelectMultipleImageStateFullLogic = ({ itemsSelected, onAdd, onDelete }: CustomSelectMultipleImageStateFullLogicProps) => {
    const [data, setData] = useState <string[]>(itemsSelected)

    const myOnAdd = useCallback((value: string) => {
        setData((old) => {
            const copySelected = [...old, value]
            onAdd?.(value)
            return copySelected
        })
    }, [setData, onAdd])

    const myOnDelete = useCallback((value: string) => {
        setData((old) => {
            const copySelected: string[] = []
            for (const key of old) {
                if (key !== value) {
                    copySelected.push(key)
                }
            }
            onDelete?.(value)
            return copySelected
        })
    }, [setData, onDelete])

    return { data, myOnAdd, myOnDelete }
}

interface CustomSelectMultipleImageStateLessProps extends CustomSelectMultipleImageBaseProps {
    name?: string
    label?: string
    onAdd: CustomChangeFunction
    onDelete: CustomChangeFunction
    className?: string
    type: string
    sortFunction: (items: TwinDictionary) => TwinDictionary[]
}

export const CustomSelectMultipleImageStateLess: React.FC<CustomSelectMultipleImageStateLessProps> = ({ name, itemsSelected, items, label, className, type, fieldValue = 'id', fieldImage='photo', fieldName='name', ...rest}) => {
    const { handleOpenSelector, openSelector, ref } = useCustomSelectMultipleImageStateLessLogic()
    const renderThis: JSX.Element[] = []
    for (const key of itemsSelected) {
        const item = items[key]
        if (item) {
            renderThis.push(
                <CustomerProjectRenderImageTooltip id={parseInt(item[fieldValue])} img={item[fieldImage] ?? rest.defaultImage} tooltipName={type} name={item[fieldName]} />
            )
        }
    }
    return (
        <div ref={ref} className={'custom_select_multiple_image relative ' + (className || '')} >
            <div className='custom_select_multiple_image_header'>
                <span className='text-gray-51 text-12 font-light pb-4 block'>{label}</span>
                <div className='flex flex-wrap gap-5'>
                    {renderThis}
                    < TwinIcon icon={faCirclePlus} onClick={handleOpenSelector} className='text-green-43 cursor-pointer h-27 w-27 ml-2'></TwinIcon>
                </div>
            </div>
            {name ? <input type='hidden' name={name} onClick={handleOpenSelector} /> : null}
            {openSelector && <CustomSelectMultipleImageDropDown {...{ items, itemsSelected, fieldImage, fieldName, fieldValue }} {...rest} />}
        </div>
    )
}

const useCustomSelectMultipleImageStateLessLogic = () => {
    const [openSelector, setOpenSelector] = useState<boolean>(false)
    const ref = useRef<any>(null)

    const handleOpenSelector = useCallback(() => {
        setOpenSelector((old) => !old)
    }, [setOpenSelector])

    useOnClickOutside(ref, () => {
        setOpenSelector(false)
    })

    return { openSelector, ref, handleOpenSelector }
}

interface CustomSelectMultipleImageDropDownProps extends CustomSelectMultipleImageDropDownLogicProps {
    onDelete: CustomChangeFunction
}

const ROW_HEIGHT = 30

const CustomSelectMultipleImageDropDown: React.FC<CustomSelectMultipleImageDropDownProps> = ({ itemsSelected,  onDelete, defaultImage, fieldImage, items, fieldName, fieldValue, ...logic }) => {
    const { t } = useTwinTranslation()
    const { searchValue, setSearchValue, rowRenderer, keys } = useCustomSelectMultipleImageDropDownLogic({ itemsSelected, defaultImage, items, fieldImage, fieldName, fieldValue, ...logic })
    const assignedRenderThis: JSX.Element[] = []
    for (const key of itemsSelected) {
        const item = items[key]
        if (item) {
            assignedRenderThis.push(
                <div className='flex items-center custom_select_multiple_image_dropdown_assigned' key={item[fieldValue]}>
                    <div className='flex-auto w-1 flex gap-10 items-center custom_select_multiple_image_dropdown_assigned_inner' title={item[fieldName]}>
                        <img src={item[fieldImage] || defaultImage} alt={item[fieldName]} className='w-27 h-27 rounded-full'  />
                        <span className='twin_elipsis flex-auto'>{item[fieldName]}</span>
                    </div>
                    <TwinIcon icon={faXmark} onClick={() => onDelete(String(item[fieldValue]))} className='w-14 h-14 ml-auto pr-10 cursor-pointer'></TwinIcon>
                </div>
            )
        }
    }
    const myDataLength = keys.length
    return (
        <div className='bg-white absolute z-10 left-0 right-0 h-300 flex flex-col custom_select_multiple_image_dropdown'>
            <SearchBar className='p-12' placeholder={t('searchDots', 'Buscar...')} onChange={setSearchValue} searchValue={searchValue} />
            <div className='flex flex-col px-12 custom_select_multiple_image_dropdown_assigned_parent'>
                <span className='font-medium mb-12'><TwinTrans transKey={'assigned'}>Asignados</TwinTrans></span>
                <div className='overflow-auto'>
                    {assignedRenderThis}
                </div>
            </div>
            <div className='flex flex-col px-12 mt-12 flex-auto pb-12'>
                <span className='font-medium mb-12'><TwinTrans transKey={'noAssigned'}>Sin asignar</TwinTrans></span>
                <div className='overflow-auto flex-auto overflow-x-hidden'>
                    <TwinAutoSizer>
                        {({ width }) => (
                            <TwinList
                                height={ROW_HEIGHT * myDataLength}
                                rowCount={myDataLength}
                                rowHeight={ROW_HEIGHT}
                                rowRenderer={rowRenderer}
                                width={width}
                            />
                        )}
                    </TwinAutoSizer>
                </div>
            </div>
        </div>
    )
}

interface CustomSelectMultipleImageDropDownLogicProps extends CustomSelectMultipleImageBasePropsNotOpt {
    onAdd: CustomChangeFunction
    sortFunction: (items: TwinDictionary) =>TwinDictionary[]
}

const useCustomSelectMultipleImageDropDownLogic = ({ onAdd, itemsSelected, defaultImage, fieldValue, fieldImage, fieldName, items, sortFunction }: CustomSelectMultipleImageDropDownLogicProps) => {
    const [searchValue, setSearchValue] = useState('')
    const keys = useMemo(() => {
        const arrItems = sortFunction(items)
        const arrFiltered: TwinDictionary[] = []
        for (const key in arrItems) {
            const item = arrItems[key as any]
            if (!itemsSelected.includes(String(item[fieldValue])) && stringMatchWithSearch(searchValue, item[fieldName])) {
                arrFiltered.push(item)
            }
        }
        return { keys: Object.keys(arrFiltered), arrFiltered }
    }, [items, itemsSelected, searchValue, fieldName, fieldValue, sortFunction])

    const rowRenderer: ListRowRenderer = useCallback(({ ...props }) => {
        const myRow = keys.arrFiltered[props.index]
        if (myRow) {
            return (
                <CustomSelectMultipleImageDropDownRow item={{ value: myRow[fieldValue], text: myRow[fieldName], img: myRow[fieldImage] || defaultImage }
                } onAdd={onAdd}  {...props} />
            )
        }
        return null

    }, [keys, onAdd, defaultImage, fieldName, fieldValue, fieldImage])

    return { searchValue, rowRenderer, keys: keys.keys, setSearchValue }
}

interface CustomSelectMultipleImageDropDownRowProps extends CustomSelectMultipleImageDropDownRowLogicProps {
    className?: string
}

const CustomSelectMultipleImageDropDownRow: React.FC<CustomSelectMultipleImageDropDownRowProps> = ({ className, onAdd, item, ...rest }) => {
    const { changeValue, text, img, value } = useCustomSelectMultipleImageDropDownRowLogic({ onAdd, item, ...rest })
    return (
        <div className='cursor-pointer' onClick={changeValue} {...rest} title={text as any}>
            <div className='flex gap-10 items-center' title={text}>
                <img src={img} alt={text} className='w-27 h-27 rounded-full' key={value} />
                <span className='twin_elipsis'>{text}</span>
            </div>
        </div>
    )
}

interface CustomSelectMultipleImageDropDownRowLogicProps {
    onAdd: CustomChangeFunction
    item: TwinDictionary
}

const useCustomSelectMultipleImageDropDownRowLogic = ({ item, onAdd }: CustomSelectMultipleImageDropDownRowLogicProps) => {
    const value = item.value
    const text = item.text
    const img = item.img
    const changeValue = useCallback(() => {
        onAdd(String(value))
    }, [onAdd, value])

    return { changeValue, text, img, value }
}