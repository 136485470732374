import { faPaperclip, faPenToSquare, faRotateLeft, faTrashCan, faUser } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useCallback, useState } from 'react'
import TextArea from '../../../../../../../../forms/Input/TextArea'
import { ButtonDisabledOrLoader, ButtonPrimary } from '../../../../../../../../baseComponents/Button'
import TwinIcon from '../../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../../baseComponents/TwinTrans'
import useEditDeleteModal, { SetEditDeleteModalTy } from '../../../../../../../../utils/hooks/useEditDeleteModal'
import useIsLoading from '../../../../../../../../utils/hooks/useIsLoading'
import { OnSubmit } from '../../../../../../../../forms/TwinForm/types'
import TwinForm from '../../../../../../../../forms/TwinForm'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import twinFetchPost, { twinFetchPostText } from '../../../../../../../../utils/globals/data'
import { ModalDeleteFilledOutside } from '../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { getUserInfo } from '../../../../../../../../utils/reducers/getters'
import { CustomRenderRoundedImage } from '../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { parseFileName } from '../../../../../../../../utils/globals/files'
import { TaskRepositoryAnswerModelTypeExt } from './types'
import FilesClipInput from '../../../../../../../../forms/FilesInput/FilesClipInput'
import { ImageInputFile } from '../../../../../../../../forms/ImageInput/types'
import { InputHidden } from '../../../../../../../../forms/Input'

interface TaskRepositoryAnswersProps extends CreateTaskRepositoryAnswerLogicProps {
    TaskRepositoryId: string
    answers: TaskRepositoryAnswerModelTypeExt[]
}

const TaskRepositoryAnswers: React.FC<TaskRepositoryAnswersProps> = ({ TaskRepositoryId, answers, getTaskRepository, onSubmit }) => {
    const renderThis: JSX.Element[] = []
    for (const key in answers) {
        const answer = answers[key]
        renderThis.push(
            <TaskRepositoryAnswer key={answer.id} answer={answer} TaskRepositoryId={TaskRepositoryId} getTaskRepository={getTaskRepository} onSubmit={onSubmit} />
        )
    }

    return (
        <div className='task_repository_answers my-20'>
            <div className='bg-gray-F7 px-16 p-10'>
                <span className='regular18'>
                    <TwinTrans transKey='comments'>Comentarios</TwinTrans>
                </span>
            </div>
            <div className='answers_box'>
                {renderThis}
            </div>
            <div>
                <CreateTaskRepositoryAnswer TaskRepositoryId={TaskRepositoryId} getTaskRepository={getTaskRepository} onSubmit={onSubmit} />
            </div>
        </div>
    )
}

interface TaskRepositoryAnswerProps extends TaskMyRepositoryAnswerProps {
    answer: TaskRepositoryAnswerModelTypeExt
}

const TaskRepositoryAnswer: React.FC<TaskRepositoryAnswerProps> = ({ answer, ...rest }) => {
    const userInfo = getUserInfo()
    const EmployeeId = userInfo?.Employee?.id
    const parseDate = new Date(answer?.createdAt).toLocaleString()
    const isMyAnswer = answer.EmployeeId === EmployeeId
    return (
        <div className='pt-20 flex'>
            <div className='flex flex-auto flex-col'>
                <div className='flex items-center'>
                    <CustomRenderRoundedImage className='mr-12 h-34 w-34' icon={faUser} img={answer.Employee?.profile_image || '/user-png.png'} name={answer.Employee?.name} />
                    <span className='medium14 mr-12'>{answer.Employee?.fullname_short}</span>
                    <span className='light14 text-gray-51'>{parseDate}</span>
                </div>
                {isMyAnswer ?
                    <TaskMyRepositoryAnswer {...{ ...rest, answer }} />
                    : <TaskOtherRepositoryAnswer {...{ answer }} />
                }
                {answer.file_url ?
                    <a href={answer.file_url_parsed} className=' block mt-10 cursor-pointer mr-20' target='_blank' rel="noreferrer">
                        <TwinIcon className='mr-10 ' icon={faPaperclip} />
                        <span>{parseFileName(answer)}</span>
                    </a>
                    : null
                }
            </div>
        </div>
    )
}

interface TaskMyRepositoryAnswerProps extends TaskMyRepositoryAnswerLogicProps {
    answer: TaskRepositoryAnswerModelTypeExt
}

const TaskMyRepositoryAnswer: React.FC<TaskMyRepositoryAnswerProps> = ({ answer, ...rest }) => {
    const { edit, handleEdit, deleteAnswer, saveAnswer, setOpenModal, openModal } = useTaskMyRepositoryAnswerLogic({ ...rest })
    const { t } = useTwinTranslation()
    return (
        <div className='flex items-center w-full border border-gray-D6 rounded-xl p-10 mt-10'>
            {edit ?
                <EditTaskAnswer {...{ answer, saveAnswer, setOpenModal, handleEdit }} />
                :
                <TaskRepositoryAnswerContent {...{ answer, handleEdit, setOpenModal }} />
            }
            {openModal?.type === 'delete' ? <ModalDeleteFilledOutside translations={{
                title: t('deleteComment', 'Eliminar comentario'),
                subtitle: t('sureDeleteComment', '¿Estás seguro de querer eliminar este comentario?'),
                buttonCancel: t('cancel', 'Cancelar'),
                buttonAccept: t('delete', 'Eliminar'),
            }} opened={true} setOpened={() => setOpenModal(null)} onAccept={() => deleteAnswer(openModal?.allRowData?.id)} /> : null}
        </div>
    )
}


interface TaskRepositoryAnswerContentProps {
    answer: TaskRepositoryAnswerModelTypeExt
    handleEdit: () => void
    setOpenModal: SetEditDeleteModalTy
}

const TaskRepositoryAnswerContent: React.FC<TaskRepositoryAnswerContentProps> = ({ answer, handleEdit, setOpenModal }) => {
    return (
        <Fragment>
            <div className={'light14 mr-auto pr-10 whitespace-pre-wrap ' + (answer.content ? '' : 'italic')}>
                {answer.content}
            </div>
            <TwinIcon className='mr-15 h-20 cursor-pointer hover:text-green-43' icon={faPenToSquare} onClick={handleEdit} />
            <TwinIcon className='mr-10 h-20 cursor-pointer hover:text-red-BA' icon={faTrashCan} onClick={() => setOpenModal({ type: 'delete', allRowData: { id: answer.id } })} />
        </Fragment>
    )
}


interface TaskMyRepositoryAnswerLogicProps {
    TaskRepositoryId: string
    getTaskRepository: ()=> void
    onSubmit: OnSubmit
}
const useTaskMyRepositoryAnswerLogic = ({ getTaskRepository, TaskRepositoryId, onSubmit }: TaskMyRepositoryAnswerLogicProps) => {
    const [edit, setEdit] = useState<boolean>(false)
    const { openModal, setOpenModal } = useEditDeleteModal()
    const deleteAnswer = useCallback(async (id: number) => {
        if (id) {
            await twinFetchPost('/api/app/task/repository/answer/deleteTaskRepositoryAnswer', { id })
            await getTaskRepository()
            onSubmit?.({} as any, {})
        }
    }, [getTaskRepository,onSubmit])

    const handleEdit = useCallback(() => {
        setEdit((old) => !old)
    }, [setEdit])

    const saveAnswer = useCallback(async (id: number, content: string) => {
        const result = await twinFetchPostText('/api/app/task/repository/answer/updateTaskRepositoryAnswer', { id, content, TaskRepositoryId })
        if (result) {
            await getTaskRepository()
            setEdit(false)
            onSubmit?.({} as any, {})
        }
    }, [setEdit, TaskRepositoryId, getTaskRepository, onSubmit])

    return { deleteAnswer, edit, handleEdit, saveAnswer, openModal, setOpenModal }
}


interface TaskOtherRepositoryAnswerProps {
    answer: TaskRepositoryAnswerModelTypeExt
}

const TaskOtherRepositoryAnswer: React.FC<TaskOtherRepositoryAnswerProps> = ({ answer }) => {
    if (!answer?.content) {
        return null
    }
    return (
        <div className='flex items-center w-full border border-gray-D6 rounded-xl p-10 mt-10'>
            <div className={'light14 mr-auto pr-10 whitespace-pre-wrap ' + (answer.content ? '' : 'italic')}>
                {answer.content}
            </div>
        </div>

    )
}


interface CreateTaskRepositoryAnswerProps extends CreateTaskRepositoryAnswerLogicProps {
    TaskRepositoryId: string
}

const CreateTaskRepositoryAnswer: React.FC<CreateTaskRepositoryAnswerProps> = ({ TaskRepositoryId, ...logic }) => {
    const { answer, handleSubmit, loading, onChangeAnswer, startLoading, files, setFiles } = useCreateTaskRepositoryAnswerLogic({ ...logic })
    const { t } = useTwinTranslation()
    const buttonIsDisabled = answer || files.length ? false : true
    return (
        <TwinForm className='mt-30' action='/api/app/task/repository/answer/createTaskRepositoryAnswer' onSubmit={handleSubmit} beforeSubmitHandler={startLoading} imagepath={['tasks', 'repository', TaskRepositoryId]}>
            <div className=' flex flex-auto items-center border border-gray-D6 px-8 py-6 rounded-lg task_repository_create_answer'>
                <div className='flex flex-auto items-center pr-10'>
                    <TextArea className='w-full text-gray-51 task_repository_answer_component_textarea ' name='content' value={answer} placeholder={t('writeAComment', 'Escribe un comentario')} onChange={onChangeAnswer} maxRows={3} />
                    {loading === false ? <FilesClipInput name='file_url' className='flex items-center' onChange={setFiles} /> : null}
                    <InputHidden name='TaskRepositoryId' value={TaskRepositoryId} />
                    <ButtonDisabledOrLoader buttonIsDisabled={buttonIsDisabled} textButton={t('add', 'Añadir')} {...{ loading }} />
                </div>
            </div>
        </TwinForm>
    )
}

interface CreateTaskRepositoryAnswerLogicProps {
    getTaskRepository: ()=>void
    onSubmit: OnSubmit
}

const useCreateTaskRepositoryAnswerLogic = ({ getTaskRepository, onSubmit }: CreateTaskRepositoryAnswerLogicProps) => {
    const [answer, setAnswer] = useState<string>('')
    const [files, setFiles] = useState<ImageInputFile[]>([]);

    const { startLoading, endLoading, loading } = useIsLoading()
    const onChangeAnswer = useCallback((value: string) => {
        setAnswer(value)
    }, [setAnswer])
    const handleSubmit: OnSubmit = useCallback(async (res) => {
        setAnswer('')
        setFiles([])
        endLoading()
        await getTaskRepository()
        onSubmit?.({} as any, {})
    }, [endLoading, setAnswer, getTaskRepository, onSubmit])

    return { startLoading, loading, answer, onChangeAnswer, handleSubmit, files, setFiles }
}


interface EditTaskAnswerProps extends EditTaskRepositoryAnswerLogicProps {
    saveAnswer: (id: number, content: string) => Promise<void>
    setOpenModal: SetEditDeleteModalTy

}

const EditTaskAnswer: React.FC<EditTaskAnswerProps> = ({ answer, saveAnswer, setOpenModal, handleEdit }) => {
    const { editAnswer, restoreContent, setEditAnswer } = useEditTaskRepositoryAnswerLogic({ answer, handleEdit })
    return (
        <Fragment>
            <TextArea className='flex-auto light12 task_repository_answer_component_textarea task_edit_answer_component_textarea' value={editAnswer} onChange={setEditAnswer} skipTimeout={true} />
            <ButtonPrimary className='mr-15 task_repository_edit_answer_btn' onClick={() => saveAnswer(answer.id, editAnswer)}><TwinTrans transKey='save'>Guardar</TwinTrans></ButtonPrimary>
            <TwinIcon className='mr-15 cursor-pointer hover:text-green-43 task_repository_answer_icon' icon={faRotateLeft} onClick={restoreContent} />
            <TwinIcon icon={faTrashCan} className='cursor-pointer hover:text-red-BA task_repository_answer_icon' onClick={() => setOpenModal({ type: 'delete', allRowData: { TaskRepositoryAnswerId: answer.id } })} />
        </Fragment>
    )
}

interface EditTaskRepositoryAnswerLogicProps {
    answer: TaskRepositoryAnswerModelTypeExt
    handleEdit: () => void
}

const useEditTaskRepositoryAnswerLogic = ({ answer, handleEdit }: EditTaskRepositoryAnswerLogicProps) => {
    const [editAnswer, setEditAnswer] = useState<string>(answer?.content || '')
    const restoreContent = useCallback(() => {
        setEditAnswer(answer?.content || '')
        handleEdit()
    }, [answer?.content, handleEdit])

    return { editAnswer, setEditAnswer, restoreContent }
}

export default TaskRepositoryAnswers