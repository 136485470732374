import { faChevronRight, faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { TaskModelType } from '@teinor/erp/types/company/task'
import { useCallback, useMemo, useState } from 'react'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import { ColumnTableSchema } from '../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { addDictionaryExtraLine } from '../../../../utils/globals/dictionary'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getEmployees, getGroups, getTaskCFields, getUserInfo } from '../../../../utils/reducers/getters'
import { CustomRenderTaskCField, CustomRenderTaskNameAndDate, CustomRenderTaskRoundImage, CustomRenderTasksFinished } from '../../../Task/TaskScreenTable/Subcomponents'
import TaskTable from '../../../Task/TaskScreenTable/TaskTable'
import { CustomerProjectModelTypeExtended } from '../types'
import CustomSelect from '../../../../forms/CustomSelect'
import { TableInstanceType } from '../../../../baseComponents/TwinTable/types'
import { TableWhereSearchBarTy } from '../../../Task/TaskScreenTable/types'
import useTaskHeaderSelectorsDict from '../../../../utils/hooks/tasks/useTaskHeaderSelectorsDict'
import { sortEmployeesByUser } from '../../../../utils/globals/employees'
import { sortGroupsByUser } from '../../../../utils/globals/groups'
import { useTaskScreenTableLogic } from '../../../Task/TaskScreenTable'

interface CustomerProjectTasksTabProps extends CustomerProjectTasksTabLogicProps{
}

const CustomerProjectTasksTab: React.FC<CustomerProjectTasksTabProps> = ({ customerProjectData, setSelectedTaskId }) => {
    const {t} = useTwinTranslation()
    const { handleCreatedTask, changeHeaderTableWhereParams, headerTableWhereParams, setTableInstance } = useCustomerProjectTasksTabLogic({ customerProjectData, setSelectedTaskId })
    const CustomerProjectId = customerProjectData.id
    const name = 'tableCustomerProjectTasksTab'
    const taskCFields = getTaskCFields()
    const employees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(getEmployees() || {}))), [])
    const groups = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(getGroups() || {}))), [])
 
    const baseColumns: ColumnTableSchema<TaskModelType> = [
        {
            id: 'finished',
            dataKey: 'finished',
            label: '',
            customRender: (parameterValue, allRowData) => <CustomRenderTasksFinished value={String(parameterValue) === 'true'} allRowData={allRowData} width={45} />,
            width: 50
        },
        {
            id: 'id',
            dataKey: 'id',
            label: t('nº', 'Nº'),
            customRender: (parameterValue) => <div className='text-green-43'>#{parameterValue}</div>,
            sortable: true,
            width: 75
        },
      {
            id: 'nameAndDate',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchKey: 'Task.name',
            sortKey: 'name',
            simpleLabel: t('nameAndDate', 'Nombre y fecha'),
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskNameAndDate allRowData={allRowData} />,
            sortable: true,
            minWidth: 120
        },
    ]
    const baseColumnsEnd: ColumnTableSchema<TaskModelType> = [
        {
            id: 'EmployeeId',
            dataKey: 'EmployeeId',
            simpleLabel: t('employee', 'Empleado'),
            label: () => <TwinIcon icon={faUser} className='w-16 h-16 mx-auto' />,
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskRoundImage id={allRowData?.id} value={allRowData?.EmployeeId} field='EmployeeId' items={employees || {}} icon={faUser} img={allRowData?.Employee?.profile_image} name={allRowData?.Employee?.fullname_short} sortFunction={sortEmployeesByUser} />,
            width: 50

        },
        {
            id: 'GroupId',
            dataKey: 'GroupId',
            simpleLabel: t('group', 'Grupo'),
            label: () => <TwinIcon icon={faUserGroup} className='w-16 h-16 mx-auto' />,
            customRender: (_parameterValue, allRowData) => <CustomRenderTaskRoundImage id={allRowData?.id} value={allRowData?.GroupId} field='GroupId' items={groups || {}} icon={faUserGroup} img={allRowData?.Group?.photo} name={allRowData?.Group?.name} sortFunction={sortGroupsByUser} />,
            width: 50
        },
        {
            id: 'openTask',
            dataKey: 'id',
            label: '',
            width: 40,
            customRender: (parameterValue) => <div onClick={() => setSelectedTaskId(parseInt(parameterValue))} className='pl-15 pr-15'><TwinIcon icon={faChevronRight} /></div>,
        },
        
    ]

    const columnCustomFields: ColumnTableSchema<TaskModelType> = []

    if (taskCFields) {
        const taskCFieldsOrdered = []
        for (const key in taskCFields) {
            if (taskCFields[key].order <= 2) {
                taskCFieldsOrdered.push(taskCFields[key])
            }
        }
        taskCFieldsOrdered.sort((a, b) => a.order - b.order)
        for (const element of taskCFieldsOrdered) {
            columnCustomFields.push({
                id: 'customField_' + element?.id,
                dataKey: 'customField_' + element?.id as any,
                label: element?.name || '',
                customRender: (_parameterValue, allRowData) => <CustomRenderTaskCField key={allRowData?.id} id={'main'} allRowData={allRowData} dictTaskCFieldValues={element?.dictTaskCFieldValues || {}} customFieldId={String(element?.id)} />,
                width: 150,
                sortable: true,
            })
        }
    }

    const columns: ColumnTableSchema<TaskModelType> = [
        ...baseColumns,
        ...columnCustomFields,
        ...baseColumnsEnd
    ]

    const { setSelectedColsFiltersHeader, selectedColsFiltersHeader, memorizedColumns } = useTaskScreenTableLogic({ name, limitPath: '', floatingTasks: true, columns })

    const { dictFinished } = useTaskHeaderSelectorsDict()

    return (
        <div className='flex flex-col flex-auto taskDoubleTable'>
            <div className='flex'>
                <CustomSelect className='w-120 mt-10' label={t('status', 'Estado')} items={dictFinished} value={headerTableWhereParams.finished} onChange={(value) => changeHeaderTableWhereParams('finished', value)} />
                <button className='text-green-43 mt-20 ml-auto' onClick={handleCreatedTask}><TwinTrans transKey='addTask'>Agregar tarea</TwinTrans></button>
            </div>
            <TaskTable name={name} createRowDataLink={['/task/tasks', ['$id']]} defaultOrder={{ defaultOrderField: 'finished', order: 'asc' }} setSelectedColsFiltersHeader={setSelectedColsFiltersHeader} memorizedColumns={memorizedColumns} selectedColsFiltersHeader={selectedColsFiltersHeader} generalSearchbar='' columns={columns} getDataFrom='/api/app/task/getAllTasks' extraWhereParams={{ CustomerProjectId }} listenSocketStr={'getAllTasksCustomer_' + customerProjectData.CustomerId} headerTableWhereParams={headerTableWhereParams} floatingTasks={false} simpleDivContainer={true} setTableInstance={setTableInstance} />
        </div>)
}

interface CustomerProjectTasksTabLogicProps {
    customerProjectData: CustomerProjectModelTypeExtended
    setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>
}

const useCustomerProjectTasksTabLogic = ({ customerProjectData, setSelectedTaskId }: CustomerProjectTasksTabLogicProps) => {
    const [tableInstance, setTableInstance] = useState<TableInstanceType>(null)
    const [headerTableWhereParams, setHeaderTableWhereParams] = useState<TableWhereSearchBarTy>({
        generalSearchBar: '',
        start_date: '',
        end_date: '',
        finished: 0,
        assigned: 1,
        visibles: 2
    })
    
    const changeHeaderTableWhereParams = useCallback((field: keyof TableWhereSearchBarTy, value: string | number) => {
        setHeaderTableWhereParams((old) => {
            const copyOld = JSON.parse(JSON.stringify(old))
            return { ...copyOld, [field]: value }
        })
        tableInstance?.getTableDataFromStart()
    }, [setHeaderTableWhereParams, tableInstance])

    const user = getUserInfo()
    const handleCreatedTask = useCallback(async() => {
        const createdTask = await twinFetchPostJSON('/api/app/task/createTask', { WorkingCenterId: user?.WorkingCenterId, ...{ CustomerProjectId: customerProjectData?.id, CustomerId: customerProjectData?.CustomerId } })
        setSelectedTaskId(createdTask.id)
        
    }, [user?.WorkingCenterId, customerProjectData, setSelectedTaskId])

    return { handleCreatedTask, changeHeaderTableWhereParams, headerTableWhereParams, setTableInstance }
}

export default CustomerProjectTasksTab