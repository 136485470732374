


import { CustomRenderDisplayHoursByMins } from '../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { VirtualTableListingStateLess } from '../../../../baseComponents/TwinTable/VirtualTableListing'
import { ColumnTableSchema } from '../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { TableComponentProps } from '../../../../baseComponents/TwinTable/BaseScreenTable/types'
import useDictMonths from '../../../../utils/hooks/useDictMonths'
import { displayFormatedHourFromMins } from '../../../../utils/globals/date'
import { TimeControlEmployeeJourneyByYearProps } from '../types'
import { timeControlEmployeeMonthYearRowRender } from '../functions'
import { RenderMonthOrTotals } from './Subcomponents'
import { RowData } from '../../../../baseComponents/TwinTable/types'
import { EmployeTTShowPermittedTimeInversedKeys } from '../../../../app/erp/screens/Staff/WorkingCalendarParent/TimeControlEmployeeMonth/types'
import { TwinDictionary } from '../../../../utils/globals/dictionary'

type TableControlYearWorkProps = TableComponentProps & {
    data: TimeControlEmployeeJourneyByYearProps[] | null
    year: number
    showPermittedTime?: EmployeTTShowPermittedTimeInversedKeys
}
const TableControlYearWork: React.FC<TableControlYearWorkProps> = ({ data, year, showPermittedTime }) => {
    const { t } = useTwinTranslation()
    const { dictMonths } = useDictMonths()
    const permittedTime = showPermittedTime === 'efective_hours' ? true : false

    const columns: ColumnTableSchema<TwinDictionary> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('monthHeader', 'Mes'),
            customRender: (parameterValue, allRowData) => <RenderMonthOrTotals value={dictMonths[parameterValue]} {...{ allRowData }} />
        },
        {
            id: permittedTime ? 'totalMinsPermittedWorked' : 'totalMinsWorked',
            dataKey: permittedTime ? 'totalMinsPermittedWorked' : 'totalMinsWorked',
            label: t('workedHours', 'Horas trabajadas'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursByMins value={parameterValue}  />,

        },
        {
            id: 'totalMinsToDo',
            dataKey: 'totalMinsToDo',
            label: t('hoursToDo', 'Horas a hacer'),
            customRender: (parameterValue) => <CustomRenderDisplayHoursByMins value={parameterValue} />,
        },
        {
            id: permittedTime ? 'balancePermittedMinsToDo' : 'balanceMinsToDo',
            dataKey: permittedTime ? 'balancePermittedMinsToDo' : 'balanceMinsToDo',
            label: t('balance', 'Balance'),
            headerClassName: 'flex justify-center items-center',
            className: 'flex justify-center items-center',
            customRender: (parameterValue, allRowData) => <RenderTotalMonthBalanceHours balance={parseFloat(parameterValue)} year={year} month={allRowData.id} allRowData={allRowData} permittedTime={permittedTime}/>,
        },
    ]
    return (
        <div className={'flex flex-col flex-auto h-1'}>
            <div className='flex-auto'>
                <VirtualTableListingStateLess columns={columns} setSelectedColumnsAndFilters={() => { }} tableData={data || []} rowHeight={48} headerHeight={48} name='yearWorkDays' rowRenderer={timeControlEmployeeMonthYearRowRender} />
            </div>
        </div>
    )
}

interface RenderTotalMonthBalanceHoursProps {
    year: number
    month: number
    balance: number
    allRowData: RowData
    permittedTime: boolean
}

const RenderTotalMonthBalanceHours: React.FC<RenderTotalMonthBalanceHoursProps> = ({ year, month, balance, allRowData, permittedTime }) => {
    const now = new Date()
    const thisStartMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const startMonthDate = new Date(year, month - 1, 1)
    if (startMonthDate > thisStartMonth) {
        return <div></div>
    } 
    let balanceClassName = 'rounded-xl balance_total ' + (balance === 0 ? 'balance_correct' : balance > 0 ? 'balance_more_hours' : 'balance_less_hours')
    if (allRowData.type === 'totals') {
        balance = (permittedTime ? allRowData?.balancePermittedMinsToDo : allRowData?.balanceMinsToDo) || 0
        balanceClassName = 'balance_total ' + (balance > 0 ? 'balance_total_more_hours' : balance < 0 ? 'balance_total_less_hours' : '')
    }
    return (
        <div className={balanceClassName}>
            {displayFormatedHourFromMins(balance)}
        </div>
    )
}

export default TableControlYearWork