import { faChevronLeft, faChevronRight, faPlus} from '@fortawesome/pro-light-svg-icons'
import { WCalHolidayUsedDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag/WCalHolidayUsedDay'
import { useState, useCallback, useEffect } from 'react'
import { ButtonSecondary, ButtonPrimary } from '../../../../../../../baseComponents/Button'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import TitleAndWhiteBoxLayout from '../../../../../../../baseComponents/Layout/TitleAndWhiteBoxLayout'
import PermissionChecker from '../../../../../../../baseComponents/PermissionChecker'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateWCHolidayRequest from '../ModalCreateWCHolidayRequest'
import { TwinBigCalendarEvents } from '../../../../../../../baseComponents/TwinBigCalendar/types'
import TwinBigCalendar from '../../../../../../../baseComponents/TwinBigCalendar'
import { timeControlParseAllEmployeeHolidaysToEvents } from '../../../../../../../baseComponents/Dashboard/Components/HolidayCalendar/functions'
import { EventsWorkingCalendar } from '../../../../../../../baseComponents/WorkingCalendar'


interface WCHolidayRequestCalendarViewProps extends ComponentWithPermissions {
    setView: React.Dispatch<React.SetStateAction<'default' | 'bars_view' | 'calendar_view'>>
}


const WCHolidayRequestCalendarView: React.FC<WCHolidayRequestCalendarViewProps> = ({ setView, userPermissions }) => {
    const { t } = useTwinTranslation()
    const { getData, openModal, setOpenModal, setYear, year, events } = useWCHolidayRequestCalendarViewLogic()
    
    return (
        <TitleAndWhiteBoxLayout title={t('holidayListing', 'Listado de vacaciones')} RightHeader={
            <div className='flex items-center'>
                <ButtonSecondary className='mr-5' onClick={() => setView('default')}><TwinTrans transKey='changeView'>Cambiar vista</TwinTrans></ButtonSecondary>
                <PermissionChecker userPermissions={userPermissions} permission='create'>
                    <ButtonPrimary onClick={() => setOpenModal({ type: 'create', allRowData: {} })}>
                        <TwinIcon icon={faPlus} className='mr-8' />
                        <TwinTrans transKey='newHolidayRequest'>Nueva solicitud de vacaciones</TwinTrans>
                    </ButtonPrimary>
                </PermissionChecker>
            </div>
        }>
            <WCHolidayFullCalendar year={year} setYear={setYear} events={events}/>
            {openModal?.type === 'create' && <ModalCreateWCHolidayRequest {...{ setOpened: () => setOpenModal(null) }} onSubmit={getData} />}
        </TitleAndWhiteBoxLayout >
    )
}

const useWCHolidayRequestCalendarViewLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const { t } = useTwinTranslation()
    const pendingText = t('pending', 'Pendiente')
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [events, setEvents] = useState<TwinBigCalendarEvents | null>(null)

    const parseResultToData = useCallback((result: WCalHolidayUsedDayModelType[]) => {
        const holidaysEvents = timeControlParseAllEmployeeHolidaysToEvents(result, pendingText, '')
        const data: TwinBigCalendarEvents = [...holidaysEvents]
        return data
    }, [pendingText])

    const getData = useCallback(async () => {
        const dateStart = new Date(year, 0, 1).toDateString()
        const dateEnd = new Date(year, 11, 31).toDateString()
        setEvents(null)
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getAllEmployeeHolidaysByDates', { dateStart, dateEnd, order: [['from', 'ASC']] })

        if (result) {
            const parsedData = parseResultToData(result)
            setEvents(parsedData)
        }
    }, [parseResultToData, year, setEvents])

    useEffect(() => {
        getData()
    }, [getData])


    return { getData, openModal, setOpenModal, setYear, year, events }
}

interface WCHolidayFullCalendarProps extends WCHolidayFullCalendarLogicProps {
    year: number
    events: TwinBigCalendarEvents | null
}

const WCHolidayFullCalendar: React.FC<WCHolidayFullCalendarProps> = ({ year, setYear, events }) => {
    const { handleOnNavigate } = useWCHolidayFullCalendarLogic({ setYear })
    
    return (
        <div className='flex flex-auto h-1 white_box_calendar_events overflow-hidden'>
            <div className='flex flex-auto flex-col white_box_calendar_events_wrapper'>
                <div className='flex items-center calendar_toolbar'>
                    <div className='cursor-pointer pr-24 hover:text-green-21' onClick={() => setYear((old) => old - 1)}><TwinIcon className='h-24' icon={faChevronLeft} /></div>
                    <div className='cursor-pointer pr-24 hover:text-green-21' onClick={() => setYear((old) => old + 1)}><TwinIcon className='h-24' icon={faChevronRight} /></div>
                    <div className='toolbar-label  text-left text-gray-51 capitalize'>{year}</div>
                </div>
                <TwinBigCalendar date={new Date(`${year}-01-01`)} events={events || []} defaultView='year' onNavigate={handleOnNavigate} className='whitebox_calendar_events_big_calendar' />
            </div>
            <EventsWorkingCalendar events={events}/>
        </div>
    )
}

interface WCHolidayFullCalendarLogicProps {
    setYear: React.Dispatch<React.SetStateAction<number>>
}

const useWCHolidayFullCalendarLogic = ({ setYear }: WCHolidayFullCalendarLogicProps) => {
    const handleOnNavigate = useCallback((date: Date) => {
        setYear(date.getFullYear())
    }, [setYear])
    return { handleOnNavigate }
}

export default WCHolidayRequestCalendarView