import { renderSwitchItemByActive } from './functions'
import './switchbutton.sass'
export interface TabItems {
    element: JSX.Element
}
interface SwitchButtonProps {
    onClick: (position: number) => void
    active: number
    items: TabItems[]
    className?: string
}

export const SwitchButton: React.FC<SwitchButtonProps> = ({ onClick, active, items, className }) => {
    const renderItems: JSX.Element[] = []
    for (const key in items) {
        const element = items[key].element
        const SwitchButtonItem = renderSwitchItemByActive(parseInt(key), active)
        renderItems.push(<SwitchButtonItem onClick={() => onClick(parseInt(key))} key={key}>{element}</SwitchButtonItem>)
    }
    return (
        <div className={'flex cursor-pointer switch_button ' + (className || '')}>
            {renderItems}
        </div>
    )
}



interface SwitchButtonActiveProps {
    children?: React.ReactNode
}

export const SwitchButtonActive: React.FC<SwitchButtonActiveProps> = ({children}) => {
    return (
        <div className='flex items-center rounded-3xl bg-green-43 text-white px-4 switch_button_tab'>{children}</div>
    )
}

interface SwitchButtonNotActiveProps {
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    children?: React.ReactNode
}

export const SwitchButtonNotActive: React.FC<SwitchButtonNotActiveProps> = ({ onClick, children }) => {
    return (
        <div className='flex items-center switch_button_tab' onClick={(e) => onClick(e)}>{children}</div>
    )
}
