import { sortItems } from '../../forms/CustomSelect/DropDown/functions'
import { ItemCustomSelectDropDownRow } from '../../forms/CustomSelect/DropDown/types'
import { getUserInfo } from '../reducers/getters'
import { TwinDictionary } from './dictionary'

export const sortEmployeesByUser = (employees: TwinDictionary) => {
    const copyEmployees = JSON.parse(JSON.stringify(employees))
    const result: ItemCustomSelectDropDownRow[] = [{ value: '', text: '---' }]
    const user = getUserInfo()
    if (user && user.Employee) {
        result.push({ value: user.Employee.id, text: user.Employee.fullname_short })
        delete copyEmployees[user.Employee.id]
        const sorted = sortItems(copyEmployees, 'fullname_short')
        if (!sorted?.[0]?.value) {
            sorted.shift()
        }
        return [...result, ...sorted]
    }
    return []
}