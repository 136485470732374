import { faBriefcase, faEnvelope, faMobile, faNotes, faPhone, faUser, faXmark } from '@fortawesome/pro-light-svg-icons'
import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import SearchBar from '../../../../baseComponents/SearchBar'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import TwinToolTip from '../../../../baseComponents/TwinToolTip'
import { DropDownWithSearchBar } from '../../../../forms/CustomSelect/DropDown'
import { InputHidden } from '../../../../forms/Input'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../utils/globals/dictionary'
import { inputFocusClass } from '../../../../utils/globals/tailwind'
import useOnClickOutside from '../../../../utils/hooks/useOnClickOut'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { CustomerPayload, ReduxCustomerTypes } from '../../../../utils/reducers/customers/customer'
import { TASK_PROJECT_DEFAULT_COLOR } from '../../../Task/variables'
import { CustomSelectSectionSearchbarDropDown } from '../../../../forms/CustomSelect/CustomSelectSections'
import { parseSearchCustomerProjectToSections } from '../functions'
import { CustomSelectTaskContactInfoRow, CustomSelectTaskContactInfoRowClipboard, CustomSelectTaskContactNotes,  } from '../../../Task/Components/CustomSelectTaskContactInfo'
import { parsePhoneNumber } from '../../../../utils/globals/phone'
import './searcher_customer_project_simple.sass'

interface SearcherCustomerProjectSimpleProps extends SearcherCustomerProjectSimpleLogicProps {
    className?: string
    label?: string
    name?: string
    CustomerProjectId?: number
}

const SearcherCustomerProjectSimple: React.FC<SearcherCustomerProjectSimpleProps> = ({ customers, className, handleAddCustomerProject, label, name, CustomerId, CustomerProjectId }) => {
    const { t } = useTwinTranslation()
    const { ref, showDropdown, handleFocusSearchbar, selectedCustomerId, handleSelectCustomerProjectId, handleSelectedCustomerId, customerProjects, customersSectionItems, indexCustomers } = useSearcherCustomerProjectSimpleLogic({customers, handleAddCustomerProject, CustomerId })
    if (CustomerProjectId && selectedCustomerId && customerProjects) {
        return (<CustomerProjectSelected customer={indexCustomers[selectedCustomerId]} label={label} project={customerProjects[CustomerProjectId]} {...{ customers, handleSelectCustomerProjectId, className, name }} />)
    }
    return (
        <div>
            <label className={'cursor-pointer searcher_customer_project_simple_label'}>{label}</label>
            {showDropdown ?
                <div ref={ref} className={'searcher_customer_project_simple w-full searcher_customer_open ' + (className || '')} >
                    {!selectedCustomerId ?
                        <CustomSelectSectionSearchbarDropDown items={customersSectionItems} onChange={handleSelectedCustomerId} value={''}/>
                        :
                        <div>
                            <div className='flex relative searcher_project_simple_container'>
                                <div className='h-34 bg-gray-F7 px-12 flex items-center cursor-pointer w-fit max-w-1/2 twin_elipsis' title={indexCustomers[selectedCustomerId]?.text}  onClick={() => handleSelectCustomerProjectId(null)}>{indexCustomers[selectedCustomerId]?.text}</div>
                                <div className='relative mr-20'>
                                    <div className='arrow_right'></div>
                                </div>
                                <DropDownWithSearchBar items={customerProjects} onChange={handleSelectCustomerProjectId} value={''} />
                            </div>
                        </div>
                    }
                </div >
                :
                <div className={'searcher_customer_project_simple w-full ' + (className || '')}>
                    <SearchBar className='custom_select_search_bar' placeholder={t('search', 'Buscar')} onChange={() => { }} searchValue='' onFocus={handleFocusSearchbar} />
                </div>
            }
        </div>
    )
}

interface SearcherCustomerProjectSimpleLogicProps {
    customers: CustomerPayload
    handleAddCustomerProject?: (CustomerProject: number | null) => Promise<boolean>
    CustomerId?: number
}

const useSearcherCustomerProjectSimpleLogic = ({handleAddCustomerProject, CustomerId, customers}: SearcherCustomerProjectSimpleLogicProps ) => {
    const [showDropdown, setShowDropdown] = useState<true | null>(null)
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(CustomerId || null)
    const [customerProjects, setCustomerProjects] = useState<TwinDictionary>({})
    const ref = useRef<any>(null)
    useOnClickOutside(ref, () => resetAll())
    
    const { t } = useTwinTranslation()
    const customersTrans = t('customers', 'Clientes')
    const customersB2BTrans = t('customersB2B', 'Clientes B2B')
    const contactTrans = t('contacts', 'Contactos')
    const { customersSectionItems, indexCustomers } = useMemo(() => {
        const types: Record<ReduxCustomerTypes, string> = {
            'customers': customersTrans,
            'customersB2B': customersB2BTrans,
            'contacts': contactTrans
        };
        return parseSearchCustomerProjectToSections(customers, types)
    }, [customers, customersTrans, customersB2BTrans, contactTrans])
    
    const getCustomerProjects = useCallback(async (CustomerId: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/getAllCustomerProjects', {
            where: {
                CustomerId
            }
        })
        if (result) {
            setCustomerProjects(dictionaryComplexFromJsonArr(result))
        }
    }, [setCustomerProjects])

    const handleFocusSearchbar = useCallback(async () => {
        await flushSync(async () => {
            await setShowDropdown(true)
            const element = document.getElementsByClassName('searcher_customer_open')[0]?.getElementsByClassName('custom_select_dropdown')[0]?.getElementsByClassName('custom_select_search_bar')[0]?.getElementsByClassName('input_field')[0]?.getElementsByClassName('input_with_icon')[0]
            element.classList.add(inputFocusClass)
            element?.querySelector('input')?.focus()
        })
    }, [setShowDropdown])

    const resetAll = useCallback(() => {
        setSelectedCustomerId(null)
        setShowDropdown(null)
    }, [setSelectedCustomerId, setShowDropdown])


    const handleSelectCustomerProjectId = useCallback(async (customerProjectId: number | null) => {
        await handleAddCustomerProject?.(customerProjectId)
        if (customerProjectId === null) {
            resetAll()
        }
    }, [resetAll, handleAddCustomerProject])

    const handleSelectedCustomerId = useCallback(async (id: number) => {
        setSelectedCustomerId(id)
    }, [setSelectedCustomerId])

    useEffect(() => {
        if (selectedCustomerId) {
            getCustomerProjects(selectedCustomerId)  
        }
    }, [getCustomerProjects, selectedCustomerId])

    return { selectedCustomerId, handleSelectCustomerProjectId, handleSelectedCustomerId, customerProjects, ref, showDropdown, handleFocusSearchbar, customersSectionItems, indexCustomers }
}

interface CustomerProjectSelectedProps {
    customer: CustomerModelType
    project: CustomerProjectModelType
    handleSelectCustomerProjectId: (CustomerProjectId: number | null) => void
    className?: string
    label?: string
    name?: string
}

const CustomerProjectSelected: React.FC<CustomerProjectSelectedProps> = ({ project, handleSelectCustomerProjectId, customer, className, label, name }) => {
    return (
        <div>
            <label className={'searcher_customer_project_simple_label'}>{label}</label>
            <TwinToolTip place='top' anchorSelect={'#project' + project?.name} clickable>
                <div>
                    <div>{customer?.code || customer?.id} - {customer?.name} - {project?.name}</div>
                    <CustomSelectTaskContactInfoRowClipboard icon={faBriefcase} text={customer?.fiscal_name} />
                    <CustomSelectTaskContactInfoRowClipboard icon={faPhone} text={parsePhoneNumber(customer?.phone || '')} />
                    <CustomSelectTaskContactInfoRowClipboard icon={faMobile} text={parsePhoneNumber(customer?.mobile || '')} />
                    <CustomSelectTaskContactInfoRowClipboard icon={faEnvelope} text={customer?.email} />
                    <CustomSelectTaskContactInfoRow icon={faUser} text={(customer as TwinDictionary)?.ResponsibleEmployee?.fullname_short} />
                    <CustomSelectTaskContactNotes icon={faNotes} notes={customer?.notes} />
                </div>
            </TwinToolTip>
            <div className={'bg-gray-F7 selected_project selected_project_simple ' + (className || '')} id={'project' + project?.name}>
                {project && project.name ?
                    <div className='flex items-center px-14 py-10'>
                        <span className='twin_elipsis'>{project?.CustomerId} - {customer.name} - {project?.name}</span>
                        <div className='mr-10 ml-10 min-h-16 min-w-16 rounded-full' style={{ 'background': project?.CustomerProjectType?.color || TASK_PROJECT_DEFAULT_COLOR }}></div>
                        <TwinIcon className='cursor-pointer ml-auto hover:text-red-BA' icon={faXmark} onClick={() => handleSelectCustomerProjectId(null)} />
                    </div>
                : null}
                {name ? <InputHidden name={name} value={project?.id || ''} /> : null}
            </div>
        </div>
    )
}


export default SearcherCustomerProjectSimple