import { useEffect, useMemo, useState } from 'react'
import { SelectedColumnsTy, SelectedFiltersTy, SelectedOrderTy } from '../useConfigColumns'
import { indexKeyDictionaryFromJsonArray, TwinDictionary } from '../../globals/dictionary'
import { ConfigColumnsColumn } from '../../../baseComponents/ModalsLayouts/ModalConfigColumnsTabs/types'
import { getEmployeeParams } from '../../reducers/getters'
import { DictTaskTableHeaderTy } from '../../../specificComponents/Task/TaskScreenTable/ModalTaskConfigColumnsTabs/types'

interface TaskConfigColumnsProps {
    columns: ConfigColumnsColumn<any>[]
    headers: DictTaskTableHeaderTy
    name: string
}

export interface SelectedTaskColsFiltersHeader {
    selectedColumns: SelectedColumnsTy
    filters: SelectedFiltersTy
    id?: number
    name?: string
    order: SelectedOrderTy
    selectedHeader: TwinDictionary
}

const useTaskConfigColumns = ({ columns, name, headers }: TaskConfigColumnsProps) => {
    const [selectedColsFiltersHeader, setSelectedColsFiltersHeader] = useState<SelectedTaskColsFiltersHeader>(getTaskTableSelectedColumns(name, columns, headers))
    const memorizedColumns = useMemo(() => getOnlySelectedColumns(columns, selectedColsFiltersHeader.selectedColumns), [columns, selectedColsFiltersHeader.selectedColumns]) as any
    const memorizedHeaders = useMemo(() => getOnlySelectedHeaders(headers, selectedColsFiltersHeader.selectedHeader), [headers, selectedColsFiltersHeader.selectedHeader]) as any
    const cols = useMemo(() => JSON.stringify(columns.map((item) => { return { id: item.id, hideColumnByDefault: item.hideColumnByDefault } })), [columns])
    const headersmemo = useMemo(() => JSON.stringify(headers), [headers])

    useEffect(() => {
        const colsParsed = JSON.parse(cols)
        const headersParsed = JSON.parse(headersmemo)
        setSelectedColsFiltersHeader(getTaskTableSelectedColumns(name, colsParsed, headersParsed))
    }, [cols, name, headersmemo])

    return { selectedColsFiltersHeader, setSelectedColsFiltersHeader, memorizedColumns, filterColumns: selectedColsFiltersHeader.filters, memorizedHeaders }
}

const getOnlySelectedColumns = (columns: ConfigColumnsColumn[], selectedColumns: SelectedColumnsTy) => {
    if (!Object.keys(selectedColumns).length) {
        return columns.filter((item) => !item.hideColumnByDefault)
    }
    const result: ConfigColumnsColumn[] = []
    const dict = indexKeyDictionaryFromJsonArray(columns)
    for (const key in selectedColumns) {
        result.push({ ...columns[dict[key]], ...selectedColumns[key] })
    }
    return result
}
export const getOnlySelectedHeaders = (headers: DictTaskTableHeaderTy, selectedHeader: DictTaskTableHeaderTy) => {
    if (!selectedHeader || !Object.keys(selectedHeader).length) {
        const defaultSelected: TwinDictionary = {}
        for (const key in headers) {
            const element = headers[key]
            if (!element.hideByDefault) {
                defaultSelected[key] = {}
            }
        }
        return defaultSelected
    }
    return selectedHeader
}

export const getTaskTableSelectedColumns = (name: string, columns: ConfigColumnsColumn[], headers: DictTaskTableHeaderTy): SelectedTaskColsFiltersHeader => {
    return getEmployeeParams()?.['table_' + name] ? JSON.parse(getEmployeeParams()?.['table_' + name]) : getTaskOnlyDefaultColsAndHeader(columns, headers)
}

export const getTaskOnlyDefaultColsAndHeader = (columns: ConfigColumnsColumn[], headers: DictTaskTableHeaderTy) => {
    const result: SelectedTaskColsFiltersHeader = {
        selectedColumns: {},
        filters: {},
        order: {},
        selectedHeader: {}
    }
    for (const key in columns) {
        const item = columns[key]
        if (!item.hideColumnByDefault) {
            result.selectedColumns[item.id] = {}
        }
    }
    for (const key in headers) {
        const item = headers[key]
        if (!item.hideByDefault) {
            result.selectedHeader[key] = {}
        }
    }
    return result
}
export const getTaskOnlyDefaultHeader = (header: DictTaskTableHeaderTy) => {
    const result: SelectedTaskColsFiltersHeader = {
        selectedColumns: {},
        filters: {},
        order: {},
        selectedHeader: {}
    }
    for (const key in header) {
        const item = header[key]
        if (!item.hideByDefault) {
            result.selectedHeader[item.id] = {}
        }
    }
    return result
}

export default useTaskConfigColumns