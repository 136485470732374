import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { CustomerContactPersonModelType } from '@teinor/erp/types/company/customer/customerContactPerson'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { permissionCheck } from '../../../../../../../../../baseComponents/PermissionChecker/function'
import TwinTable from '../../../../../../../../../baseComponents/TwinTable'
import BaseScreenTableInner from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner'
import { TableComponentBaseScreenInnerProps } from '../../../../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { EditRow, DeleteRow } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateCustomerB2BContactPerson from './ModalCreateCustomerB2BContactPerson'
import ModalEditCustomerB2BContactPerson from './ModalEditCustomerB2BContactPerson'
import ModalDeleteCustomerB2BContactPerson from './ModalDeleteCustomerB2BContactPerson'

interface CustomerB2BContactPersonsProps extends ComponentWithPermissions {
    basicData: CustomerModelType | null
}

const CustomerB2BContactPersons: React.FC<CustomerB2BContactPersonsProps> = ({ basicData, userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('contactsPerson', 'Personas de contacto'),
        newText: t('addContactPerson', 'Nueva persona de contacto'),
    }

    return (
        <BaseScreenTableInner TableComponent={TableCustomerB2BContactPersons} CreateModalComponent={ModalCreateCustomerB2BContactPerson} EditModalComponent={ModalEditCustomerB2BContactPerson} DeleteModalComponent={ModalDeleteCustomerB2BContactPerson} pageData={pageData} userPermissions={userPermissions} extraWhereParams={{ CustomerId: basicData?.id, active: true }} />
    )
}
interface TableCustomerB2BContactPersonsProps extends TableComponentBaseScreenInnerProps { }

const TableCustomerB2BContactPersons: React.FC<TableCustomerB2BContactPersonsProps> = ({ setTableInstance, userPermissions, extraWhereParams, setOpened }) => {
    const { t } = useTwinTranslation()

    const columns: ColumnTableSchema<CustomerContactPersonModelType> = [
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            }
        },
        {
            id: 'position',
            dataKey: 'position',
            label: t('position', 'Cargo'),
            sortable: true,
        },
        {
            id: 'mobile',
            dataKey: 'mobile',
            label: t('mobile', 'Móvil'),
        },
        {
            id: 'phone',
            dataKey: 'phone',
            label: t('phone', 'Teléfono'),
        },
        {
            id: 'email',
            dataKey: 'email',
            label: t('email', 'Email'),
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]
    return (
        <TwinTable columns={columns} name='customerB2BContactPersons' getDataFrom='/api/app/customer/customerContactPerson/getAllCustomerContactPersons'
                 onRowClick={(_id, rowData) => setOpened({
                    type: 'edit',
                    allRowData: rowData
        })} setTableInstance={setTableInstance} extraWhereParams={extraWhereParams} />
    )
}

export default CustomerB2BContactPersons