import { WCalHolidayUsedDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag/WCalHolidayUsedDay'
import { displayDate } from '../../../../utils/globals/date'
import { TwinBigCalendarEvents } from '../../../TwinBigCalendar/types'
import { WCalFreeDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalFree/WCalFreeDay'

export const timeControlParseAllEmployeeHolidaysToEvents = (data: WCalHolidayUsedDayModelType[], pendingText: string, holidaysText: string) => {
    const events: TwinBigCalendarEvents = []
    if (data) {
        for (const item of data) {
            const startDate = new Date(item.from)
            const endDate = new Date(item.to)
            const subtitleExtra = item.approved === 0 ? `\n(${pendingText})` : ''
            events?.push({
                id: item.id,
                allDay: true,
                start: startDate,
                end: endDate,
                color: item.approved === 0 ?  '#B97607' : '#43BAA5',
                title: holidaysText + ' ' + item.WCalHolidayBag?.Employee?.fullname_short,
                subtitle: (displayDate(startDate) !== displayDate(endDate) ? displayDate(startDate) + ' - ' + displayDate(endDate) : displayDate(startDate)) + subtitleExtra,
            })
        }
    }
    events.sort((a,b) => (b.start || 0) < (a.start || 0 ) ? 1 : -1)
    return events
}

export const timeControlParseAllFreeDaysToEvents = (data: WCalFreeDayModelType[]) => {
    const events: TwinBigCalendarEvents = []
    if (data) {
        for (const item of data) {
            const startDate = new Date(item.day)
            const endDate = new Date(item.day)
            events?.push({
                id: item.id,
                allDay: true,
                start: startDate,
                end: endDate,
                color: item.color,
                title: item.name,
                subtitle: (item as any).WCalFree.name
            })
        }
    }
    return events
}