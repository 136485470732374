import { faArrowRotateRight, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import TwinIcon, { TwinIconToolTip } from '../../../../../../baseComponents/TwinIcon'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import TypeUserCompany from '../../../../components/TypeUserCompany'
import { UserBoxProps, UserBoxType } from './types'
import twinFetchPost from '../../../../../../utils/globals/data'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { changeSuccessMessage } from '../../../../../../utils/reducers/reduxDispatch'
import ModalSmallFilled from '../../../../../../baseComponents/ModalsLayouts/ModalSmallFilled'
import { ModalOpenedTy } from '../../../../../../baseComponents/Modal/types'

const UserBox: React.FC<UserBoxProps> = ({ user, type, myType, className, setOpenModal}) => {
    const { t } = useTwinTranslation()
    const { resendInviteUserEmail, opened, setOpened } = useUserBoxLogic({user})
    return (
        <div className={'user_company_box  text-gray-51 bg-white rounded-lg flex justify-between' + (className || '')}>
            <div className='flex'>
                <img src={user.img ?? '/user-png.png'} alt={user.name} className='rounded-full user_company_box_img' />
                <div className='flex flex-col'>
                    <div className='regular18 user_company_name'>{user.name ?? t('user', 'Usuario')}</div>
                    <div className='regular18 user_company_surname'>{user.surname ?? t('notCreated', 'no creado')}</div>
                    <div className='regular14 user_company_box_email my-5'>{user.email}</div>
                    <div className='flex'>
                        <TypeUserCompany type={type} />
                        {type === 100 ?
                            <div className='cursor-pointer ml-20' onClick={() => setOpened(true)} id='iconReSendInvitation'>
                                <TwinIconToolTip className='mr-6 w-20 h-20' icon={faArrowRotateRight} message={t('reSendInvitation', 'Reenviar invitación')} id='iconReSendInvitation' />
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            <DeleteUserIcon {...{
                type, myType, setOpenModal: () => setOpenModal({
                        type: 'delete',
                        allRowData: { UserId: user.id, type },
                    }
                )
            }} />
            {opened && <ModalSmallFilled opened={true} setOpened={setOpened} onClickOut={false} onAccept={resendInviteUserEmail} translations={{
                title: t('reSendInvitation', 'Reenviar invitación'),
                subtitle: t('YouWillSendAndEmailTo', 'Se mandará un correo a ') + `${user.email}. `+t('youWillSendAndEmailToReSendTheInvitation', '¿Quieres volver a enviar la invitación al email indicado?'),
                buttonAccept: t('accept', 'Aceptar')
            }} ></ModalSmallFilled>}
        </div>
    )
}
interface UserBoxLogicProps {
    user: UserBoxType
}

const useUserBoxLogic = ({ user}: UserBoxLogicProps) => {
    const Company = useParams()
    const { t } = useTwinTranslation()
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const successReSendMessage = t('successfullyResendInvitation', 'Invitación reenviada')

    const resendInviteUserEmail = useCallback(async () => {
        const result = await twinFetchPost('/api/company/resendInviteUserEmail', { CompanyId: Company.id, email: user.email })
        if (result) {
            changeSuccessMessage(successReSendMessage)
        }
    }, [Company, user, successReSendMessage])
    return { resendInviteUserEmail, opened, setOpened }
}

interface DeleteUserIconProps {
    myType: number
    type: number
    setOpenModal: () => void
}

const DeleteUserIcon: React.FC<DeleteUserIconProps> = ({ type, myType,  setOpenModal }) => {
    if (type <= myType) {
        return null
    }
    return (
        <TwinIcon icon={faTrashCan} onClick={()=>setOpenModal()} className='hover:text-red-BA cursor-pointer mt-2 delete_user_icon' size='lg' />
    )
}

export default UserBox