import { faPaperclip, faPenToSquare, faRotateLeft, faTrashCan, faUser } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useCallback, useState } from 'react'
import { ButtonDisabledOrLoader, ButtonPrimary } from '../../../../baseComponents/Button'
import { ModalDeleteFilledOutside } from '../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import { CustomRenderRoundedImage } from '../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { InputHidden } from '../../../../forms/Input'
import TextArea from '../../../../forms/Input/TextArea'
import TwinForm from '../../../../forms/TwinForm'
import { OnSubmit } from '../../../../forms/TwinForm/types'
import twinFetchPost, { twinFetchPostText } from '../../../../utils/globals/data'
import useEditDeleteModal, { SetEditDeleteModalTy } from '../../../../utils/hooks/useEditDeleteModal'
import useIsLoading from '../../../../utils/hooks/useIsLoading'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getUserInfo } from '../../../../utils/reducers/getters'
import { SetTaskDataExtended } from '../types'
import { TaskCommentModelTypeExt } from './types'
import { ImageInputFile } from '../../../../forms/ImageInput/types'
import FilesClipInput from '../../../../forms/FilesInput/FilesClipInput'
import { parseFileName } from '../../../../utils/globals/files'

interface TaskCommentsProps extends CreateTaskCommentLogicProps {
    TaskId: string
    comments: TaskCommentModelTypeExt[]
}

const TaskComments: React.FC<TaskCommentsProps> = ({ TaskId, comments, setData }) => {
    const renderThis: JSX.Element[] = []
    for (const key in comments) {
        const comment = comments[key]
        renderThis.push(
            <TaskComment key={comment.id} comment={comment} posArr={parseInt(key)} TaskId={TaskId} setData={setData} />
        )
    }

    return (
        <div className='task_comments'>
            <div className='bg-gray-F7 px-16 p-10'>
                <span className='regular18'>
                    <TwinTrans transKey='comments'>Comentarios</TwinTrans>
                </span>
            </div>
            <div className='comments_box'>
                {renderThis}
            </div>
            <div>
                <CreateTaskComment TaskId={TaskId} setData={setData}/>
            </div>
        </div>
    )
}

interface TaskCommentProps extends TaskMyCommentProps {
    comment: TaskCommentModelTypeExt
}

const TaskComment: React.FC<TaskCommentProps> = ({ comment, ...rest }) => {
    const userInfo = getUserInfo()
    const EmployeeId = userInfo?.Employee?.id
    const parseDate = new Date(comment?.createdAt).toLocaleString()
    const isMyComment = comment.EmployeeId === EmployeeId
    return (
        <div className='pt-20 flex'>
            <div className='flex flex-auto flex-col'>
                <div className='flex items-center'>
                    <CustomRenderRoundedImage className='mr-12 h-34 w-34' icon={faUser} img={comment.Employee?.profile_image || '/user-png.png'} name={comment.Employee?.name}  />
                    <span className='medium14 mr-12'>{comment.Employee?.fullname_short}</span>
                    <span className='light14 text-gray-51'>{parseDate}</span>
                </div>
                {isMyComment ? 
                    <TaskMyComment {...{ ...rest, comment }} /> 
                    : <TaskOtherComment {...{comment}} />
                }
                {comment.file_url ?
                    <a href={comment.file_url_parsed} className=' block mt-10 cursor-pointer mr-20' target='_blank' rel="noreferrer">
                        <TwinIcon className='mr-10 ' icon={faPaperclip} />
                        <span>{parseFileName(comment)}</span>
                    </a>
                    : null
                }
            </div>
        </div>
    )
}

interface TaskMyCommentProps extends TaskMyCommentLogicProps {
    comment: TaskCommentModelTypeExt
}

const TaskMyComment: React.FC<TaskMyCommentProps> = ({ comment, ...rest }) => {
    const { edit, handleEdit, deleteComment, saveComment, setOpenModal, openModal } = useTaskMyCommentLogic({ ...rest })
    const {t} = useTwinTranslation()
    return (
        <div className='flex items-center w-full border border-gray-D6 rounded-xl p-10 mt-10'>
            {edit ?
                <EditTaskComment {...{ comment, saveComment, setOpenModal, handleEdit }} />
                :
                <TaskCommentContent {...{ comment, handleEdit, setOpenModal}}/>
            }
            {openModal?.type === 'delete' ? <ModalDeleteFilledOutside translations={{
                title: t('deleteComment', 'Eliminar comentario'),
                subtitle: t('sureDeleteComment', '¿Estás seguro de querer eliminar este comentario?'),
                buttonCancel: t('cancel', 'Cancelar'),
                buttonAccept: t('delete', 'Eliminar'),
            }} opened={true} setOpened={() => setOpenModal(null)} onAccept={() => deleteComment(openModal?.allRowData?.CommentId)}/> : null}
        </div>
    )
}


interface TaskCommentContentProps {
    comment: TaskCommentModelTypeExt
    handleEdit: () => void
    setOpenModal: SetEditDeleteModalTy
}

const TaskCommentContent: React.FC<TaskCommentContentProps> = ({ comment, handleEdit, setOpenModal}) => {
    return (
        <Fragment>
            <div className={'light14 mr-auto pr-10 whitespace-pre-wrap ' + (comment.content ? '' : 'italic')}>
                {comment.content}
            </div>
            <TwinIcon className='mr-15 h-20 cursor-pointer hover:text-green-43' icon={faPenToSquare} onClick={handleEdit} />
            <TwinIcon className='mr-10 h-20 cursor-pointer hover:text-red-BA' icon={faTrashCan} onClick={() => setOpenModal({ type: 'delete', allRowData: { CommentId: comment.id } })} />
        </Fragment>
    )
}


interface TaskMyCommentLogicProps {
    TaskId: string
    posArr: number
    setData: SetTaskDataExtended
}
const useTaskMyCommentLogic = ({ posArr, setData, TaskId }: TaskMyCommentLogicProps) => {
    const [edit, setEdit] = useState<boolean>(false)
    const {openModal, setOpenModal} = useEditDeleteModal()
    const deleteComment = useCallback(async(id: number) => {
        if(id){
            await twinFetchPost('/api/app/task/comment/deleteTaskComment', { id, TaskId }, { myExtraHeaders: { 'type-update': 'singleTask' } })
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld.TaskComments.splice(posArr, 1)
                return { ...copyOld }
            })
        }
    }, [setData, posArr, TaskId])

    const handleEdit = useCallback(() => {
        setEdit((old)=>!old)
    }, [setEdit])

    const saveComment = useCallback(async(id: number, content: string) => {
        const result = await twinFetchPostText('/api/app/task/comment/updateTaskComment', { id, content, TaskId })
        if(result){
            setEdit(false)
        }
    }, [setEdit, TaskId])
   
    return { deleteComment, edit, handleEdit, saveComment, openModal, setOpenModal }
}


interface TaskOtherCommentProps {
    comment: TaskCommentModelTypeExt
}

const TaskOtherComment: React.FC<TaskOtherCommentProps> = ({comment}) => {
    if(!comment?.content){
        return null
    }
    return (
        <div className='flex items-center w-full border border-gray-D6 rounded-xl p-10 mt-10'>
            <div className={'light14 mr-auto pr-10 whitespace-pre-wrap ' + (comment.content ? '' : 'italic')}>
                {comment.content}
            </div>
        </div>

    )
}


interface CreateTaskCommentProps extends CreateTaskCommentLogicProps {
    TaskId: string
}

const CreateTaskComment: React.FC<CreateTaskCommentProps> = ({ TaskId, ...logic }) => {
    const { comment, handleSubmit, loading, onChangeComment, startLoading, files, setFiles } = useCreateTaskCommentLogic({ ...logic })
    const { t } = useTwinTranslation()
    const buttonIsDisabled =  comment || files.length ? false : true
    return (
        <TwinForm className='mt-30' action='/api/app/task/comment/createTaskComment' onSubmit={handleSubmit} beforeSubmitHandler={startLoading} imagepath={['tasks', TaskId]} extraProps={{ myExtraHeaders: { 'type-update': 'singleTask' } }}>
            <div className=' flex flex-auto items-center border border-gray-D6 px-8 py-6 rounded-lg task_create_comment'>
                <div className='flex flex-auto items-center pr-10'>
                    <TextArea className='w-full text-gray-51 task_comment_component_textarea ' name='content' value={comment} placeholder={t('writeAComment', 'Escribe un comentario')} onChange={onChangeComment} maxRows={3} />
                    <InputHidden name='TaskId' value={TaskId} />
                    {loading === false ? <FilesClipInput name='file_url' className='flex items-center' onChange={setFiles} /> : null}
                    <ButtonDisabledOrLoader buttonIsDisabled={buttonIsDisabled} textButton={t('add', 'Añadir')} {...{ loading }} />
                </div>
            </div>
        </TwinForm>
    )
}

interface CreateTaskCommentLogicProps {
    setData: SetTaskDataExtended
}

const useCreateTaskCommentLogic = ({ setData }: CreateTaskCommentLogicProps) => {
    const [comment, setComment] = useState<string>('')
    const [files, setFiles] = useState<ImageInputFile[]>([]);

    const { startLoading, endLoading, loading } = useIsLoading()
    const onChangeComment = useCallback((value: string) => {
        setComment(value)
    }, [setComment])
    const handleSubmit: OnSubmit = useCallback(async (res) => {
        const result = await res.json()
        setComment('')
        setFiles([])
        endLoading()
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.TaskComments.push(result)
            return { ...copyOld }
        })

    }, [endLoading, setComment, setData])

    return { startLoading, loading, comment, onChangeComment, handleSubmit, files, setFiles }
}


interface EditTaskCommentProps extends EditTaskCommentLogicProps {
    saveComment: (id: number, content: string) => Promise<void>
    setOpenModal: SetEditDeleteModalTy

}

const EditTaskComment: React.FC<EditTaskCommentProps> = ({ comment, saveComment, setOpenModal, handleEdit }) => {
    const { editComment, restoreContent, setEditComment } = useEditTaskCommentLogic({ comment, handleEdit })
    return (
        <Fragment>
            <TextArea className='flex-auto light12 task_comment_component_textarea task_edit_comment_component_textarea' value={editComment} onChange={setEditComment} skipTimeout={true} />
            <ButtonPrimary className='mr-15 task_edit_comment_button' onClick={() => saveComment(comment.id, editComment)}><TwinTrans transKey='save'>Guardar</TwinTrans></ButtonPrimary>
            <TwinIcon className='mr-15 cursor-pointer hover:text-green-43 task_comment_icon' icon={faRotateLeft} onClick={restoreContent} />
            <TwinIcon icon={faTrashCan} className='cursor-pointer hover:text-red-BA task_comment_icon' onClick={() => setOpenModal({ type: 'delete', allRowData: { CommentId: comment.id } })} />
        </Fragment>
    )
}

interface EditTaskCommentLogicProps {
    comment: TaskCommentModelTypeExt
    handleEdit: ()=>void
}

const useEditTaskCommentLogic = ({ comment, handleEdit}: EditTaskCommentLogicProps) => {
    const [editComment, setEditComment] = useState<string>(comment?.content || '')
    const restoreContent = useCallback(() => {
        setEditComment(comment?.content || '')
        handleEdit()
    }, [comment?.content, handleEdit])

    return { editComment, setEditComment, restoreContent }
}

export default TaskComments