import ModalDeleteFilled from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'


interface ModalDeleteContactContactPersonProps extends ModalEditComponentProps { }

const ModalDeleteContactContactPerson: React.FC<ModalDeleteContactContactPersonProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/customer/customerContactPerson/updateCustomerContactPerson',
        data: {
            id: allRowData?.id,
            active: false
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureDeleteContactPerson', '¿Seguro que quieres eliminar esta persona de contacto?'),
            subtitle: t('onceDeletedNotRecoverM', 'Una vez eliminado no podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteContactContactPerson