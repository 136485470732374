import { faPaperclip, faPenToSquare, faRotateLeft, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useState, useCallback } from 'react'
import { ButtonPrimary } from '../../../../baseComponents/Button'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import TextArea from '../../../../forms/Input/TextArea'
import { twinFetchPostJSON, twinFetchPostText } from '../../../../utils/globals/data'
import { displayDateWithFormattedHours } from '../../../../utils/globals/date'
import useEditDeleteModal, { UseEditDeleteModalProps, SetEditDeleteModalTy } from '../../../../utils/hooks/useEditDeleteModal'
import { getUserInfo } from '../../../../utils/reducers/getters'
import ModalDeleteCustomerProjectComments from './ModalDeleteCustomerProjectComments'
import { CustomerProjectCommentModelTypeExt } from '../types'
import { CustomerProjectRenderImageTooltip } from '../../Subcomponents'
import { parseFileName } from '../../../../utils/globals/files'

export interface CustomerProjectTabsInfoHeaderProps {
    image?: string
    secondText?: string
    headerText?: string
    date: string
    showImage?: boolean
    id?: number
    nameTrigger?: string
}

export const CustomerProjectTabsInfoHeader: React.FC<CustomerProjectTabsInfoHeaderProps> = ({ date, headerText, secondText, image, showImage = true, id, nameTrigger }) => {
    return (
        <div className='flex items-center gap-12'>
            {showImage ? <CustomerProjectRenderImageTooltip id={id || 0} img={image ?? '/user-png.png'} name={nameTrigger} tooltipName={'Employee'} /> : null}
            <div className='items-center flex'>
                <div className='font-medium'>
                    <span className='mr-3 '>{headerText}</span>
                    {secondText ? <span className='italic' >{secondText}</span> : null}
                </div>
                <span className='text-gray-9D ml-12'>{displayDateWithFormattedHours(new Date(date))}</span>
            </div>
        </div>
    )
}



interface CustomerProjectTabsCommentBoxProps {
    data: CustomerProjectCommentModelTypeExt
    getData: () => Promise<void>
}

export const CustomerProjectTabsCommentBox: React.FC<CustomerProjectTabsCommentBoxProps> = ({ data, getData }) => {
    const { openModal, setOpenModal } = useCustomerProjectTabsCommentBoxLogic()
    const user = getUserInfo()
    const id = user?.Employee?.id
    const isMyComment = id === data.Employee?.id
    return (
        <div className='flex flex-col'>
            {isMyComment ?
                <CustomerProjectMyComment openModal={openModal} getData={getData} setOpenModal={setOpenModal} data={data} />
                :
                <CustomerProjectOtherComment data={data} />
            }
            <div className='flex items-center gap-5 mt-8'>
                {data.file_url ?
                    <DownloadFile id={data.id} data={data} />
                    : null
                }
            </div>
            {openModal?.type === 'delete' ? <ModalDeleteCustomerProjectComments setOpened={() => setOpenModal?.(null)} onSubmit={() => getData?.()} id={openModal.allRowData.id} /> : null}
        </div>
    )
}

const useCustomerProjectTabsCommentBoxLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()

    return { openModal, setOpenModal }
}

interface DownloadFileProps {
    id: number
    data: CustomerProjectCommentModelTypeExt
}

const DownloadFile: React.FC<DownloadFileProps> = ({ id, data }) => {
    const { handleDownloadFile } = useDownloadFileLogic()
    return (
        <div onClick={(e) => handleDownloadFile(id, e)} className='text-12 cursor-pointer'>
            <TwinIcon className='mr-4' icon={faPaperclip} />
            <span>{parseFileName(data)}</span>
            <a className='hidden downloadfile' download={true} href='/' target='_blank' onClick={(e) => e.stopPropagation()}>a</a>
        </div>
    )
}

const useDownloadFileLogic = () => {
    const handleDownloadFile = useCallback(async (id: number, e: React.MouseEvent) => {
        const current = e.currentTarget
        const link = await twinFetchPostText('/api/app/customer/customerProject/customerProjectComment/getCustomerProjectCommentInstanceFile', { id })
        if (link && link !== 'false') {
            const a = current.querySelector('a')
            if (a) {
                a.href = link
                a.click()
            }
        }
    }, [])
    return { handleDownloadFile }
}

interface CustomerProjectMyCommentProps {
    data: CustomerProjectCommentModelTypeExt
    getData: () => Promise<void>
    openModal: UseEditDeleteModalProps
    setOpenModal: SetEditDeleteModalTy
}

const CustomerProjectMyComment: React.FC<CustomerProjectMyCommentProps> = ({ openModal, data, setOpenModal, ...logic }) => {
    return (
        <div className='flex flex-auto overflow-x-hidden items-center border border-gray-D6 rounded-xl p-10'>
            {openModal?.type === 'edit' ?
                <EditMyCustomerProjectComment data={data} openModal={openModal} setOpenModal={setOpenModal} getData={logic.getData} />
                :
                <CustomerProjectCommentContent data={data} setOpenModal={setOpenModal} />
            }
        </div>
    )
}

interface EditMyCustomerProjectCommentProps extends EditMyCustomerProjectCommentLogicProps {
    openModal: UseEditDeleteModalProps
}

const EditMyCustomerProjectComment: React.FC<EditMyCustomerProjectCommentProps> = ({ data, openModal, setOpenModal, getData }) => {
    const { editComment, restoreContent, setEditComment, handleOnClickSaveCustomerProjectComment } = useEditMyCustomerProjectCommentLogic({ data, setOpenModal, getData })
    return (
        <Fragment>
            <TextArea className='flex-auto light12 customer_project_comment_component_textarea customer_project_edit_comment_component_textarea' value={editComment} onChange={(value) => setEditComment(value)} />
            <ButtonPrimary className='mr-15 customer_project_edit_comment_button' onClick={() => handleOnClickSaveCustomerProjectComment(editComment, openModal?.allRowData.id)}><TwinTrans transKey='save'>Guardar</TwinTrans></ButtonPrimary>
            <TwinIcon className='mr-15 cursor-pointer hover:text-green-43 cp_comment_component_tab_header_icon' icon={faRotateLeft} onClick={restoreContent} />
            <TwinIcon icon={faTrashCan} className='cursor-pointer hover:text-red-BA cp_comment_component_tab_header_icon' onClick={() => setOpenModal({ type: 'delete', allRowData: { id: data?.id } })} />
        </Fragment>
    )
}

interface EditMyCustomerProjectCommentLogicProps {
    data: CustomerProjectCommentModelTypeExt
    setOpenModal: SetEditDeleteModalTy
    getData: () => Promise<void>
}

const useEditMyCustomerProjectCommentLogic = ({ data, setOpenModal, getData }: EditMyCustomerProjectCommentLogicProps) => {
    const [editComment, setEditComment] = useState<string>(data.message || '')
    const restoreContent = useCallback(() => {
        setEditComment(data.message || '')
        setOpenModal(null)
    }, [data.message, setOpenModal])

    const handleOnClickSaveCustomerProjectComment = useCallback(async (value: string, id: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/customerProjectComment/updateCustomerProjectComment', {
            id,
            message: value
        })
        if (result) {
            getData?.()
            setOpenModal(null)
        }
    }, [getData, setOpenModal])

    return { editComment, setEditComment, restoreContent, handleOnClickSaveCustomerProjectComment }

}


interface CustomerProjectCommentContentProps {
    data: CustomerProjectCommentModelTypeExt
    setOpenModal: SetEditDeleteModalTy
}

const CustomerProjectCommentContent: React.FC<CustomerProjectCommentContentProps> = ({ data, setOpenModal }) => {
    return (
        <Fragment>
            <div className={'light12 mr-auto pr-10 whitespace-pre-wrap'}>
                {data.message}
            </div>
            <TwinIcon className='mr-15 cursor-pointer hover:text-green-43 cp_comment_component_tab_header_icon' icon={faPenToSquare} onClick={() => setOpenModal({ type: 'edit', allRowData: { id: data.id } })} />
            <TwinIcon className='mr-10 cursor-pointer hover:text-red-BA cp_comment_component_tab_header_icon' icon={faTrashCan} onClick={() => setOpenModal({ type: 'delete', allRowData: { id: data.id } })} />
        </Fragment>
    )
}

interface CustomerProjectOtherCommentProps {
    data: CustomerProjectCommentModelTypeExt
}

const CustomerProjectOtherComment: React.FC<CustomerProjectOtherCommentProps> = ({ data }) => {
    if (!data?.message) {
        return null
    }
    return (
        <div className='flex items-center border border-gray-D6 rounded-xl p-10 mt-10'>
            <div className={'light12 mr-auto pr-10 whitespace-pre-wrap '}>
                {data.message}
            </div>
        </div>
    )
}