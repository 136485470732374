import { EmployeeJourneyTy } from '@teinor/erp/types/company/userInner/employee/employeeTimetable';
import { Modify } from '../../../../../../utils/globals/types';

export type EmployeeJourneyTyExtended = Modify<EmployeeJourneyTy, {
    type: 'freeDay' | 'holidayDay' | 'specialDay' | 'normalDay' | 'noWorkDay' | 'totals'
    date: string
    totalMinsWorked?: number
    totalMinsPermittedWorked?: number
    totalMinsToDo?: number
    balanceMinsToDo?: number
    balance?: number
    balancePermitted?: number
}>

export interface EmployeeHolidayInfoTy {
    holidayPendent: number
    holidayDone: number
    holidayTotal: number
}

export const employeTTShowPermittedTimeDefault = {
    0: 'efective_hours',
    1: 'total_hours'
}
export const employeTTShowPermittedTimeDefaultInversed = {
    'efective_hours': 0,
    'total_hours': 1
}
export type EmployeTTShowPermittedTimeType = typeof employeTTShowPermittedTimeDefault
export type EmployeTTShowPermittedTimeKeys = keyof EmployeTTShowPermittedTimeType
export type EmployeTTShowPermittedTimeTypeInversed = typeof employeTTShowPermittedTimeDefaultInversed
export type EmployeTTShowPermittedTimeInversedKeys = keyof EmployeTTShowPermittedTimeTypeInversed