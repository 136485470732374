import './twineditor.sass'
import { EditorState, ContentBlock } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import { useCallback, useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { customToolbar } from './types';
import TwinIcon from '../../baseComponents/TwinIcon';
import { faCode } from '@fortawesome/pro-regular-svg-icons';
import { getEditorStateWithContent, getHTMLFromEditorState } from './functions';
import { ModalBig } from '../../baseComponents/Modal';
import { ButtonPrimary } from '../../baseComponents/Button';
import TwinTrans from '../../baseComponents/TwinTrans';
import { ModalOpenedSetTy, ModalOpenedTy } from '../../baseComponents/Modal/types';
import { ChangeValueFunc } from '../../utils/globals/types';

export interface TwinEditorProps  extends TwinEditorLogicProps {
    name?: string
    className?: string
    label?: string
    parentClassName?: string
    readOnly?: boolean
 }
function entityMapperToComponent(entity: any) {
    if (entity.type === "DIV") {
        return () => (
            <div dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} className='notSelectable'/>
        );
    }
    if (entity.type === "TABLE") {
        return () => (
            <table dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} className='notSelectable'/>
        );
    }
    if (entity.type === "TBODY") {
        return () => (
            <tbody dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} className='notSelectable'/>
        );
    }
    if (entity.type === "TR") {
        return () => (
            <tr dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} className='notSelectable'/>
        );
    }
    if (entity.type === "TH") {
        return () => (
            <th dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} className='notSelectable'/>
        );
    }
    if (entity.type === "TD") {
        return () => (
            <td dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} className='notSelectable'/>
        );
    }
    if (entity.type === "STYLE") {
        return () => <style className='notSelectable'>{entity.data.innerHTML}</style>;
    }

    return "";
}

function customBlockRenderFunc(block: ContentBlock, config: EditorState) {
    if (block.getType() === "atomic") {
        const contentState = config.getCurrentContent();
        const entity: any = contentState.getEntity(block.getEntityAt(0));
        return {
            component: entityMapperToComponent(entity),
            editable: false,
            props: {
                children: () => entity.innerHTML
            }
        };
    }
    return undefined;
}
const TwinEditor: React.FC<TwinEditorProps> = ({ defaultValue, onChange, name, label, className, parentClassName, readOnly }) => {
    const { editorValue, changeValue, showCode, setShowCode, editorHTML, changeValueEditorHMTL } = useTwinEditorLogic({ defaultValue, onChange })
    return (
        <div className={'twin_editor_parent ' + (parentClassName || '') + (readOnly? ' twin_editor_readOnly' : '') }>
            {label && <span className='regular16 mb-15 block'>{label}</span>}
            <Editor
                toolbar={customToolbar}
                editorState={editorValue}
                wrapperClassName="twin_editor"
                editorClassName={className}
                onEditorStateChange={changeValue}
                customBlockRenderFunc={(block) => customBlockRenderFunc(block, editorValue)}
                toolbarCustomButtons={[<DeciderButton show={showCode} setShow={setShowCode} />]}
                readOnly={readOnly}
            />
            <textarea className='hidden'
                name={name}
                value={editorHTML}
                readOnly
            />
            {showCode && <DeciderShowEditorHTML {...{ showCode, setShowCode, editorHTML, changeValueEditorHMTL, name }} />}
        </div>
    )
}

interface TwinEditorLogicProps {
    defaultValue?: string
    onChange?(value: string): void
}

const useTwinEditorLogic = ({ defaultValue, onChange }: TwinEditorLogicProps) => {
    const [editorValue, setEditorValue] = useState<EditorState>(getEditorStateWithContent(defaultValue || ''))
    const [showCode, setShowCode] = useState<ModalOpenedTy>(null)

    const changeValue = useCallback((newValue: EditorState) => {
        setEditorValue(newValue)
        if (onChange) {
            const parsedEditorHTML = getHTMLFromEditorState(newValue)
            onChange?.(parsedEditorHTML)
        }
    }, [onChange])
    
    useEffect(() => {
        if (defaultValue !== undefined) {
            setEditorValue((oldValue) => {
                if (oldValue && getHTMLFromEditorState(oldValue) !== defaultValue) {
                    return getEditorStateWithContent(defaultValue)
                }
                return oldValue
            })
        }
    }, [defaultValue])

    const changeValueEditorHMTL = useCallback((value: string) => {
        setEditorValue(getEditorStateWithContent(value))
    }, [])

    return { editorValue, setEditorValue, showCode, setShowCode, editorHTML: getHTMLFromEditorState(editorValue), changeValue, changeValueEditorHMTL }
}
interface DeciderShowEditorHTMLProps extends DeciderShowEditorHTMLLogicProps {
    showCode: ModalOpenedTy
}

const DeciderShowEditorHTML: React.FC<DeciderShowEditorHTMLProps> = ({ showCode, editorHTML, changeValueEditorHMTL, setShowCode }) => {
    const { valueHTML, handleEditTextArea, handleOnSubmit } = useDeciderShowEditorHTMLLogic({ editorHTML, changeValueEditorHMTL, setShowCode})
    return (
        <ModalBig opened={showCode} setOpened={setShowCode} className='flex flex-col'>
            <h2 className='mb-20'>
                <TwinTrans transKey='editCodeSource'>Editar código fuente</TwinTrans>
            </h2>
            <span><TwinTrans transKey='dontTouchHTML'>Te recomendamos que no modifiques esta opción si no conoces el lenguaje de programación HTML.</TwinTrans></span>
            <textarea className={'show_code_textarea' + (showCode? '' : ' hidden')}
                value={valueHTML}
                onChange={handleEditTextArea}
                />
            <ButtonPrimary onClick={handleOnSubmit} className='mt-30 ml-auto'>
                <TwinTrans transKey='save'>Guardar</TwinTrans>
            </ButtonPrimary>
        </ModalBig>
    )
}

interface DeciderShowEditorHTMLLogicProps {
    editorHTML: string
    changeValueEditorHMTL: ChangeValueFunc
    setShowCode: ModalOpenedSetTy
}

const useDeciderShowEditorHTMLLogic = ({ editorHTML, changeValueEditorHMTL, setShowCode }: DeciderShowEditorHTMLLogicProps) => {
    const [valueHTML, setValueHTML] = useState<string>('')

    const handleEditTextArea = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.currentTarget.value
        setValueHTML(value)
    }, [])

    const handleOnSubmit = useCallback(() => {
        changeValueEditorHMTL(valueHTML)
        setShowCode(null)
    }, [changeValueEditorHMTL, setShowCode, valueHTML])

    useEffect(() => {
        setValueHTML(editorHTML)
    }, [editorHTML])

    return { valueHTML, handleEditTextArea, handleOnSubmit }
}


interface DeciderButtonProps {
    show: ModalOpenedTy
    setShow: ModalOpenedSetTy
}

const DeciderButton: React.FC<DeciderButtonProps> = ({ show, setShow }) => {
    return (
        <div className='twin_editor_code'><TwinIcon onClick={() => setShow(true)} icon={faCode} className={'show_icon ' + (show ? 'show_code_active' : 'show_code')} /></div>
    )
}



export default TwinEditor
