import { Fragment } from 'react'
import { RenderBalanceHours, RenderDateAndWeekDay, RenderEntryOutWork, RenderEntryOutWorkPermitted, RenderHoursToWork, RenderIconBalanceHours, RenderIconBalanceHoursPermitted, RenderWorkedHours, RenderWorkingTime } from './Subcomponents'
import { timeControlEmployeeMonthRowRender } from './functions'
import { EmployeeJourneyTyExtended, EmployeTTShowPermittedTimeInversedKeys } from '../../../app/erp/screens/Staff/WorkingCalendarParent/TimeControlEmployeeMonth/types'
import { TableComponentProps } from '../../../baseComponents/TwinTable/BaseScreenTable/types'
import { VirtualTableListingStateLess } from '../../../baseComponents/TwinTable/VirtualTableListing'
import { ColumnTableSchema } from '../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'

type TableControlMonthWorkProps = TableComponentProps & {
    data: EmployeeJourneyTyExtended[] | null
    showPermittedTime?: EmployeTTShowPermittedTimeInversedKeys
}

const TableControlMonthWork: React.FC<TableControlMonthWorkProps> = ({ data, showPermittedTime }) => {
    const { t } = useTwinTranslation()

    const permittedTime = showPermittedTime === 'efective_hours' ? true : false
    const columns: ColumnTableSchema<EmployeeJourneyTyExtended> = [
        {
            id: 'day',
            dataKey: 'date',
            label: t('day', 'Día'),
            customRender: (parameterValue) => <RenderDateAndWeekDay date={parameterValue} />
        },
        {
            id: 'workingTime',
            dataKey: 'journey',
            label: t('workingTime', 'Horario'),
            customRender: (parameterValue, allRowData) => <RenderWorkingTime {...{ allRowData }} />,
            minWidth: 300,
        },
    ]
    if(permittedTime){
        columns.push(...[
            {
                id: 'workingTime_permitted_start',
                dataKey: 'journey',
                label: t('entry', 'Entrada'),
                customRender: (parameterValue, allRowData) => <RenderEntryOutWorkPermitted entry={true} {...{ allRowData }} /> 
            },
            {
                id: 'workingTime_permitted_exit',
                dataKey: 'journey',
                label: t('exit', 'Salida'),
                customRender: (parameterValue, allRowData) => <RenderEntryOutWorkPermitted entry={false} {...{ allRowData }} />
            },
            {
                id: 'workedHours_permitted',
                dataKey: 'timetables',
                label: t('workedHours', 'Horas trabajadas'),
                customRender: (parameterValue, allRowData) => <RenderWorkedHours  {...{ allRowData, permittedTime }} />,
            },
            {
                id: 'hoursToWork',
                dataKey: 'journey',
                label: t('hoursToDo', 'Horas a hacer'),
                customRender: (parameterValue, allRowData) => <RenderHoursToWork {...{ allRowData }} />
            },
            {
                id: 'balance_permitted',
                dataKey: 'timetables',
                label: t('balance', 'Balance'),
                headerClassName: 'flex justify-center items-center',
                className: 'flex justify-center items-center',
                customRender: (parameterValue, allRowData) => <RenderBalanceHours  {...{ allRowData, permittedTime }} />
            },
            {
                id: 'icons_permitted',
                dataKey: 'timetables',
                label: '',
                customRender: (parameterValue, allRowData) => <RenderIconBalanceHoursPermitted {...{ allRowData }} />,
                minWidth: 250,
            },
        ] as ColumnTableSchema<EmployeeJourneyTyExtended>)
    }else{
        columns.push(...[
            {
                id: 'workingTime_start',
                dataKey: 'journey',
                label: t('entry', 'Entrada'),
                customRender: (parameterValue, allRowData) => <RenderEntryOutWork entry={true} {...{ allRowData }} />
            },
            {
                id: 'workingTime_exit',
                dataKey: 'journey',
                label: t('exit', 'Salida'),
                customRender: (parameterValue, allRowData) => <RenderEntryOutWork entry={false} {...{ allRowData }} />
            },
            {
                id: 'workedHours',
                dataKey: 'timetables',
                label: t('workedHours', 'Horas trabajadas'),
                customRender: (parameterValue, allRowData) => <RenderWorkedHours  {...{ allRowData, permittedTime }} />,
            },
            {
                id: 'hoursToWork',
                dataKey: 'journey',
                label: t('hoursToDo', 'Horas a hacer'),
                customRender: (parameterValue, allRowData) => <RenderHoursToWork {...{ allRowData }} />
            },
            {
                id: 'balance',
                dataKey: 'timetables',
                label: t('balance', 'Balance'),
                headerClassName: 'flex justify-center items-center',
                className: 'flex justify-center items-center',
                customRender: (parameterValue, allRowData) => <RenderBalanceHours  {...{ allRowData, permittedTime }} />
            },
            {
                id: 'icons',
                dataKey: 'timetables',
                label: '',
                customRender: (parameterValue, allRowData) => <RenderIconBalanceHours {...{ allRowData }} />,
                minWidth: 250,
            },
        ] as ColumnTableSchema<EmployeeJourneyTyExtended>)
    }
    return (
        <Fragment>
            <div className={'flex flex-col flex-auto h-1'}>
                <div className='flex-auto'>
                    <VirtualTableListingStateLess {...{ columns: columns }} setSelectedColumnsAndFilters={() => { }} tableData={data || []} headerHeight={48} name='monthWorkDays' rowRenderer={timeControlEmployeeMonthRowRender} />
                </div>
            </div>
        </Fragment>
    )
}

export default TableControlMonthWork