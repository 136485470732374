import { faCheck, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { AllReduxAppPayloads, GlobalContext } from '../../../utils/reducers'
import TwinIcon, { TwinIconProp } from '../../TwinIcon'
import {checkIfAnimated} from '../../Modal/functions'
import { TwinDictionary } from '../../../utils/globals/dictionary'

interface ModalPopupMessageProps extends ModalPopupMessageLogicProps {}

const ModalPopupMessage: React.FC<ModalPopupMessageProps> = ({...rest}) => {
    const { display, animatedClass } = useModalPopupMessageLogic({ ...rest })
    if (!display || !rest.popupMessage?.type) {
        return null
    }
    return (
        <PopUpMessage type={rest.popupMessage?.type} message={display} animatedClass={animatedClass} />
    )
}

interface ModalPopupMessageLogicProps extends ReduxPopupMessageInformationConnector{}

const useModalPopupMessageLogic = ({ resetPopupMessage, popupMessage }: ModalPopupMessageLogicProps) => {
    const { t } = useTwinTranslation()
    const myMessageTranslator: TwinDictionary = {
        '__SOCKET_DISCONNECT': t('disconnectedFromRealTime', 'Te has desconectado del tiempo real, por favor, refresca la página para que todo funcione adecuadamente.')
    }
    const [animated, setAnimated] = useState(false)
    const havePopupMessage = popupMessage !== null
    const isPermanentPopup = popupMessage?.permanent
    const displayErr = popupMessage?.value !== true ? (myMessageTranslator[popupMessage?.value || ''] ||popupMessage?.value) : t('errorWhenProcessing', 'Error al procesar la información')
    useEffect(() => {
        if (havePopupMessage) {
            setTimeout(() => {
                setAnimated(true)
            }, 10)
            if (!isPermanentPopup) {
                setTimeout(() => {
                    resetPopupMessage()
                }, 3000)
            }
        }
        return () => {
            if (havePopupMessage) {
                setAnimated(false)
            }
        }
    }, [havePopupMessage, resetPopupMessage, isPermanentPopup])
    return {display: havePopupMessage ? displayErr: false, animatedClass: checkIfAnimated(animated)}
}

const popupmessageInformationDispatch = {
    resetPopupMessage: () => ({ type: 'RESET_POPUP_MSG' }),
}
const mapPopupMessageInformationConnector = (state: AllReduxAppPayloads) => ({ popupMessage: state.popupMessage })
const popupmessageInformationConnector = connect(mapPopupMessageInformationConnector, popupmessageInformationDispatch, null, { context: GlobalContext })
type ReduxPopupMessageInformationConnector = ConnectedProps<typeof popupmessageInformationConnector>


interface PopUpMessageProps {
    type: 'error' | 'success'
    message: string | boolean
    animatedClass: string
}

export const PopUpMessage: React.FC<PopUpMessageProps> = ({ type, message, animatedClass }) => {
    const data: Record<PopUpMessageProps['type'], { className: string, icon: TwinIconProp }> = {
        error: {
            className: 'bg-red-BA',
            icon: faExclamationTriangle
        },
        success: {
            className: 'bg-green-43',
            icon: faCheck
        }
    }
    return (
        <div className={'opacity_trigger fixed right-5 top-5  text-white py-20 px-20 flex items-center w-240 z-50 rounded-8 ' + animatedClass + ' ' + data[type].className}>
            <TwinIcon icon={data[type].icon} style={{ width: '28px', height: '25px' }} className='mr-20' />
            <span>{message}</span>
        </div>
    )
}

export default popupmessageInformationConnector(ModalPopupMessage)