import { useCallback, useState } from 'react'
import { flushSync } from 'react-dom'
import { ConnectedProps, connect } from 'react-redux'
import { useParams } from 'react-router'
import { ModalOpenedSetTy } from '../../../../../../../../../../baseComponents/Modal/types'
import { ModalCreateEditMediumCustomLoading } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import TwinCalendarStartEnd from '../../../../../../../../../../baseComponents/TwinCalendar/TwinCalendarStartEnd'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { OnSubmit } from '../../../../../../../../../../forms/TwinForm/types'
import ScheduleAbsence from '../../../../../../../../../../specificComponents/WCal/schedule/ScheduleAbsence'
import { AbsenceScheduleErrorsTy } from '../../../../../../../../../../specificComponents/WCal/schedule/ScheduleAbsence/types'
import twinFetchPost from '../../../../../../../../../../utils/globals/data'
import { TwinDictionary } from '../../../../../../../../../../utils/globals/dictionary'
import { checkSchedulesAllOk } from '../../../../../../../../../../utils/globals/schedule'
import withLoading from '../../../../../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../../../../../utils/reducers'
import { EmployeeAbsenceTypePayload } from '../../../../../../../../../../utils/reducers/company/employeeAbsenceTypes'
import { changeErrorMessage } from '../../../../../../../../../../utils/reducers/reduxDispatch'
import { EmployeeAbsenceModelTypeExtended } from './types'
import useIsLoading from '../../../../../../../../../../utils/hooks/useIsLoading'


type ModalCreateEmployeeAbsenceProps = ModalEditComponentProps & ModalCreateEmployeeAbsenceLogicProps & ReduxEmployeeAbsenceTypes & {
}

const ModalCreateEmployeeAbsence: React.FC<ModalCreateEmployeeAbsenceProps> = ({ allRowData, setOpened, onSubmit, employeeAbsenceTypes}) => {
    const { t } = useTwinTranslation()
    const { id } = useParams()

    const { handleOnChange, dateSelected, checkAllOk, schedule, setSchedule, loading, endLoading, startLoading } = useModalCreateEmployeeAbsenceLogic({ setOpened, onSubmit})
    const fields: FormRender<EmployeeAbsenceModelTypeExtended> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'EmployeeAbsenceTypeId',
                    component: 'CustomSelect',
                    label: t('absenceType', 'Tipo de ausencia'),
                    items: employeeAbsenceTypes||{},
                    required: true
                },
                {
                    name: 'description',
                    component: 'TextArea',
                    label: t('description', 'Descripción'),
                },
                {
                    name: 'proof_file_url',
                    component: 'FilesInput',
                    label: t('uploadProof', 'Subir justificante'),
                },
                {
                    name: 'EmployeeId',
                    component: 'InputHidden',
                    value: id
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: allRowData?.id
                },
            ]
        }
    ]
    return (
        <ModalCreateEditMediumCustomLoading url='' onSubmit={() => { }} beforeSubmit={(vals) => { checkAllOk(vals); return false }} translations={{
            title: t('createEmployeeAbsence', 'Crear ausencia'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} imagePath={['tmp']} className='notFlexAutoFormRender' {...{ startLoading, endLoading, loading, fields, setOpened}}>
            <TwinCalendarStartEnd start={dateSelected.start} end={dateSelected.end} onChange={handleOnChange} />
            <ScheduleAbsence {...{ schedule, setSchedule }} />
        </ModalCreateEditMediumCustomLoading>
    )
}


interface ModalCreateEmployeeAbsenceLogicProps {
    setOpened: ModalOpenedSetTy
    onSubmit: OnSubmit
}
const useModalCreateEmployeeAbsenceLogic = ({ setOpened, onSubmit }: ModalCreateEmployeeAbsenceLogicProps) => {
    const currentDate = new Date()
    const [schedule, setSchedule] = useState<AbsenceScheduleErrorsTy>({ workingTime: [], errors: [] })
    const [dateSelected, setDateSelected] = useState({ start: currentDate, end: currentDate })
    const { startLoading, endLoading, loading } = useIsLoading()
    const { t } = useTwinTranslation()
    
    const errorHours = t('conflictInHoursSelectedCheckRedFields', 'Hay un conflicto en las horas seleccionadas. Revisa los campos en rojo.')

    const messages = JSON.stringify({
        'The day picked must be a working day': t('mustBeWorkingDay', 'La fecha seleccionada debe ser un día laborable'),
        'The day picked is not available': t('datePickedNotAvailable', 'La fecha seleccionada no está disponible'),
    })
    const handleOnChange = useCallback((value: Date, type: 'start' | 'end') => {
        setDateSelected((old) => {
            return { ...old, [type]: value }
        })
    }, [setDateSelected])


    const checkAllOk = useCallback(async (values: TwinDictionary) => {
        startLoading()
        await flushSync(async () => {
            let hasError = false
            values['dateStart'] = dateSelected.start.toDateString()
            values['dateEnd'] = dateSelected.end.toDateString()
            let checkedSchedule: AbsenceScheduleErrorsTy | false = false
            await setSchedule((old) => {
                const copyOld = JSON.parse(JSON.stringify(old))
                const myDaySchedule = copyOld
                myDaySchedule.errors = []
                const response = checkSchedulesAllOk(myDaySchedule)
                if (response.hasError) {
                    hasError = true
                }
                if (hasError) {
                    changeErrorMessage(errorHours)
                }
                if (!hasError) {
                    checkedSchedule = { ...copyOld }
                }
                return { ...copyOld }
            })
            if (hasError) {
                changeErrorMessage(errorHours)
                endLoading()
                return false
            }
            if (checkedSchedule) {
                if (schedule.workingTime && schedule.workingTime.length){
                    values['schedule'] = JSON.stringify(schedule?.workingTime)
                }
                const response = await twinFetchPost('/api/app/workingCalendar/employeeAbsence/createEmployeeAbsence', values)
                if (response.status === 200) {
                    setOpened(null)
                    onSubmit?.(response, values)
                } else {
                    const messagesParsed = JSON.parse(messages)
                    const responseJSON = await response.json()
                    if (responseJSON.errors[0].msg && messagesParsed[responseJSON.errors[0].msg]) {
                        changeErrorMessage(messagesParsed[responseJSON.errors[0].msg])
                    }
                }
            }
        })
        endLoading()
    }, [errorHours, setSchedule, setOpened, messages, dateSelected.start, dateSelected.end, onSubmit, schedule.workingTime, endLoading, startLoading])
    
    return { handleOnChange, dateSelected, checkAllOk, schedule, setSchedule, loading, endLoading, startLoading }
}


const employeeAbsenceTypesDispatch = {
    setEmployeeAbsenceTypes: (payload: EmployeeAbsenceTypePayload) => ({ type: 'CHANGE_EMPLOYEEABSENCETYPE', payload }),
}

export type ReduxEmployeeAbsenceTypes = ConnectedProps<typeof employeeAbsenceTypesConnect>
const mapEmployeeAbsenceTypesConnector = (state: AllReduxPayloads) => ({ employeeAbsenceTypes: state.employeeAbsenceTypes })
const employeeAbsenceTypesConnect = connect(mapEmployeeAbsenceTypesConnector, employeeAbsenceTypesDispatch)
const employeeAbsenceTypesConnectLoading = withLoading(ModalCreateEmployeeAbsence, [{ fetchUrl: '/api/app/workingCalendar/employeeAbsence/getAllEmployeeAbsenceTypes', propName: 'employeeAbsenceTypes', setFunctionName: 'setEmployeeAbsenceTypes' }])
const ModalCreateEmployeeAbsenceConnect = employeeAbsenceTypesConnect(employeeAbsenceTypesConnectLoading)

export default ModalCreateEmployeeAbsenceConnect