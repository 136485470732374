import { EmployeeTimetableModelType } from '@teinor/erp/types/company/userInner/employee/employeeTimetable';
import { TableRowProps } from 'react-virtualized';
import { defaultRowRenderer } from 'react-virtualized/dist/es/Table';
import { EmployeeJourneyTyExtended } from '../../../app/erp/screens/Staff/WorkingCalendarParent/TimeControlEmployeeMonth/types';
import { parseStringDateHoursToMins } from '../../../utils/globals/date';
import { EmployeeJourneyByYearResult } from './TableControlYearWork/types';

export const timeControlEmployeeMonthRowRender = (props: TableRowProps) => {
    let className = props.className
    const type = props?.rowData?.type
    const isNotFreeDay = type === 'normalDay' || (type === 'specialDay' && props.rowData?.journey.workingTime?.length) || type === 'absenceDay' || type === 'absenceJustifiedDay'
    if (props?.rowData?.type === 'totals') {
        className += ' bg-gray-F7 total_month_row flex items-center regular24'
    } else if (!isNotFreeDay){
        className += ' bg-gray-F7'
    }
    return defaultRowRenderer({ ...props, className })
}
export const timeControlEmployeeMonthYearRowRender = (props: TableRowProps) => {
    let className = props.className
    if (props?.rowData?.type === 'totals') {
        className += ' bg-gray-F7 total_year_row flex items-center regular24'
    }
    return defaultRowRenderer({ ...props, className })
}

export const getTotalMonthData = (monthData: EmployeeJourneyTyExtended[]) => {
    const now = new Date()
    let totalMinsToDo = 0
    let balanceMinsToDo = 0
    let balancePermittedMinsToDo = 0
    let totalMinsWorked = 0
    let totalMinsPermittedWorked = 0

    for (const day of monthData) {
        let totalDay = 0
        if (day?.journey?.workingTime) {
            for (const hours of day?.journey.workingTime) {
                totalDay += hours[1] - hours[0]
            }
            if (day.dayMins && day.journey.flexible && totalDay > day.dayMins){
                totalMinsToDo += day.dayMins
                day.totalMinsToDo = day.dayMins
            }else {
                totalMinsToDo += totalDay
                day.totalMinsToDo = totalDay
            }
            const totalMinsWorkedToday = day.totalDayTime || 0
            const totalMinsPermittedWorkedToday = day.totalTimePermitted || 0
            day.totalMinsWorked = totalMinsWorkedToday
            day.balance = totalMinsWorkedToday - (day.totalMinsToDo || totalDay)
            day.balancePermitted = totalMinsPermittedWorkedToday - (day.totalMinsToDo || totalDay)
            if (now > new Date(day.date)) {
                balanceMinsToDo += day.balance
                balancePermittedMinsToDo += day.balancePermitted
            }
            totalMinsWorked += totalMinsWorkedToday
            totalMinsPermittedWorked += totalMinsPermittedWorkedToday
        }
    }
    return {
        totalMinsToDo, balanceMinsToDo, totalMinsWorked, totalMinsPermittedWorked, balancePermittedMinsToDo
    }
}

export const getTotalYearData = (yearData: EmployeeJourneyByYearResult[]) => {
    let totalMinsToDo = 0
    let balanceMinsToDo = 0
    let balancePermittedMinsToDo = 0
    let totalMinsWorked = 0
    let totalMinsPermittedWorked = 0
    for (const month of yearData) {
        totalMinsWorked += month.totalMinsWorked
        totalMinsToDo += month.totalMinsToDo
        totalMinsPermittedWorked += month.totalMinsPermittedWorked
        balanceMinsToDo += month.balanceMinsToDo
        balancePermittedMinsToDo += month.balancePermittedMinsToDo
    }
    return {
        totalMinsToDo, balanceMinsToDo, totalMinsWorked, totalMinsPermittedWorked, balancePermittedMinsToDo
    }
}
export const parseStartAndEndTime = (timetable: EmployeeTimetableModelType) => {
    let startMin = 0
    let endMin = 0
    startMin = parseInt(parseStringDateHoursToMins(timetable.edited_start_time || timetable.og_start_time))
    if (timetable.edited_end_time || timetable.og_end_time) {
        endMin = parseInt(parseStringDateHoursToMins(timetable.edited_end_time || timetable.og_end_time || '0'))
    }
    return { startMin, endMin }
}