import { useLocation } from 'react-router-dom'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import { TwinTableLink } from '../../../../../../baseComponents/TwinTable'
import { BaseScreenTableSimple } from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { CustomRenderString } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'

interface CustomerProjectStatusesFlowProps extends ComponentWithPermissions { }

const CustomerProjectTypesStatusesFlow: React.FC<CustomerProjectStatusesFlowProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('projectTypes', 'Tipos de proyecto'),
    }

    return (
        <BaseScreenTableSimple TableComponent={TableCustomerProjectTypesStatusFlow} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableCustomerProjectTypesStatusFlowProps extends TableComponentProps { }

const TableCustomerProjectTypesStatusFlow: React.FC<TableCustomerProjectTypesStatusFlowProps> = ({setTableInstance}) => {
    const location = useLocation().pathname
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<CustomerProjectModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },        
        },
        {
            id: 'description',
            dataKey: 'description',
            label: t('description', 'Descripción'),
        },
        {
            id: 'CustomerProjectType',
            dataKey: 'CustomerProjectTypeId',
            label: t('projectType', 'Tipo de proyecto'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerProjectType?.name} />,
            sortable: true,
        }
    ]
    return (
        <TwinTableLink name='customerProjectStatusesFlow' createRowDataLink={[location, ['$id', 'main']]} columns={columns} getDataFrom='/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypes' setTableInstance={setTableInstance} />
    )
}

export default CustomerProjectTypesStatusesFlow