import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { SmallWhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import { InputWithLabelIcon } from '../../../../../../forms/Input'
import { useCallback, useMemo, useState } from 'react'
import { CustomSelectDropDown, useDropDownRowLogic } from '../../../../../../forms/CustomSelect/DropDown'
import { CustomSelectDropDownBaseProps, CustomSelectDropDownRowProps } from '../../../../../../forms/CustomSelect/DropDown/types'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../utils/globals/dictionary'
import { getTaskCFields } from '../../../../../../utils/reducers/getters'
import { ModalOpenedSetTy } from '../../../../../../baseComponents/Modal/types'
import { UpdateTaskTy } from '../../../types'

interface ModalTaskTransformSubTaskProps extends ModalTaskTransformSubTaskLogicProps {}

const ModalTaskTransformSubTask: React.FC<ModalTaskTransformSubTaskProps> = ({ ...logic }) => {
    const { transformInSubtask, handleSearchValue, searchValue, tasks } = useModalTaskTransformSubTaskLogic({ ...logic })
    return (
        <SmallWhiteBox className='transform_subtask_modal'>
            <InputWithLabelIcon label='' value={searchValue} onChange={handleSearchValue} icon={faMagnifyingGlass} />
            {tasks ? <CustomSelectDropDownTasks items={tasks || {}} onChange={transformInSubtask} value={''} /> : null}
        </SmallWhiteBox>
    )
}

interface ModalTaskTransformSubTaskLogicProps {
    setOpened: ModalOpenedSetTy
    updateTask: UpdateTaskTy
}

const useModalTaskTransformSubTaskLogic = ({setOpened, updateTask}: ModalTaskTransformSubTaskLogicProps) => {
    const [searchValue, setSearchValue] = useState<string>('')
    const [tasks, setTasks] = useState<TwinDictionary | null>(null)
    const taskCFields = getTaskCFields()

    const customFieldIds = useMemo(() => {
        const arrTaskCFields = []
        for (const key in taskCFields) {
            arrTaskCFields.push(key)
        }
        return arrTaskCFields
    }, [taskCFields])

    const getAllTasks = useCallback(async (name: string) => {
        const result = await twinFetchPostJSON('/api/app/task/getAllTasks', {
            whereToParse:
            {
                'generalSearchBar': name,
                'deleted': false
            },
            customFieldIds,
            limit: 20,
            orderToParse: [['id', 'desc']]
        })
        if (result) {
            setTasks(dictionaryComplexFromJsonArr(result))
        }
    }, [customFieldIds])

    const handleSearchValue = useCallback((value: string) => {
        setSearchValue(value)
        if (value?.length > 0) {
            getAllTasks(value)
        } else {
            setTasks(null)
        }
    }, [setSearchValue, getAllTasks, setTasks])


    const closeAndResetSearchValue = useCallback(() => {
        setOpened(null)
        setTasks(null)
        setSearchValue('')
    }, [setOpened, setTasks, setSearchValue])

    const transformInSubtask = useCallback(async (value: number) => {
        await updateTask('TaskId', value)
        closeAndResetSearchValue()
    }, [updateTask, closeAndResetSearchValue])

    return { transformInSubtask, handleSearchValue, searchValue, tasks }
}

interface CustomSelectDropDownTasksProps extends CustomSelectDropDownBaseProps {
}

const CustomSelectDropDownTasks: React.FC<CustomSelectDropDownTasksProps> = ({ items, children, ...rest }) => {
    const keys = useMemo(() => Object.keys(items), [items])
    return (<CustomSelectDropDown items={keys} {...rest} rowRenderer={({ ...rowRenderProps }) => {
        return (
            <CustomSelectDropDownTasksRow currentvalue={rest.value} onChange={rest.onChange} item={{ value: keys[rowRenderProps.index], text: items[keys[rowRenderProps.index]]?.name }}  {...rowRenderProps} />
        )
    }}>{children}</CustomSelectDropDown>)
}

interface CustomSelectDropDownTasksRowProps extends CustomSelectDropDownRowProps { }
const CustomSelectDropDownTasksRow: React.FC<CustomSelectDropDownTasksRowProps> = ({ className, onChange, ...rest }) => {
    const { changeValue, text, value } = useDropDownRowLogic({ onChange, ...rest })
    return (
        <div className={'custom_select_value cursor-pointer flex justify-between items-center bg-white regular12 text-gray-51 hover:bg-gray-F7 px-15 z-20 ' + (className || '')} onClick={changeValue} {...rest} {...value} title={text as any}>
            <span className='text-green-43 mr-5'>#{rest.item.value}</span><span className='w-full twin_elipsis'>{text}</span>
        </div>
    )
}

export default ModalTaskTransformSubTask