import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { InputWithRightIcon } from '../../forms/Input'
import { ChangeValueFunc } from '../../utils/globals/types'
import Checkbox from '../../forms/Checkbox'
import { useDebounce } from 'use-debounce'
import { useEffect, useState } from 'react'

interface SearchBarProps {
    onChange: ChangeValueFunc
    searchValue: string
    className?: string
    placeholder?: string
    onFocus?: () => void
}

const SearchBar: React.FC<SearchBarProps> = ({ searchValue, className, ...rest }) => {
    return (
        <div className={' light14 searchbar ' + (className || '')} onClick={(e) => e.stopPropagation()}>
            <InputWithRightIcon className='placeholder-gray-97 w-full ' iconStyles={{ height: '16px', width: '16px', color: '#636363' }} icon={faSearch} value={searchValue} {...rest} />
        </div>
    )
}
interface SearchBarWithDebounceProps extends SearchBarWithDebounceLogicProps {
    className?: string
    placeholder?: string
    onFocus?: () => void
}

export const SearchBarWithDebounce: React.FC<SearchBarWithDebounceProps> = ({ className, searchValue, onChange,...rest }) => {
    const { mySearchValue, setMySearchValue } = useSearchBarWithDebounceLogic({searchValue, onChange})
    return (
        <div className={' light14 searchbar ' + (className || '')} onClick={(e) => e.stopPropagation()}>
            <InputWithRightIcon className='placeholder-gray-97 w-full ' iconStyles={{ height: '16px', width: '16px', color: '#636363' }} icon={faSearch} value={mySearchValue} onChange={setMySearchValue} {...rest} />
        </div>
    )
}

interface SearchBarWithDebounceLogicProps {
    onChange: ChangeValueFunc
    searchValue: string
}

const useSearchBarWithDebounceLogic = ({ searchValue, onChange }: SearchBarWithDebounceLogicProps) => {
    const [mySearchValue, setMySearchValue] = useState<string>(searchValue)
    const [debounceValue] = useDebounce(mySearchValue, 150)

    useEffect(() => {
        setMySearchValue(searchValue)
    }, [searchValue])

    useEffect(() => {
        onChange(debounceValue)
    }, [debounceValue, onChange])
    return { mySearchValue, setMySearchValue }
}


interface SearchBarCheckBoxProps extends SearchBarProps {
    checked: boolean
    onChangeCheckbox?: (value: boolean) => void
    label?: string
}

export const SearchBarCheckBox: React.FC<SearchBarCheckBoxProps> = ({ searchValue, className, label, onChangeCheckbox, checked, ...rest }) => {
    return (
        <div className={' light14 searchbar flex border-b-green-21 ' + (className || '')} onClick={(e) => e.stopPropagation()}>
            <Checkbox className='ml-21' label={label || ''} onChange={onChangeCheckbox} {...{ checked }} />
            <InputWithRightIcon className='placeholder-gray-97 w-full ' iconStyles={{ height: '16px', width: '16px', color: '#636363' }} icon={faSearch} value={searchValue} {...rest} />
        </div>
    )
}


export default SearchBar