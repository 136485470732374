import { EmployeeTimetableModelType } from '@teinor/erp/types/company/userInner/employee/employeeTimetable'
import { TwinBigCalendarEvent } from '../../../../../../baseComponents/TwinBigCalendar/types'
import { DictEmployeeJourneyTy, UserTimeControlEvent } from './types'
import moment from 'moment-timezone'
import { displayFormatedHourFromMins } from '../../../../../../utils/globals/date'

export const getStartOfWeek = (dateStr: string) => {
    const newDate = new Date(dateStr)
    const startDay = newDate.getDate() - newDate.getDay() + (newDate.getDay() === 0 ? -6 : 1)
    newDate.setDate(startDay)
    return newDate
}
export const getEndOfWeek = (dateStr: string) => {
    const newDate = new Date(dateStr)
    const lastday = newDate.getDate() - (newDate.getDay() - 1) + 6
    newDate.setDate(lastday)
    return newDate
}
interface StartEndWorkDayMsgTy {
    startMessage: string
    endMessage: string
}

const parseAndAddTimetable = (calendarEvents: TwinBigCalendarEvent[], timetables: EmployeeTimetableModelType[], minHour: number, startEndWorkDayMsg: StartEndWorkDayMsgTy, timeZone: string ) => {
    const { startMessage, endMessage } = startEndWorkDayMsg
    for (const timetable of timetables) {
        const hour = parseFloat(timetable.edited_start_time ? timetable.edited_start_time.split(' ')[1] : timetable.og_start_time.split(' ')[1] )
        if (hour < minHour) {
            minHour = hour
        }
        let parsedEnd = new Date(moment().tz(timeZone || 'Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')) 
        if (timetable?.og_end_time){
            parsedEnd = new Date(timetable?.og_end_time)
        } 
        if (timetable?.edited_end_time){
            parsedEnd = new Date(timetable?.edited_end_time)
        } 
        const startDate = new Date(timetable.edited_start_time ? timetable.edited_start_time : timetable.og_start_time)
        const color = (timetable.edited_start_time || timetable.edited_end_time) ? '#218679' : undefined
        calendarEvents.push({
            title: `${startMessage} ${startDate.toLocaleTimeString()}\n${endMessage} ${parsedEnd ? parsedEnd.toLocaleTimeString() : ''} `,
            start: startDate,
            end: parsedEnd,
            allDay: false,
            color
        })
    }
    return minHour
}


export const parseWorkingTimeTimetable = (calendarData: DictEmployeeJourneyTy | null, startEndWorkDayMsg: StartEndWorkDayMsgTy) => {
    let minHour = 8
    let totalWeekTime = 0
    const calendarEvents: UserTimeControlEvent[] = []
    if (calendarData) {
        for (const day in calendarData) {
            const workDay = calendarData[day]
            const totalDay = workDay?.journey ? (workDay?.totalDayTime || 0) : 0
            if (workDay?.journey) {
                if (workDay.journey.workingTime) {
                    const timeTable: string[] = []
                    for (const element of workDay.journey.workingTime) {
                        timeTable.push(displayFormatedHourFromMins(element[0]) + ' - ' + displayFormatedHourFromMins(element[1]))
                    }
                    calendarEvents.push({
                        title: timeTable.join(" / "),
                        start: new Date(day),
                        end: new Date(day),
                        allDay: true,
                        subtitle: String(workDay.totalDayTime || '0'),
                        flexible: workDay.journey.flexible,
                        type: workDay.type
                    })
                }
            }
            if(workDay.totalDayTime && totalDay > 0) {
                totalWeekTime +=workDay.totalDayTime
            }
            if (workDay.type !== 'normalDay' && workDay.type !== 'specialDay' && workDay.type !== 'absenceDay' && workDay.type !== 'absenceJustifiedDay') {
                calendarEvents.push({
                    title: workDay.type,
                    start: new Date(day),
                    end: new Date(day),
                    allDay: true,
                    type: workDay.type
                })
            }
            if (workDay.timetables && totalDay > 0) {
                minHour = parseAndAddTimetable(calendarEvents, workDay.timetables, minHour, startEndWorkDayMsg, workDay?.timeZone || 'Europe/Madrid')
            }
        }
    }
    return { calendarEvents, minHour, totalWeekTime }
}