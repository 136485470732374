import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faPlus } from '@fortawesome/pro-light-svg-icons'
import { WCalHolidayUsedDayModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag/WCalHolidayUsedDay'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../baseComponents/Button'
import { ComponentWithPermissions } from '../../../../../../../baseComponents/CreateRoutering/types'
import TitleAndWhiteBoxLayout from '../../../../../../../baseComponents/Layout/TitleAndWhiteBoxLayout'
import LoadingSpinner from '../../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import PermissionChecker from '../../../../../../../baseComponents/PermissionChecker'
import RangeView from '../../../../../../../baseComponents/TwinBigCalendar/RangeView'
import { RangeViewCalendarEvents } from '../../../../../../../baseComponents/TwinBigCalendar/RangeView/types'
import TwinCalendar from '../../../../../../../baseComponents/TwinCalendar'
import TwinIcon from '../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../utils/globals/date'
import useDictMonths from '../../../../../../../utils/hooks/useDictMonths'
import useEditDeleteModal from '../../../../../../../utils/hooks/useEditDeleteModal'
import useOnClickOutside from '../../../../../../../utils/hooks/useOnClickOut'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateWCHolidayRequest from '../ModalCreateWCHolidayRequest'
import { getEmployeeParams, getEmployees } from '../../../../../../../utils/reducers/getters'
import { TwinDictionary, dictionaryFromJsonArr } from '../../../../../../../utils/globals/dictionary'
import { ModalOpenedTy } from '../../../../../../../baseComponents/Modal/types'
import VerticalSortList from '../../../../../../../baseComponents/Sorts/VerticalSortList'
import { ModalMedium } from '../../../../../../../baseComponents/Modal'
import LoaderDecider from '../../../../../../../baseComponents/LoaderDecider'
import { VerticalSortItem } from '../../../../../../../baseComponents/Sorts/VerticalSortList/types'
import { changeEmployeeParams } from '../../../../../../../utils/reducers/reduxDispatch'

interface WCHolidayRequestRangeViewProps extends ComponentWithPermissions {
    setView: React.Dispatch<React.SetStateAction<'default' | 'bars_view' | 'calendar_view'>>
}

const WCHolidayRequestRangeView: React.FC<WCHolidayRequestRangeViewProps> = ({ setView, userPermissions }) => {
    const {t} = useTwinTranslation()
    const { date, setDate, setOpenModal, openModal, getData, futureDate, data, employeesOrdered, getEmployeesOrder } = useWCHolidayRequestRangeViewLogic()
    return (
        <TitleAndWhiteBoxLayout title={t('holidayListing', 'Listado de vacaciones')} RightHeader={
            <div className='flex items-center'>
                <ButtonSecondary className='mr-5' onClick={() => setView('calendar_view')}><TwinTrans transKey='changeView'>Cambiar vista</TwinTrans></ButtonSecondary>
                <PermissionChecker userPermissions={userPermissions} permission='create'>
                    <ButtonPrimary onClick={() => setOpenModal({ type: 'create', allRowData: {} })}>
                        <TwinIcon icon={faPlus} className='mr-8' />
                        <TwinTrans transKey='newHolidayRequest'>Nueva solicitud de vacaciones</TwinTrans>
                    </ButtonPrimary>
                </PermissionChecker>
            </div>
        }>
            <WCControlFreeDaysDateSelector date={date} setDate={setDate} employees={employeesOrdered} getEmployeesOrder={getEmployeesOrder}/>
            {data ? <RangeView dateStart={date} dateEnd={futureDate} events={data} leftColumnData={employeesOrdered} skipRowsWithoutData={true} /> : <LoadingSpinner />}
            {openModal?.type === 'create' && <ModalCreateWCHolidayRequest {...{ setOpened: () => setOpenModal(null) }} onSubmit={getData} />}
        </TitleAndWhiteBoxLayout >

    )
}

const useWCHolidayRequestRangeViewLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()
    const [data, setData] = useState<RangeViewCalendarEvents | null>(null)
    const [date, setDate] = useState(() => {
        const myDate = new Date()
        return new Date(myDate.getFullYear(), myDate.getMonth(), 1)
    })
    const [employeesOrdered, setEmployeesOrdered] = useState<TwinDictionary>({})
    const futureDate = useMemo(() => {
        const myFutureDate = new Date(date.getFullYear(), date.getMonth() + 2, 0)
        return myFutureDate
    }, [date])
    const {t} = useTwinTranslation()
    const pendingText = t('pending', 'Pendiente')
    const holidaysText = t('holidays', 'Vacaciones')

    const getEmployeesOrder = useCallback(async () => {
        const allEmployees = getEmployees()
        const employees = JSON.parse(JSON.stringify(allEmployees || {}))
        const myRangeView = getEmployeeParams()?.['WC_holiday_range_view']
        if (!myRangeView) {
            setEmployeesOrdered(dictionaryFromJsonArr(employees || {}, 'id', 'fullname_short'))
        } else {
            const myValue = JSON.parse(myRangeView)
            const myEmployees: TwinDictionary = {}
            for (const element of myValue) {
                myEmployees['employee-' + element] = employees[element].fullname_short
                delete employees[element]
            }
            const keysEmployees = Object.keys(employees)
            if (keysEmployees.length) {
                for (const element of keysEmployees) {
                    myEmployees['employee-' + element] = employees[element].fullname_short
                }
            }
            setEmployeesOrdered(myEmployees)
        }
    }, [setEmployeesOrdered])

    const getData = useCallback(async () => {
        const result: WCalHolidayUsedDayModelType[] = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getAllEmployeeHolidaysByDates', {
            dateStart: date.toDateString(),
            dateEnd: futureDate.toDateString()
        })
        if (result) {
            const myData: RangeViewCalendarEvents = {}
            for (const element of result) {
                const EmployeeId = element.WCalHolidayBag?.EmployeeId
                if (EmployeeId) {
                    if (!myData[EmployeeId]) {
                        myData[EmployeeId] = {}
                    }
                    const startDate = new Date(element.from)
                    const endDate = new Date(element.to)
                    const subtitleExtra = element.approved === 0 ? `\n(${pendingText})` : ''
                    let props = {
                        id: element.id,
                        allDay: true,
                        start: startDate,
                        end: endDate,
                        color: element.approved === 0 ? '#B97607' : '#43BAA5',
                        title: holidaysText + ' ' + element.WCalHolidayBag?.Employee?.name,
                        subtitle: (displayDate(startDate) !== displayDate(endDate) ? displayDate(startDate) + ' - ' + displayDate(endDate) : displayDate(startDate)) + subtitleExtra,
                    }
                    const currentDate = new Date(element.from)
                    while (endDate >= currentDate) {
                        const key = currentDate.toDateString()
                        myData[EmployeeId][key] = { ...props, rightSide: undefined }
                        currentDate.setDate(currentDate.getDate() + 1)
                    }
                }
            }
            setData(myData)
        }
        
    }, [date, pendingText, holidaysText, futureDate])

    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        getEmployeesOrder()
    }, [getEmployeesOrder])
    
    return { date, setDate, openModal, setOpenModal, getData, futureDate, data, employeesOrdered, getEmployeesOrder }
}

interface WCControlFreeDaysDateSelectorProps extends WCControlFreeDaysDateSelectorLogicProps {
    date: Date
    employees: TwinDictionary
    getEmployeesOrder: () => Promise<void>
}

const WCControlFreeDaysDateSelector: React.FC<WCControlFreeDaysDateSelectorProps> = ({ date, employees, ...rest }) => {
    const { nextMonth, onSelectMonth, prevMonth, showModalCalendar, ref, handleShowModalCalendar, opened, setOpened } = useWCControlFreeDaysDateSelectorLogic({ ...rest })
    const { dictMonths } = useDictMonths()
    const futureDate = new Date(date)
    futureDate.setMonth(futureDate.getMonth() + 1)

    return (
        <div className='flex items-end relative calendar_toolbar'>
            <div className='cursor-pointer mr-24 hover:text-green-21' onClick={prevMonth}><TwinIcon className='h-24' icon={faChevronLeft} /></div>
            <div className='cursor-pointer mr-24 hover:text-green-21' onClick={nextMonth}><TwinIcon className='h-24' icon={faChevronRight} /></div>
            <div className='flex items-center relative ' ref={ref}>
                <div className='toolbar-label  text-left text-gray-51 capitalize cursor-pointer' onClick={handleShowModalCalendar}>
                    {dictMonths[date.getMonth() + 1]} {date.getFullYear()} - {dictMonths[futureDate.getMonth() + 1]} {futureDate.getFullYear()}
                </div>
                <div className='cursor-pointer ml-24 hover:text-green-21' >
                    <TwinIcon className='h-24' icon={showModalCalendar ? faChevronUp : faChevronDown} onClick={handleShowModalCalendar} />
                </div>
                {showModalCalendar ? <TwinCalendar defaultActiveStartDate={date} defaultView='year' className='absolute z-10 time_selector_month_calendar top-full' onChange={() => null} onClickMonth={onSelectMonth} /> : null}
            </div>
            <ButtonSecondary onClick={() => setOpened(true)} className='ml-auto'>
                <TwinTrans transKey={'configOrder'}>Configurar orden</TwinTrans>
            </ButtonSecondary>
            {opened && <ModalWCHolidayRangeViewConfigOrder employees={employees} getEmployeesOrder={rest.getEmployeesOrder} setOpened={setOpened}/>}
        </div>
    )
}

interface WCControlFreeDaysDateSelectorLogicProps {
    setDate: React.Dispatch<React.SetStateAction<Date>>
}

const useWCControlFreeDaysDateSelectorLogic = ({ setDate }: WCControlFreeDaysDateSelectorLogicProps) => {
    const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false)
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const ref = useRef<any>(null)
    useOnClickOutside(ref, () => setShowModalCalendar(false))

    const handleShowModalCalendar = useCallback(() => {
        setShowModalCalendar((old) => !old)
    }, [setShowModalCalendar])

    const prevMonth = useCallback(() => {
        setDate((old) => {
            const myDate = new Date(old)
            myDate.setMonth(myDate.getMonth() - 2)
            return myDate
        })
    }, [setDate])

    const nextMonth = useCallback(() => {
        setDate((old) => {
            const myDate = new Date(old)
            myDate.setMonth(myDate.getMonth() + 2)
            return myDate
        })
    }, [setDate])


    const onSelectMonth = useCallback((myDate: Date) => {
        setDate(new Date(myDate.getFullYear(), myDate.getMonth(), 1))
        handleShowModalCalendar()
    }, [handleShowModalCalendar, setDate])

    return { prevMonth, nextMonth, onSelectMonth, showModalCalendar, ref, handleShowModalCalendar, opened, setOpened }
}

interface ModalWCHolidayRangeViewConfigOrderProps extends ModalWCHolidayRangeViewConfigOrderLogicProps {}

const ModalWCHolidayRangeViewConfigOrder: React.FC<ModalWCHolidayRangeViewConfigOrderProps> = ({ employees, getEmployeesOrder, setOpened }) => {
    const { data, updateEmployeeParams, changeOrder } = useModalWCHolidayRangeViewConfigOrderLogic({ employees, getEmployeesOrder, setOpened })
    return (
        <ModalMedium opened={true} setOpened={setOpened} className='flex flex-col'>
            <h2 className='mb-35'>
                <TwinTrans transKey='updateEmployeeOrder'>Actualizar el orden de empleados</TwinTrans>
            </h2>
            <LoaderDecider loading={data === null}>
                {data !== null && <VerticalSortList items={data as any} setItems={(value) => { changeOrder(value(data)) }}></VerticalSortList>}
            </LoaderDecider>
            <ButtonPrimary className='mt-30 ml-auto' onClick={() => updateEmployeeParams(data)}>
                <TwinTrans transKey='save'>Guardar</TwinTrans>
            </ButtonPrimary>
        </ModalMedium>
    )
}

interface ModalWCHolidayRangeViewConfigOrderLogicProps {
    employees: TwinDictionary
    getEmployeesOrder: () => Promise<void>
    setOpened: React.Dispatch<React.SetStateAction<ModalOpenedTy>>
}

const useModalWCHolidayRangeViewConfigOrderLogic = ({ employees, getEmployeesOrder, setOpened}: ModalWCHolidayRangeViewConfigOrderLogicProps) => {
    const [data, setData] = useState<VerticalSortItem[] | null>(() => {
        const employeesToOrder: VerticalSortItem[] = []
        let index = 0
        for (const key in employees) {
            const employee = employees[key]
            const id = key.includes('employee-') ? key.split('employee-')[1] : key
            index = index + 1
            employeesToOrder.push({
                name: employee,
                id: id,
                order: index
            })
        }
        return employeesToOrder
    })

    const changeOrder = useCallback(async (newOrder: VerticalSortItem[]) => {
        setData(newOrder)
    }, [setData])

    const updateEmployeeParams = useCallback(async (myData: VerticalSortItem[] | null) => {
        const idsOrder = []
        for (const element of myData || []) {
            idsOrder.push(element.id)
        }
        const myValue = JSON.stringify(idsOrder)
        await twinFetchPostJSON('/api/app/config/employee/updateEmployeeParameter', { parameterKey: 'WC_holiday_range_view', value: myValue })
        changeEmployeeParams({ 'WC_holiday_range_view': myValue })
        setOpened(null)
        getEmployeesOrder()
    }, [setOpened, getEmployeesOrder])

    return { data, updateEmployeeParams, changeOrder }
}


export default WCHolidayRequestRangeView