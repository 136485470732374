import ModalDeleteFilled from '../../../../../../../../baseComponents/ModalsLayouts/ModalDeleteFilled'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import { useFetchText } from '../../../../../../../../utils/hooks/useFetchText'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'

interface ModalDeleteCustomerProjectStatusProps extends ModalEditComponentProps { }

const ModalDeleteCustomerProjectStatus: React.FC<ModalDeleteCustomerProjectStatusProps> = ({ setOpened, onSubmit, allRowData }) => {
    const { t } = useTwinTranslation()
    const { handleFetchText } = useFetchText({
        onSubmit,
        url: '/api/app/customer/customerProject/customerProjectStatus/deleteCustomerProjectStatus',
        data: {
            id: allRowData?.id,
        }
    })
    return (
        <ModalDeleteFilled opened={true} setOpened={setOpened} onCancel={() => setOpened(null)} onAccept={handleFetchText} translations={{
            title: t('sureDeleteCustomerProjectStatus', '¿Seguro que quieres eliminar este estado del tipo de proyecto?'),
            subtitle: t('onceDeletedNotRecoverM', 'Una vez eliminado no podrás recuperarlo')
        }} />
    )
}

export default ModalDeleteCustomerProjectStatus