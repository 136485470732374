import { faCheckCircle, faCircleCheck, faPencil, faXmark } from '@fortawesome/pro-light-svg-icons'
import { CustomerProjectStatusModelType } from '@teinor/erp/types/company/customer/customerProjectStatus'
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { EditCardTabsHorizontalWithoutLinks } from '../../../baseComponents/EditCardTabs'
import { ModalWithCloseButton } from '../../../baseComponents/Modal'
import { ModalEditComponentProps } from '../../../baseComponents/ModalsLayouts/types'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { RowData } from '../../../baseComponents/TwinTable/types'
import CustomSelectColor from '../../../forms/CustomSelect/CustomSelectColor'
import { InputCalendarStateFull } from '../../../forms/Input/InputCalendar'
import TextArea, { TextAreaDebounce } from '../../../forms/Input/TextArea'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { displayDate } from '../../../utils/globals/date'
import { dictionaryComplexFromJsonArr, TwinDictionary } from '../../../utils/globals/dictionary'
import withLoading from '../../../utils/hoc/withLoading'
import { ComponentTabObject } from '../../../utils/hooks/useTabLogic'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../utils/reducers'
import { EmployeesPayload } from '../../../utils/reducers/company/employees'
import { getAllCustomerProjectTypes, getEmployees, getGroups, getTaskCFields } from '../../../utils/reducers/getters'
import { GroupPayload } from '../../../utils/reducers/groups/groups'
import { CustomSelectMultipleImageStateFull } from '../../../forms/CustomSelect/CustomSelectMultipleImage'
import CustomerProjectActivityTab from './CustomerProjectActivityTab'
import CustomerProjectCommentTab from './CustomerProjectCommentTab'
import CustomerProjectTaskTab from './CustomerProjectTaskTab'
import { CustomerProjectModelTypeExtended } from './types'
import { TaskCFieldPayload } from '../../../utils/reducers/company/taskCFields'
import { CustomerProjectTypePayload } from '../../../utils/reducers/customers/customerProjectTypes'
import { sortEmployeesByUserForProject, sortGroupsByUserForProject } from './functions'
import { ButtonDisabledOrLoader } from '../../../baseComponents/Button'
import useIsLoading from '../../../utils/hooks/useIsLoading'
import { OnSubmit } from '../../../forms/TwinForm/types'
import './modaleditcustomerprojectactivity.sass'
import TwinTrans from '../../../baseComponents/TwinTrans'
import { SingleTaskInner, useSingleTaskLogic } from '../../Task/SingleTask'
import { changeErrorMessage, changeSuccessMessage } from '../../../utils/reducers/reduxDispatch'
import { ModalOpenedSetTy } from '../../../baseComponents/Modal/types'
import { CustomSelectWithSearchBar } from '../../../forms/CustomSelect'


interface ModalEditCustomerProjectProps extends ModalEditComponentProps {}

const ModalEditCustomerProject: React.FC<ModalEditCustomerProjectProps> = ({ allRowData, setOpened, onSubmit }) => {
    const { customerProjectData, getCustomerProject, setCustomerProjectData, selectedTaskId, setSelectedTaskId, returnModal } = useModalEditCustomerProjectLogic({ allRowData: allRowData || {}, setOpened })

    if (!customerProjectData) {
        return null
    }

    return (
        <ModalWithCloseButton size='modal_big' opened={true} setOpened={setOpened} className='flex flex-col modal_edit_customerProject' onClickOut={false} showCloseButton={selectedTaskId ? true : false} setOpenedReturn={returnModal}>
            {selectedTaskId ? 
                <CustomerProjectSingleTask selectedTaskId={selectedTaskId} setSelectedTaskId={setSelectedTaskId}  />
                :  <CustomerProjectMain onSubmit={onSubmit} setSelectedTaskId={setSelectedTaskId} getCustomerProject={getCustomerProject} allRowData={allRowData || {}} customerProjectData={customerProjectData} setCustomerProjectData={setCustomerProjectData}/>
            }
        </ModalWithCloseButton>
    )
}

interface ModalEditCustomerProjectLogicProps {
    allRowData: RowData
    setOpened: ModalOpenedSetTy
}

const useModalEditCustomerProjectLogic = ({ allRowData, setOpened }: ModalEditCustomerProjectLogicProps) => {
    const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null)
    const [customerProjectData, setCustomerProjectData] = useState<CustomerProjectModelTypeExtended | null>(null)
    const id = allRowData.id

    const getCustomerProject = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/getCustomerProjectInstanceComplete', { id })
        if (result) {
            setCustomerProjectData(() => {
                const itemsEmployees: string[] = []
                const itemsGroups: string[] = []
                const dictCustomerProjectStatusHistory = dictionaryComplexFromJsonArr(result?.CustomerProjectStatusHistories, 'CustomerProjectStatusId', undefined, true)
                for (const element of result.Employees) {
                    itemsEmployees.push(String(element.id))
                }
                for (const element of result.Groups) {
                    itemsGroups.push(String(element.id))
                }

                return {
                    ...result,
                    dataSelectedEmployees: itemsEmployees,
                    dataSelectedGroups: itemsGroups,
                    customerProjectStatusesHistoryDict: dictCustomerProjectStatusHistory
                }
            })
        }
    }, [setCustomerProjectData, id])

    const returnModal = useCallback(() => {
        if (selectedTaskId) {
            setSelectedTaskId(null)
        } else {
            setOpened(null)
        }
    }, [setOpened, selectedTaskId, setSelectedTaskId])

    useEffect(() => {
        getCustomerProject()
    }, [getCustomerProject])

    return { customerProjectData, getCustomerProject, setCustomerProjectData, selectedTaskId, setSelectedTaskId, returnModal }
}

interface CustomerProjectSingleTaskProps {
    selectedTaskId: number
    setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>
}

const CustomerProjectSingleTask: React.FC<CustomerProjectSingleTaskProps> = ({ selectedTaskId, setSelectedTaskId }) => {
    const { onInvalidPermissions, onDeletedTask } = useCustomerProjectSingleTaskLogic()
    const { refSubTask, data: dataSingleTask, updateTask, setData, customerDicts, changeDescription, changeName, openModalInvertedTime, setOpenModalInvertedTime } = useSingleTaskLogic({ id: String(selectedTaskId), onInvalidPermissions, onDeletedTask })
    const employees = getEmployees()
    const groups = getGroups()
    const taskCFields = getTaskCFields();

    return (
        <div className='flex flex-auto single_task'>
            <div className={'flex flex-col w-full overflow-auto'}>
                {dataSingleTask?.finished ?
                    <div className='mr-auto flex items-center task_finished_message' >
                        <TwinIcon icon={faCheckCircle} className='mr-10 h-20' />
                        <TwinTrans transKey='finishedTask'>Tarea finalizada</TwinTrans>
                    </div> : null}
                {dataSingleTask && <SingleTaskInner data={dataSingleTask} setSelectedSubtaskId={setSelectedTaskId} {...{ updateTask, setData, changeDescription, changeName, refSubTask, customerDicts, openModalInvertedTime, setOpenModalInvertedTime, employees, groups, taskCFields }} />}
            </div>
        </div>
    )
}

const useCustomerProjectSingleTaskLogic = () => {
    const { t } = useTwinTranslation()
    const taskNotAvailable = t('taskNotAvailable', 'Esta tarea no está disponible')
    const taskDeleted = t('taskDeletedSuccessfully', 'Tarea eliminada con éxito')
    const taskHasBeenDeleted = t('taskHasBeenDeleted', 'Esta tarea ha sido eliminada')

    const onInvalidPermissions = useCallback(() => {
        changeErrorMessage(taskNotAvailable)
    }, [taskNotAvailable])

    const onDeletedTask = useCallback((otherPerson?: boolean) => {
        if (otherPerson) {
            changeErrorMessage(taskHasBeenDeleted)
        } else {
            changeSuccessMessage(taskDeleted)
        }
    }, [taskDeleted, taskHasBeenDeleted])

    return { onInvalidPermissions, onDeletedTask }
}

interface CustomerProjectMainProps extends CustomerProjectMainLogicProps {
    onSubmit: OnSubmit
    setSelectedTaskId: React.Dispatch<SetStateAction<number | null>>
    getCustomerProject: () => Promise<void>
    
}

const CustomerProjectMain: React.FC<CustomerProjectMainProps> = ({ allRowData, onSubmit, setSelectedTaskId, customerProjectData, setCustomerProjectData, getCustomerProject }) => {
    const { t } = useTwinTranslation()
    const { setTabActive, tabActive, onChangeCustomerProjectDescription, projectStatuses } = useCustomerProjectMainLogic({ allRowData: allRowData || {}, setCustomerProjectData, customerProjectData})
    
    const tabs: ComponentTabObject = {
        'activity': { component: CustomerProjectActivityTab, text: t('activity', 'Actividad') },
        'tasks': { component: CustomerProjectTaskTab, text: t('tasks', 'Tareas') },
        'comments': { component: CustomerProjectCommentTab, text: t('comments', 'Comentarios') }
    }    
    
    return (
        <div className='modal_edit_cproject flex-auto flex flex-col'>
            <CustomerProjectTitle setCustomerProjectData={setCustomerProjectData} customerProjectData={customerProjectData} onSubmit={onSubmit} />
            <h2 className='text-16 text-gray-51 font-light mt-10'>{allRowData?.Customer?.name}</h2>
            <CustomerProjectFirstHeader customerProjectData={customerProjectData} getCustomerProject={getCustomerProject} id={allRowData?.id} myCustomerProjectStatusesDict={projectStatuses.myCustomerProjectStatusesDict} />
            <CustomerProjectStatusBar CurrentCProjectStatusId={customerProjectData.CurrentCProjectStatusId} customerProjectStatusesHistoryDict={customerProjectData.customerProjectStatusesHistoryDict} myCustomerProjectStatusesArr={projectStatuses.myCustomerProjectStatusesArr} />
            <TextAreaDebounce className='mt-34 w-full mr-15 modal_edit_customer_project_description' name='description' value={customerProjectData?.description || ''} onChange={onChangeCustomerProjectDescription} label={t('description', 'Descripción')} />
            <EditCardTabsHorizontalWithoutLinks tabs={tabs} tab={tabActive} onClick={setTabActive} extraComponentData={{ customerProjectData, getCustomerProject, setSelectedTaskId }} className='customer_project_tabs mt-34 flex-auto flex flex-col' />
        </div>
    )
}

interface CustomerProjectMainLogicProps {
    allRowData: RowData
    customerProjectData: CustomerProjectModelTypeExtended
    setCustomerProjectData: React.Dispatch<SetStateAction<CustomerProjectModelTypeExtended | null>>
}

const useCustomerProjectMainLogic = ({ customerProjectData, setCustomerProjectData, allRowData }: CustomerProjectMainLogicProps) => {
    const [tabActive, setTabActive] = useState('activity')
    const id = allRowData.id

    const projectStatuses = useMemo(() => {
        const customerProjectTypes = getAllCustomerProjectTypes()
        const CustomerProjectTypeId = customerProjectData?.CustomerProjectTypeId
        if (CustomerProjectTypeId) {
            const myCustomerProjectStatusesDict = customerProjectTypes?.[CustomerProjectTypeId]?.customerProjectStatusDict || {}
            const myCustomerProjectStatusesArr = customerProjectTypes?.[CustomerProjectTypeId].CustomerProjectStatuses || []
            return { myCustomerProjectStatusesDict, myCustomerProjectStatusesArr }
        }
        return { myCustomerProjectStatusesDict: {}, myCustomerProjectStatusesArr: [] }
    }, [customerProjectData?.CustomerProjectTypeId])

    const onChangeCustomerProjectDescription = useCallback(async (value: string) => {
        setCustomerProjectData((old) => {
            if (!old) {
                return null
            }
            return { ...old, description: value }
        })
        await twinFetchPostJSON('/api/app/customer/customerProject/updateCustomerProject', {
            id,
            description: value
        })
    }, [id, setCustomerProjectData])

    return { tabActive, setTabActive, projectStatuses, onChangeCustomerProjectDescription }
}


interface CustomerProjectTitleProps extends CustomerProjectTitleLogicProps {}

const CustomerProjectTitle: React.FC<CustomerProjectTitleProps> = ({customerProjectData, ...logic}) => {
    const { loading, opened, setOpened, setTitle, updateNameProject, title } = useCustomerProjectTitleLogic({ customerProjectData, ...logic })
    const {t} = useTwinTranslation()
    if (opened) {
        return (
            <div className='flex items-center'>
                <TextArea className='w-full' value={title} onChange={setTitle} />
                <ButtonDisabledOrLoader onClick={updateNameProject} loading={loading} buttonIsDisabled={title === '' || customerProjectData?.name === title} textButton={t('save', 'Guardar')} />
                <TwinIcon className='ml-10 cursor-pointer hover:text-red-BA' icon={faXmark} onClick={() => setOpened(false)} />
            </div>
        )
    }
    return (
        <div className='flex items-center mt-30'>
            <h2>{title}</h2>
            <TwinIcon className='ml-10 cursor-pointer hover:text-green-43' icon={faPencil} onClick={() => setOpened(true)} />
        </div>
    )
}

interface CustomerProjectTitleLogicProps {
    setCustomerProjectData: React.Dispatch<React.SetStateAction<CustomerProjectModelTypeExtended | null>>
    customerProjectData: CustomerProjectModelTypeExtended | null
    onSubmit: OnSubmit
}

const useCustomerProjectTitleLogic = ({ setCustomerProjectData, customerProjectData, onSubmit }: CustomerProjectTitleLogicProps) => {
    const [opened, setOpened] = useState(false)
    const [title, setTitle] = useState(customerProjectData?.name || '')
    const { startLoading, endLoading, loading } = useIsLoading()

    const updateNameProject = useCallback(async () => {
        startLoading()
        const res = await twinFetchPostJSON('/api/app/customer/customerProject/updateCustomerProject', { name: title, id: customerProjectData?.id })
        setCustomerProjectData((old) => {
            if (!old) {
                return null
            }
            return { ...old, name: title }
        })
        endLoading()
        onSubmit(res, {})
        setOpened(false)
    }, [startLoading, endLoading, title, customerProjectData?.id, setCustomerProjectData, onSubmit])

    return { loading, opened, setTitle, setOpened, updateNameProject, title }
}


interface CustomerProjectFirstHeaderProps extends CustomerProjectFirstHeaderLogicProps {
    myCustomerProjectStatusesDict: TwinDictionary
    customerProjectData: CustomerProjectModelTypeExtended
}

const CustomerProjectFirstHeader: React.FC<CustomerProjectFirstHeaderProps> = ({ customerProjectData, myCustomerProjectStatusesDict, getCustomerProject, id }) => {
    const { t } = useTwinTranslation()
    const { handleOnChangeCustomerProjectLimitDate, handleOnChangeCustomerProjectStatus, handleOnAddEmployeeSelector, handleOnDeleteEmployeeSelector, handleOnAddGroupSelector, handleOnDeleteGroupSelector, employees, groups, customerProjectsTypes, handleChangeCustomerProjectType } = useCustomerProjectFirstHeaderLogic({ getCustomerProject, id })
    return (
        <div className='mt-18 flex items-start justify-between'>
            <div className='mr-4 flex gap-5'>
                <CustomSelectMultipleImageStateFull itemsSelected={customerProjectData?.dataSelectedEmployees} defaultImage='/user-png.png' items={employees || {}} sortFunction={sortEmployeesByUserForProject} onAdd={handleOnAddEmployeeSelector} onDelete={handleOnDeleteEmployeeSelector} label={t('employeesAssigned', 'Empleados asignados')} type={'Employees'} fieldImage='profile_image' fieldName='fullname_short'/>
                <CustomSelectMultipleImageStateFull itemsSelected={customerProjectData?.dataSelectedGroups} defaultImage='/group.png' items={groups || {}} sortFunction={sortGroupsByUserForProject}  onAdd={handleOnAddGroupSelector} onDelete={handleOnDeleteGroupSelector} label={t('groupsAssigned', 'Grupos asignados')} type={'Groups'}/>
            </div>
            <div className='flex flex-auto gap-20'>
                <InputCalendarStateFull label={t('limitDate', 'Fecha límite')} onlyValids={true} value={customerProjectData?.limit_date} className='w-full' onChange={handleOnChangeCustomerProjectLimitDate}></InputCalendarStateFull>
                <CustomSelectColor items={myCustomerProjectStatusesDict} value={customerProjectData?.CurrentCProjectStatusId} label={t('changeStatus', 'Cambiar de estado')} onChange={handleOnChangeCustomerProjectStatus} />
                <CustomSelectWithSearchBar className='w-full' items={customerProjectsTypes} value={customerProjectData?.CustomerProjectTypeId} label={t('projectType', 'Tipo de proyecto')} onChange={handleChangeCustomerProjectType} />
            </div>
        </div>
    )
}

interface CustomerProjectFirstHeaderLogicProps {
    getCustomerProject: () => Promise<void>
    id: number
}

const useCustomerProjectFirstHeaderLogic = ({ getCustomerProject, id }: CustomerProjectFirstHeaderLogicProps) => {
    const employees = useMemo(() => getEmployees() || {}, [])
    const groups = useMemo(() => getGroups() || {}, [])
    const customerProjectsTypes = useMemo(() => getAllCustomerProjectTypes() || {}, [])
    const handleOnAddEmployeeSelector = useCallback(async (value: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/assignCustomerProjectEmployee', {
            CustomerProjectId: id,
            EmployeeId: value
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])

    const handleOnDeleteEmployeeSelector = useCallback(async (value: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/removeCustomerProjectEmployee', {
            CustomerProjectId: id,
            EmployeeId: value
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])
    const handleOnAddGroupSelector = useCallback(async (value: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/assignCustomerProjectGroup', {
            CustomerProjectId: id,
            GroupId: parseInt(value)
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])

    const handleOnDeleteGroupSelector = useCallback(async (value: string) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/removeCustomerProjectGroup', {
            CustomerProjectId: id,
            GroupId: parseInt(value)
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])

    const handleOnChangeCustomerProjectLimitDate = useCallback(async (value: any) => {
        await twinFetchPostJSON('/api/app/customer/customerProject/updateCustomerProject', {
            id,
            limit_date: value
        })
    }, [id])

    const handleChangeCustomerProjectType = useCallback(async (value: any) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/changeTypeCustomerProject', {
            CustomerProjectId: id,
            CustomerProjectTypeId: value
        })
        if (result) {
            getCustomerProject()
        }
    }, [id, getCustomerProject])

    const handleOnChangeCustomerProjectStatus = useCallback(async (CurrentCProjectStatusId: string) => {
        await twinFetchPostJSON('/api/app/customer/customerProject/updateCustomerProjectCurrentStatus', {
            id,
            CurrentCProjectStatusId
        })
        getCustomerProject()
    }, [id, getCustomerProject])

    useEffect(() => {
        getCustomerProject()
    }, [getCustomerProject])

    return { handleOnAddEmployeeSelector, handleOnDeleteEmployeeSelector, handleOnAddGroupSelector, handleOnDeleteGroupSelector, handleOnChangeCustomerProjectLimitDate, handleOnChangeCustomerProjectStatus, employees, groups, customerProjectsTypes, handleChangeCustomerProjectType }
}



interface CustomerProjectStatusBarProps  {
    CurrentCProjectStatusId: number
    customerProjectStatusesHistoryDict: TwinDictionary
    myCustomerProjectStatusesArr: CustomerProjectStatusModelType[]
}

const CustomerProjectStatusBar: React.FC<CustomerProjectStatusBarProps> = ({ CurrentCProjectStatusId, myCustomerProjectStatusesArr, customerProjectStatusesHistoryDict }) => {
    const renderThis: JSX.Element[] = []
    let lastDate: null | Date = null
    let isColored = CurrentCProjectStatusId ? true : false
    for (const element of myCustomerProjectStatusesArr) {
        if (element.listing) {
            const finishedDate = customerProjectStatusesHistoryDict[element.id]?.finishedDate
            let displayDateDate = finishedDate ? new Date(finishedDate) : null
            if (!lastDate && displayDateDate) {
                lastDate = displayDateDate
            }
            if (displayDateDate && lastDate && displayDateDate < lastDate) {
                displayDateDate = null
            } else if(displayDateDate) {
                lastDate = displayDateDate
            }
            const isCurrentStatus = CurrentCProjectStatusId === element.id 
            if (isCurrentStatus) {
                const createdAt = customerProjectStatusesHistoryDict[element.id]?.createdAt
                displayDateDate = new Date(createdAt)
            }
            renderThis.push(
                <CustomerProjectStatusBox name={element.name} displayDate={isColored && displayDateDate ? displayDate(displayDateDate) : ''} color={isColored ? element.color : '#D9D9D9'} hasIcon={!isCurrentStatus} key={element.id}/>
            )
            if (isCurrentStatus) {
                isColored = false
            }
        }
    }
    return (
        <div className='mt-23 flex gap-2 overflow-auto'>
            {renderThis}
        </div>
    )
}

interface CustomerProjectStatusBoxProps {
    displayDate?: string
    name: string
    color?: string
    hasIcon?: boolean
}

const CustomerProjectStatusBox: React.FC<CustomerProjectStatusBoxProps> = ({ displayDate, name, color, hasIcon }) => {
    return (
        <div className='flex flex-col customer_project_status_box p-10 bg-gray-F7 border-t-4' style={{borderColor: color}}>
            <span className='text-gray-51 text-14 twin_elipsis'>{name}</span>
            {displayDate ? <div className='flex justify-between items-center h-24'>
                <span className='text-gray-51 text-12 font-light mr-40'>{displayDate}</span>
                {hasIcon ? <TwinIcon icon={faCircleCheck} className='text-green-43 w-20 h-20'></TwinIcon> : null}
            </div> : null}
        </div>
    )
}

const customerEmployeeGroupDispatch = {
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
    setCustomerProjectTypes: (payload: CustomerProjectTypePayload) => ({ type: 'CHANGE_CUSTOMER_PROJECT_TYPES', payload }),
}

export type ReduxCustomerEmployeeGroup= ConnectedProps<typeof customerEmployeeGroupConnect>
const mapCustomerEmployeeGroupConnector = (state: AllReduxPayloads) => ({ groups: state.groups, employees: state.employees, taskCFields: state.taskCFields, customerProjectTypes: state.customerProjectTypes })
const customerEmployeeGroupConnect = connect(mapCustomerEmployeeGroupConnector, customerEmployeeGroupDispatch)

const customerEmployeeGroupConnectLoading = withLoading(ModalEditCustomerProject, [{ fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/task/customField/getAllTaskCFields', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }, { fetchUrl: '/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypesFull', propName: 'customerProjectTypes', setFunctionName: 'setCustomerProjectTypes' }])

const ModalEditCustomerProjectConnect = customerEmployeeGroupConnect(customerEmployeeGroupConnectLoading)

export default ModalEditCustomerProjectConnect