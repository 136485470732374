import { faArrowUpRightAndArrowDownLeftFromCenter, faArrowUpRightFromSquare, faCirclePause, faCirclePlay, faCircleStop, faWindowMinimize } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { HeaderElementTask } from '../../../../specificComponents/Task/SingleTask/HeaderTask'
import { twinFetchPostJSON, twinFetchPostText } from '../../../../utils/globals/data'
import { displayFormatedHourFromSecs } from '../../../../utils/globals/date'
import useInterval from '../../../../utils/hooks/useInterval'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { getEmployeeParams } from '../../../../utils/reducers/getters'
import { addBottomNavAppElement, changeBottomNavAppElement, deleteBottomNavAppElement } from '../../../../utils/reducers/reduxDispatch'
import TwinIcon from '../../../TwinIcon'

interface ModalWorkingTaskProps {
    extraData: any
}

const ModalWorkingTask: React.FC<ModalWorkingTaskProps> = ({ extraData }) => {
    const {t} = useTwinTranslation()
    const parameters = getEmployeeParams()
    const floatingTasks = parameters?.['floatingTasks'] === 'on' ? true : false
    const { timer, opened, handleOpened, startTimer, stopTimer, closeModalWorkingTask, isWorking, openModalTaskFloating } = useModalWorkingTaskLogic({ extraData, floatingTasks })
    const parsedTimer = displayFormatedHourFromSecs(timer.workedSecs+timer.acomulatedSecs)
    const name: string = extraData?.name || t('taskNoName', 'Tarea sin título')
    return (
        <div className='min-w-300 mr-20'>
            {!opened  ?
                <div className='modal_working_task fixed flex items-center light20  bg-green-43 text-white px-24 py-15  rounded-t-lg bottom-0 w-300'>
                    <div className='twin_elipsis mr-15'>{extraData.TaskId} - {name}</div>
                    <div className='mr-15'>{parsedTimer}</div>
                    <TwinIcon className='cursor-pointer ml-auto' icon={faArrowUpRightAndArrowDownLeftFromCenter} onClick={handleOpened} />
                </div>
                : 
                <div className={'modal_working_task regular16 fixed text-white px-24 py-15  rounded-t-lg bottom-0 w-300 ' + (isWorking ? 'bg-green-43' : ' bg-red-F0')}>
                    <div className='mb-15 flex items-center'>
                        <div className='twin_elipsis mr-15'>{extraData.TaskId} - {name}</div>
                        <div className='flex items-center ml-auto '>
                            <HeaderElementTask className='task_working_minimize_icon mr-20' icon={faWindowMinimize} message={t('minimize', 'Minimizar')} id='minimizeWorkingModal' onClick={handleOpened} />
                            <ShowWorkingTaskIcon  TaskId={extraData?.TaskId} {...{ openModalTaskFloating, floatingTasks}} />
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <div className='light20 mr-auto'>{parsedTimer}</div>
                        <TwinIcon icon={isWorking ? faCirclePause : faCirclePlay} className='mr-15 h-35 cursor-pointer' onClick={isWorking ? stopTimer : startTimer} />
                        <TwinIcon icon={faCircleStop} className='h-35 cursor-pointer' onClick={closeModalWorkingTask} />
                    </div>
                </div>
            }
        </div>
    )
}

interface ModalWorkingTaskLogicProps {
    extraData: any
    floatingTasks: boolean
}

const useModalWorkingTaskLogic = ({ extraData, floatingTasks }: ModalWorkingTaskLogicProps) => {
    const { id, TaskId, name, startDate, TaskBagOfHoursId } = extraData
    const [opened, setOpened] = useState<boolean>(true)
    const [isWorking, setIsWorking] = useState<boolean>(true)
    const [timer, setTimer] = useState(() => {
        const parsedStartDate = new Date(startDate).getTime()
        const now = new Date()
        const workedSecs = Math.round(Math.abs(parsedStartDate - now.getTime()) / 1000)
        return { workedSecs, acomulatedSecs: 0, parsedStartDate }
    })
    const navigate = useNavigate()

    const handleOpened = useCallback(() => {
        setOpened((old) => !old)
    }, [setOpened])

    const startTimer = useCallback(async () => {
        const startDate = new Date()
        const newWorkLog = await twinFetchPostJSON('/api/app/task/workLog/createTaskWorkLog', { TaskId, startDate: startDate.toISOString(), TaskBagOfHoursId })
        setTimer((old) => {
            return {
                acomulatedSecs: old.acomulatedSecs,
                parsedStartDate: startDate.getTime(),
                workedSecs: 0
            }
        })
        setIsWorking(true)
        changeBottomNavAppElement({ type: 'workingModal', key: 'workingModal', extraData: { startDate: newWorkLog.startDate, TaskId, id: newWorkLog.id, name, TaskBagOfHoursId } })
        setIsWorking(true)
    }, [TaskId, name, setIsWorking, TaskBagOfHoursId])

    const stopTimer = useCallback(async () => {
        if (isWorking) {
            const endDate = new Date()
            setIsWorking(false)
            const now = new Date()
            const workedSecs = Math.round(Math.abs(timer.parsedStartDate - now.getTime()) / 1000)
            setTimer((old) => {
                return { acomulatedSecs: workedSecs + old.acomulatedSecs, workedSecs: 0, parsedStartDate: old.parsedStartDate}
            })
            await twinFetchPostText('/api/app/task/workLog/endTaskWorkLog', { id, TaskId, endDate: endDate.toISOString(), workedSecs, TaskBagOfHoursId })
        }
    }, [TaskId, id, setIsWorking, isWorking, timer, TaskBagOfHoursId])

    const updateTaskWorkLog = useCallback(async () => {
        const now = new Date()
        const workedSecs = Math.round(Math.abs(timer.parsedStartDate - now.getTime()) / 1000)
        await twinFetchPostText('/api/app/task/workLog/updateTaskWorkLog', { id, TaskId, workedSecs, TaskBagOfHoursId })
    }, [id, TaskId, timer, TaskBagOfHoursId])

    const closeModalWorkingTask = useCallback(async () => {
        await stopTimer()
        await deleteBottomNavAppElement('workingModal')
    }, [stopTimer])

    const openModalTaskFloating = useCallback((id: string) => {
        if(floatingTasks){
            addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + id, extraData: { id, opened: true } })
        } else {
            navigate('/task/tasks/' + id)
        }
    }, [floatingTasks, navigate])

    useInterval(() => {
        setTimer((old) => {
            const now = new Date()
            const workedSecs = Math.round(Math.abs(old.parsedStartDate - now.getTime()) / 1000)
            return { acomulatedSecs: old.acomulatedSecs, workedSecs, parsedStartDate: old.parsedStartDate }
        })
    }, isWorking ? 1000 : false)

    useInterval(() => {
        updateTaskWorkLog()
    }, isWorking ? (1000 * 60 * 3) : false)

    useEffect(() => {
        if (isWorking) {
            window.onbeforeunload = function () {
                updateTaskWorkLog()
            }
        }
    }, [updateTaskWorkLog, isWorking])

    return { opened, handleOpened, timer, startTimer, stopTimer, closeModalWorkingTask, isWorking, openModalTaskFloating }
}


interface ShowWorkingTaskIconProps {
    TaskId: string
    floatingTasks: boolean
    openModalTaskFloating: (id: string) => void
}

const ShowWorkingTaskIcon: React.FC<ShowWorkingTaskIconProps> = ({ floatingTasks, TaskId, openModalTaskFloating}) => {
    const {t} = useTwinTranslation()
    const floatingTasksDict = {
        "false": {
            icon: faArrowUpRightFromSquare,
            text: t('show', 'Mostrar')
        },
        "true": {
            icon: faArrowUpRightAndArrowDownLeftFromCenter,
            text: t('maximize', 'Maximizar')
        }
    }
    const floatingTaskItem = floatingTasksDict[floatingTasks ? 'true' : 'false']
    return (<HeaderElementTask className='task_working_show_task' icon={floatingTaskItem.icon} message={floatingTaskItem.text} id='maximize' onClick={() => openModalTaskFloating(TaskId)} />
    )
}

export default ModalWorkingTask