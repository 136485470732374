import { useCallback, useEffect } from 'react'
import { ModalEditComponentProps } from '../../../../../../../../baseComponents/ModalsLayouts/types'
import { twinFetchPostJSON } from '../../../../../../../../utils/globals/data'
import { changeLastNotification } from '../../../../../../../../utils/reducers/reduxDispatch'
import ModalUserNotificationsAbsences from './Modals/ModalUserNotificationsAbsences'
import ModalAdminNotificationsAbsences from './Modals/ModalAdminNotificationsAbsences'
import ModalUserNotificationTasks from './Modals/ModalUserNotificationTasks'
import ModalAdminNotificationExpenses from './Modals/ModalAdminNotificationExpenses'
import ModalUserNotificationsProjects from './Modals/ModalUserNotificationsProjects'
import ModalAdminNotificationsHolidayUsedDays from './Modals/ModalAdminNotificationsHolidayUsedDays'
import ModalEmployeePayslipReceived from './Modals/ModalEmployeePayslipReceived'
import ModalUserNotificationExpenses from './Modals/ModalUserNotificationExpenses'
import ModalUserNotificationsHolidayUsedDays from './Modals/ModalUserNotificationsHolidayUsedDays'
import { EmployeeNotificationLogTypeKeys } from '@teinor/erp/types/company/userInner/employee/employeeNotification'

interface ModalAllNotificationsEmployeeProps extends ModalAllNotificationsEmployeeLogicProps {}

const ModalAllNotificationsEmployee: React.FC<ModalAllNotificationsEmployeeProps> = ({...rest}) => {
    useModalAllNotificationsEmployeeLogic({ ...rest })

    const statusModals: Record<EmployeeNotificationLogTypeKeys, React.FC<ModalEditComponentProps>> = {
        0: ModalAdminNotificationsHolidayUsedDays,
        1: ModalUserNotificationsHolidayUsedDays,
        2: ModalUserNotificationsHolidayUsedDays,
        3: ModalUserNotificationsHolidayUsedDays,
        4: ModalAdminNotificationsAbsences,
        5: ModalUserNotificationsAbsences,
        6: ModalUserNotificationsAbsences,
        7: ModalUserNotificationsAbsences,
        8: ModalAdminNotificationsAbsences,
        9: ModalAdminNotificationsAbsences,
        10: ModalEmployeePayslipReceived,
        11: ModalAdminNotificationExpenses,
        12: ModalUserNotificationExpenses,
        13: ModalUserNotificationExpenses,
        14: ModalUserNotificationExpenses,
        15: ModalUserNotificationExpenses,
        16: ModalUserNotificationsProjects,
        17: ModalUserNotificationsProjects,
        18: ModalUserNotificationTasks,
        19: ModalUserNotificationTasks,
        20: ModalUserNotificationTasks,
        21: ModalUserNotificationTasks,
        22: ModalUserNotificationTasks,
        23: ModalUserNotificationTasks,
        24: ModalUserNotificationsProjects,
    }

    const Component = statusModals[(rest.allRowData?.logType || 0) as EmployeeNotificationLogTypeKeys ]
    return <Component {...rest} />
}

interface ModalAllNotificationsEmployeeLogicProps extends ModalEditComponentProps {}

const useModalAllNotificationsEmployeeLogic = ({allRowData, onSubmit }: ModalAllNotificationsEmployeeLogicProps) => {
    
    const updateNotificationReaded = useCallback(async () => {
        if (!allRowData?.readed) {
            const result = await twinFetchPostJSON('/api/app/employee/employeeNotification/updateEmployeeNotification', {
                id: allRowData?.id,
                readed: true
            })
            if (result) {
                onSubmit(result, {})
                changeLastNotification(null)
            }
        }
    }, [allRowData?.id, onSubmit, allRowData?.readed])
    
    useEffect(() => {
        updateNotificationReaded()
    }, [updateNotificationReaded])

    return {}
}

export default ModalAllNotificationsEmployee