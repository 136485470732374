import { useParams } from 'react-router-dom'
import EditCardTabs from '../../../../../baseComponents/EditCardTabs'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { useBasicData } from '../../../../../utils/hooks/useBasicData'
import { ComponentTabObject } from '../../../../../utils/hooks/useTabLogic'
import MainEmployeeProfile from './Tabs/MainEmployeeProfile'
import EmployeeParameters from './Tabs/EmployeeParameters/intex'
import MyEmployeeFiles from './Tabs/MyEmployeeFiles'
import EmployeeTaskHelperCreator from './Tabs/EmployeeTaskHelperCreator'
import EmployeePayslip from './Tabs/EmployeePayslip'
import EmployeeExpenses from './Tabs/EmployeeExpenses'

interface EmployeeProfileProps {}

const EmployeeProfile: React.FC<EmployeeProfileProps> = () => {
    const params = useParams<{ tab?: string }>()
    const { t } = useTwinTranslation()
    const { basicData, getData } = useBasicData({ params: {}, url: '/api/app/employee/getMyEmployee'})
    if (!basicData) {
        return null
    }
    const tabs: ComponentTabObject = {
        'main': { component: MainEmployeeProfile, text: t('general', 'General')},
        'parameters': { component: EmployeeParameters, text: t('parameters', 'Parámetros') },
        'task': { component: EmployeeTaskHelperCreator, text: t('taskHelpers', 'Ayudantes de tareas')},
        'payslip': { component: EmployeePayslip, text: t('payslips', 'Nóminas'), modules: ['working_calendar_full']},
        'expenses': { component: EmployeeExpenses, text: t('expenses', 'Gastos'), modules: ['employee_expenses'] },
        'files': { component: MyEmployeeFiles, text: t('files', 'Ficheros') },
    }

    return (<EditCardTabs basePath={'/employeeProfile'} basicData={basicData} tabs={tabs} tab={params.tab} userPermissions={{ n: 4, permission: 'delete' }} getData={getData} />)
}

export default EmployeeProfile