import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { Fragment, useMemo, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ButtonSecondary } from '../../../../../baseComponents/Button'
import TwinIcon from '../../../../../baseComponents/TwinIcon'
import { AllReduxAppPayloads, GlobalContext, appStore } from '../../../../../utils/reducers'
import EditCompanyModal from './EditCompanyModal'
import './companyInformation.sass'
import TokenDisplay from '../../../components/TokenDisplay'
import { Company, CompanyPayload } from '../../../../../utils/reducers/globals/company'
import { getStateByCountryAndId } from '../../../../../utils/globals/countries'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { ModalOpenedTy } from '../../../../../baseComponents/Modal/types'
import { useParams } from 'react-router-dom'

interface CompanyInformationProps extends ReduxCompanyInformationConnector {}

const CompanyInformation: React.FC<CompanyInformationProps> = ({ company, setCompany }) => {
    if (!company) {
        return null
    }
    return (
        <div className='company_information bg-white'>
            <HeaderCompanyInformation {...{ company, setCompany }}/>
            <ImageAndToken token={company.token} companyName={company.name} companyImage={company.image} />
            <CompanyInfo company={company} />
            <FooterCompanyInformation />
        </div>
    )
}

interface HeaderCompanyInformationProps {
    company: CompanyPayload
    setCompany: (payload: CompanyPayload) => {
        type: string
        payload: CompanyPayload
    }
}

const HeaderCompanyInformation: React.FC<HeaderCompanyInformationProps> = ({company, setCompany}) => {
    const [openEditModal, setOpenEditModal] = useState<ModalOpenedTy>(null)
    const params = useParams<{ id: string }>()
    const id = params.id
    return (
        <Fragment>
            <div className='flex justify-between flex-wrap gap-20'>
                <h2>
                    <TwinTrans transKey={'companyInformation'}>Información de contacto</TwinTrans>
                </h2>
                <ButtonSecondary onClick={() => setOpenEditModal(true)}>
                    <TwinIcon icon={faEdit} className='mr-10' style={{width: '13px', height: '13px'}} />
                    <TwinTrans transKey={'editar'}>Editar</TwinTrans>
                </ButtonSecondary>
            </div>
            {openEditModal && <EditCompanyModal id={id||''} {...{ openEditModal, setOpenEditModal, company, setCompany }} />}
        </Fragment>
    )
}

interface ImageAndTokenProps {
    token: string
    companyName: string
    companyImage?: string
}

const ImageAndToken: React.FC<ImageAndTokenProps> = ({ token, companyName, companyImage }) => {
    return (
        <div className='flex items-center image_div_company_information'>
            <img src={companyImage || '../no-image.png'} alt={companyName} className='image_company_information' />
            <TokenDisplay token={token} />
        </div>
    )
}

interface CompanyInfoProps{
    company: Company
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({ company }) => {
    const { state, countries } = useCompanyInfoLogic(company)
    return (
        <div>
            <div className='company_info_section grid grid-cols-2 mt-20'>
                <CompanyInfoItem value={company.fiscal_name}>
                    <TwinTrans transKey={'fiscalName'}>Nombre Fiscal</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.name}>
                    <TwinTrans transKey={'name'}>Nombre</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.address}>
                    <TwinTrans transKey={'address'}>Dirección</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.additional_address}>
                    <TwinTrans transKey={'additionalInfo'}>Datos adicionales</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.city}>
                    <TwinTrans transKey={'town'}>Población</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.postal_code}>
                    <TwinTrans transKey={'postalCode'}>Código Postal</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={state}>
                    <TwinTrans transKey={'state'}>Provincia</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={countries[company.country].name}>
                    <TwinTrans transKey={'country'}>País</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.id_number}>
                    <TwinTrans transKey={'idCard'}>Identificación Fiscal</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.phone}>
                    <TwinTrans transKey={'phone'}>Teléfono</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.mobile}>
                    <TwinTrans transKey={'mobile'}>Teléfono móvil</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.email}>
                    <TwinTrans transKey={'email'}>Email</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.billing_email}>
                    <TwinTrans transKey={'billingEmail'}>Email de facturación</TwinTrans>
                </CompanyInfoItem>
                <CompanyInfoItem value={company.urlName}>
                    <TwinTrans transKey={'urlSues'}>URL Denuncias</TwinTrans>
                </CompanyInfoItem>
            </div>
        </div>
    )
}

export const useCompanyInfoLogic = (company: Company) => {
    const countries = useMemo(() => appStore.getState().countries, [])
    const state = getStateByCountryAndId(countries[company.country], parseInt(company.state))
    return { state: state ? state.name : '', countries }
}
interface CompanyInfoItemProps {
    value: string
    children?: React.ReactNode
}

const CompanyInfoItem: React.FC<CompanyInfoItemProps> = ({ value, children }) => {
    return (
        <div className='mb-32'>
            <div className='mb-6 company_info_item_title'>
                <span className='light12 text-gray-51'>{children}</span>
            </div>
            <div className='company_info_item_text'>
                <span>{value}</span>
            </div>
        </div>
    )
}

interface FooterCompanyInformationProps {}

const FooterCompanyInformation: React.FC<FooterCompanyInformationProps> = () => {
    return (
        <div className='mt-auto flex items-center justify-between'>

        </div>
    )
}

const companyInformationDispatch = {
    setCompany: (payload: CompanyPayload) => ({ type: 'CHANGE_COMPANY', payload }),
}
const mapCompanyInformationConnector = (state: AllReduxAppPayloads) => ({ company: state.company })
const companyInformationConnector = connect(mapCompanyInformationConnector, companyInformationDispatch, null, { context: GlobalContext })
type ReduxCompanyInformationConnector = ConnectedProps<typeof companyInformationConnector>
export const CompanyInformationConnect = companyInformationConnector(CompanyInformation)

export default CompanyInformationConnect