import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import ModalCreateTaskRepository from './ModalCreateTaskRepository'
import { useParams } from 'react-router-dom'
import { useAddDoubleTableGreenRowRender } from '../../../../../../baseComponents/DoubleTable/functions'
import { TaskRepositoryModelType } from '@teinor/erp/types/company/task/taskRepository'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { useMemo } from 'react'
import { RowData } from '../../../../../../baseComponents/TwinTable/types'
import TwinToolTip from '../../../../../../baseComponents/TwinToolTip'
import ModalEditTaskRepository from './ModalEditTaskRepository'
import ModalDeleteTaskRepository from './ModalDeleteTaskRepository'
import { CustomRenderRoundedImage, DeleteRow, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { permissionCheck } from '../../../../../../baseComponents/PermissionChecker/function'
import { TableComponentProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTable/types'

interface TaskRespositoriesProps extends ComponentWithPermissions { }

const TaskRespositories: React.FC<TaskRespositoriesProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()

    const pageData = {
        title: t('repository', 'Repositorio'),
        newText: t('newIssue', 'Nuevo tema'),
    }

    return (
        <BaseScreenTable TableComponent={TableTaskRespositories} CreateModalComponent={ModalCreateTaskRepository} EditModalComponent={ModalEditTaskRepository} DeleteModalComponent={ModalDeleteTaskRepository} pageData={pageData} userPermissions={userPermissions} />
    )
}

interface TableTaskRespositoriesProps extends TableComponentProps { }

const TableTaskRespositories: React.FC<TableTaskRespositoriesProps> = ({ setTableInstance, setOpened, userPermissions }) => {
    const { t } = useTwinTranslation()
    const { id } = useParams()
    const { rowRenderer } = useAddDoubleTableGreenRowRender(id || '')
    const columns: ColumnTableSchema<TaskRepositoryModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            sortable: true,
        },
        {
            id: 'title',
            dataKey: 'title',
            label: t('title', 'Título'),
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('title', 'Título') }
            }
        },
        {
            id: 'customer',
            dataKey: 'CustomerId',
            label:t('customer', 'Cliente'),
            customRender: (_parameterValue, allRowData) => <RenderCustomer allRowData={allRowData} />,
            minWidth: 175,
            searchKey: 'Customer.name',
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('customerName', 'Nombre cliente') }
            },
        },
        {
            id:'TaskRepositoryAnswers',
            dataKey: 'TaskRepositoryAnswers',
            label: t('comments', 'Comentarios'),
            customRender: (_parameterValue, allRowData) => <div>{allRowData.answerCount}</div>,
        },
        {
            id: 'EmployeeId',
            dataKey: 'EmployeeId',
            simpleLabel: t('employee', 'Empleado'),
            label: () => <TwinIcon icon={faUser} className='w-16 h-16 mx-auto' />,
            customRender: (_parameterValue, allRowData) => <CustomRenderRoundedImage className='cursor-pointer h-34 w-34' icon={faUser} img={allRowData?.Employee?.profile_image || ''} name={allRowData?.Employee?.fullname_short} />,
            width: 50
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
        {
            id: 'delete',
            dataKey: 'id',
            label: '',
            width: 50,
            customRender: (parameterValue, allRowData) => <DeleteRow key={'delete' + parameterValue} {...{ parameterValue, allRowData }} onClick={(_idk, allRowData) => setOpened({ type: 'delete', allRowData })} />,
            havePermission: permissionCheck(userPermissions, 'delete')
        }
    ]

    return (
        <TwinTable name='TaskRepositories' columns={columns} getDataFrom='/api/app/task/repository/getAllTaskRepositories' setTableInstance={setTableInstance} rowRenderer={rowRenderer} hideLoadFiltersButton={true} onRowClick={(_id, rowData) => setOpened({
            type: 'edit',
            allRowData: rowData
        })} />
    )
}
interface RenderCustomerProps {
    allRowData: RowData
}

const RenderCustomer: React.FC<RenderCustomerProps> = ({ allRowData}) => {
    const idTooltip = useMemo(() => 'Tooltip_CustomRenderTaskRepositoryCustomer' + String(allRowData?.id), [allRowData?.id])
    if (!allRowData?.CustomerId) {
        return <div> -- </div>
    }
    const customerName = allRowData?.Customer?.name
    const customerId =allRowData?.Customer?.code ||allRowData?.Customer?.id
    const customerIdName = customerId + ' - ' + customerName
    return (
        <div className='flex flex-auto'>
            <TwinToolTip id={idTooltip} place='top' content={customerIdName} positionStrategy='fixed' />
            <div data-tooltip-id={idTooltip} className='flex items-center flex-auto' >
                <span className='flex-auto twin_elipsis w-1'>{customerIdName}</span>
            </div>
        </div>
    )
}



export default TaskRespositories