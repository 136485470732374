import { Order, TableConfiguration } from '../../types';

export const checkIfOrderExists = (tableConfiguration: TableConfiguration, dataKey: string, order: Order) => {
    if (tableConfiguration.order[dataKey] === order ) {
        return true
    }
    return false
}
export const checkIfOrderIsNull = (tableConfiguration: TableConfiguration, dataKey: string) => {
    if (tableConfiguration.order[dataKey] === null ) {
        return true
    }
    return false
}