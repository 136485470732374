import { WCalHolidayBagModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag'

export const timeControlEmployeePDFParseDates = (month: number, year: number, showYears: boolean) => {
    let dateStart = ''
    let dateEnd = ''
    if (showYears) {
        dateStart = new Date(year, 0, 1).toDateString()
        dateEnd = new Date(year, 12, 0).toDateString()
    } else {
        dateStart = new Date(year, month - 1, 1).toDateString()
        dateEnd = new Date(year, month, 0).toDateString()
    }

    return {dateStart, dateEnd}
}

export const parseHolidayBags = (holidayBags: WCalHolidayBagModelType[]) => {
    let holidayPendent = 0
    let holidayDone = 0
    let holidayTotal = 0
    for (const bag of holidayBags) {
        const originalDays = parseInt(String(bag.originalDays))
        const leftDays = parseInt(String(bag.leftDays))
        if(bag){ 
            holidayPendent += leftDays
            holidayDone += originalDays - leftDays
            holidayTotal += originalDays
        }
    }
    return { holidayPendent, holidayDone, holidayTotal }
}