import { faCalendar, faXmark } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useMemo, useRef, useState } from 'react'
import TwinCalendar from '../../../baseComponents/TwinCalendar'
import Input, { InputWithLabelMarginIcon } from '..'
import { ModalInputHour } from '../InputHour'
import { displayDateWithFormattedHours, displayFormatedHour, getDateFormat, getTimeFormat } from '../../../utils/globals/date'
import { HourMinTy, Modify } from '../../../utils/globals/types'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import './inputCalendarWithHours.sass'


export type InputCalendarWithHoursStateFullProps = Modify<InputCalendarWithHoursStateLessProps, InputCalendarWithHoursStateFullLogicProps & {
}>

export const InputCalendarWithHoursStateFull: React.FC<InputCalendarWithHoursStateFullProps> = ({ value, onChange, ...rest }) => {
    const { date, onChangeDate } = useInputCalendarStateFullLogic({ value })
    return (
        <InputCalendarWithHoursStateLess {...rest} onChange={onChangeDate} value={date} />
    )
}

interface InputCalendarWithHoursStateFullLogicProps {
    value: Date
}

const useInputCalendarStateFullLogic = ({ value }: InputCalendarWithHoursStateFullLogicProps) => {
    const [date, setDate] = useState<Date>(value)
    const onChangeDate = useCallback((val: Date) => {
        setDate(val)
    }, [setDate])

    return { date, onChangeDate }
}

export type InputCalendarWithHoursStateLessProps = TwinCalendarWithHoursModalProps & InputCalendarWithHoursStateLessLogicProps & {
    name?: string
    label: string
    onDeleteDate?: () => void
}

export const InputCalendarWithHoursStateLess: React.FC<InputCalendarWithHoursStateLessProps> = ({ readOnly, value, label, name, onDeleteDate, ...rest }) => {
    const { openCalendar, handleOpen, ref, memorizedPattern } = useInputCalendarWithHoursStateLessLogic({ readOnly })
    let dateFormatted = ''
    if (value) {
        dateFormatted = displayDateWithFormattedHours(value)
    }
    return (
        <div className={'input_calendar relative'} ref={ref}>
            <InputWithLabelMarginIcon {...rest} onClickIcon={onDeleteDate} {...{ readOnly }} value={dateFormatted} label={label} icon={value && onDeleteDate ? faXmark : faCalendar} onFocus={handleOpen} onChange={() => { }} pattern={memorizedPattern} className='w-full' />
            {name ? <input type='hidden' name={name} value={value?.toUTCString?.() || ''} /> : null}
            {openCalendar ? <TwinCalendarWithHoursModal {...rest} value={value} /> : null}
        </div>
    )
}

interface InputCalendarWithHoursStateLessLogicProps {
    readOnly?: boolean
}

const useInputCalendarWithHoursStateLessLogic = ({ readOnly }: InputCalendarWithHoursStateLessLogicProps) => {
    const [openCalendar, setOpenCalendar] = useState<boolean>(false)

    const ref = useRef<any>(null)

    const memorizedPattern = useMemo(() => {
        const formats = getDateFormat()
        const formatsTimes = getTimeFormat()
        let myFormat = formats[0].replaceAll('-', '/') + ' ' + formatsTimes[0]
        myFormat = myFormat.replace('D', '[0-9]{1,2}')
        myFormat = myFormat.replace('M', '[0-9]{1,2}')
        myFormat = myFormat.replace('YYYY', '(([0-9]{2})|([0-9]{4}))')
        myFormat = myFormat.replace('YY', '(([0-9]{2})|([0-9]{4}))')
        myFormat = myFormat.replace('H', '[0-9]{1,2}')
        myFormat = myFormat.replace('mm', '[0-9]{1,2}')
        myFormat = '^(' + myFormat + ')|(' + myFormat.replaceAll('/', '-') + ')$'
        return myFormat
    }, [])

    useOnClickOutside(ref, () => setOpenCalendar(false))

    const handleOpen = useCallback(() => {
        if (!readOnly) {
            setOpenCalendar(true)
        }
    }, [readOnly])

    return { openCalendar, handleOpen, ref, memorizedPattern }
}

interface TwinCalendarWithHoursModalProps extends TwinCalendarWithHoursModalLogicProps {
    defaultActiveStartDate?: Date
    minDate?: Date
}

const TwinCalendarWithHoursModal: React.FC<TwinCalendarWithHoursModalProps> = ({ defaultActiveStartDate, minDate, ...rest }) => {
    const { onChangeMins, openHourModal, ref, setOpenHourModal, onDateChange } = useTwinCalendarWithHoursModal({ ...rest })
    const hourMin: HourMinTy = { mins: rest.value?.getMinutes?.() || 0, hours: rest.value?.getHours?.() || 0 }
    const displayMins = displayFormatedHour(hourMin)
    return (
        <div className='flex flex-col absolute z-10 items-center input_cal_with_hours bg-white'>
            <TwinCalendar className='' onChange={onDateChange} value={rest.value} defaultActiveStartDate={defaultActiveStartDate} minDate={minDate} />
            <div onClick={() => setOpenHourModal(true)} ref={ref} className='flex items-center pt-12 pb-12 input_cal_with_hours_picker'>
                <Input className='w-50' readOnly={true} value={displayMins} />
                {openHourModal ? <ModalInputHour onFinalChangeModal={onChangeMins} value={hourMin} className='modal_input_hour_calendar' maxHours={23} /> : null}
            </div>
        </div>
    )
}

interface TwinCalendarWithHoursModalLogicProps {
    onChange: (date: Date) => void
    value?: Date
}

const useTwinCalendarWithHoursModal = ({ value, onChange }: TwinCalendarWithHoursModalLogicProps) => {
    const ref = useRef<any>(null)

    const [openHourModal, setOpenHourModal] = useState<boolean>(false)

    const onDateChange = useCallback((date: Date) => {
        const newDate = new Date(date)
        if (value) {
            newDate.setHours(value.getHours())
            newDate.setMinutes(value.getMinutes())
        }
        onChange(newDate)
    }, [onChange, value])

    const onChangeMins = useCallback((hour: HourMinTy) => {
        const d = value ? new Date(value) : new Date()
        d.setHours(hour.hours)
        d.setMinutes(hour.mins)
        onChange(d)
    }, [value, onChange])

    useOnClickOutside(ref, () => {
        setOpenHourModal(false)
    })

    return { onChangeMins, openHourModal, ref, setOpenHourModal, onDateChange }
}