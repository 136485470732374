import { CustomerModelType } from '@teinor/erp/types/company/customer'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import SingleFormLayout from '../../../../../../../../../baseComponents/Layout/SingleFormLayout'
import { FormRender } from '../../../../../../../../../forms/FormRenderer/types'
import { valueOrDefaultValue } from '../../../../../../../../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { useTypeIdNumber } from '../../../../../../../../../utils/hooks/useTypeIdNumber'
import { getActiveLangs } from '../../../../../../../../../utils/reducers/getters'
import { ButtonPrimary } from '../../../../../../../../../baseComponents/Button'
import { Fragment, useMemo, useState } from 'react'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import { ModalOpenedTy } from '../../../../../../../../../baseComponents/Modal/types'
import ModalContactChangeType from './ModalContactChangeType'
import { addDictionaryExtraLine } from '../../../../../../../../../utils/globals/dictionary'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../../../../../../../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../../../../../../../../utils/reducers'
import { EmployeesPayload } from '../../../../../../../../../utils/reducers/company/employees'


type MainContactProfileProps = ComponentWithPermissions & ReduxMainContactProfile & {
    basicData: CustomerModelType | null
    getData: () => Promise<void>
}

interface CustomerModelTypeExtended extends CustomerModelType {
    transform_to_client: string
}

const MainContactProfile: React.FC<MainContactProfileProps> = ({ basicData, userPermissions, getData, employees }) => {
    const { t } = useTwinTranslation()
    const { typesIdNumber } = useTypeIdNumber()
    const { opened, setOpened } = useMainContactProfileLogic()
    const myEmployees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(employees || {}))), [employees])


    const fields: FormRender<CustomerModelTypeExtended> = [
        {
            cols: 2,
            title: t('generalInformation', 'Información general'),
            elements: [
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true
                },
                {
                    name: 'fiscal_name',
                    label: t('fiscalName', 'Nombre fiscal'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'type_id_number',
                    label: t('typeIdNumber', 'Tipo de documento identificación'),
                    component: 'CustomSelect',
                    items: typesIdNumber,
                    value: basicData?.type_id_number,
                },
                {
                    name: 'id_number',
                    label: t('id_number', 'DNI'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                },
                {
                    name: 'code',
                    label: t('customerCode', 'Código de Cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'customer_card',
                    label: t('customerCard', 'Tarjeta de cliente'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'responsable_name',
                    label: t('responsableName', 'Nombre del responsable'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'ResponsibleEmployeeId',
                    label: t('OurResponsibleEmployee', 'Nuestro empleado responsable'),
                    component: 'CustomSelectWithSearchBar',
                    items: myEmployees || {},
                    fieldName: 'fullname_short',
                    sortItems: true
                }
            ]
        },
        {
            cols: 2,
            title: t('contactInformation', 'Datos de contacto'),
            elements: [
                {
                    name: 'phone',
                    label: t('phone', 'Teléfono'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'mobile',
                    label: t('mobile', 'Móvil'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'email',
                    label: t('email', 'Email'),
                    component: 'InputWithLabelMargin',
                    type: 'email',
                },
                {
                    name: 'web',
                    label: t('web', 'Página web'),
                    component: 'InputWithLabelMargin',
                    type: 'text'
                },
                {
                    name: 'LangId',
                    label: t('language', 'Idioma'),
                    component: 'CustomSelect',
                    items: getActiveLangs(),
                },
                {
                    name: 'newsletter',
                    label: t('newsletter', 'Newsletter'),
                    component: 'CheckboxMargin',
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: basicData?.id
                }
            ]
        },
    ]
    const parsedFields = valueOrDefaultValue(fields, userPermissions, basicData)

    const extraButtons = [
        <ButtonPrimary key='transformToClient' className='regular14 ml-10' type='button' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOpened(true) }}><TwinTrans transKey='saveAndTransformToClient'>Guardar y Transformar a cliente</TwinTrans></ButtonPrimary>
    ]
    return (
        <Fragment>
            <SingleFormLayout action='/api/app/customer/updateCustomer' onSubmit={getData} sections={parsedFields} userPermissions={userPermissions} extraButtons={extraButtons}>
            </SingleFormLayout>
            {opened ? <ModalContactChangeType setOpened={() => setOpened(null)} onSubmit={() => { }} allRowData={basicData || {}} /> : null}
        </Fragment>
    )
}

const useMainContactProfileLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    return { opened, setOpened }
}


const mainContactProfileDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
}

export type ReduxMainContactProfile = ConnectedProps<typeof mainContactProfileConnect>
const mapMainContactProfileTypesConnector = (state: AllReduxPayloads) => ({ employees: state.employees })
const mainContactProfileConnect = connect(mapMainContactProfileTypesConnector, mainContactProfileDispatch)

const mainContactProfileConnectLoading = withLoading(MainContactProfile, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }])

const ModalCreateMainContactProfileConnect = mainContactProfileConnect(mainContactProfileConnectLoading)


export default ModalCreateMainContactProfileConnect