import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { memo, useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { WhiteBox } from '../../../baseComponents/AppLayout/WhiteBox'
import { ButtonPrimary } from '../../../baseComponents/Button'
import { joinAndRemoveDuplicatedBars } from '../../../baseComponents/CreateRoutering/functions'
import { ComponentWithPermissions } from '../../../baseComponents/CreateRoutering/types'
import PermissionChecker from '../../../baseComponents/PermissionChecker'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { CreateLinkProps, getBasePath } from '../../../utils/globals/link'
import { getConfigParam, getUserInfo } from '../../../utils/reducers/getters'
import { addBottomNavAppElement } from '../../../utils/reducers/reduxDispatch'
import TaskTable from './TaskTable'
import { TableWhereSearchBarTy, TaskScreenTableBaseTy, TaskScreenTableInfoTy } from './types'
import useTaskConfigColumns from '../../../utils/hooks/tasks/useTaskConfigColumns'
import { ButtonTaskConfigColumnsWithModal } from './ModalTaskConfigColumnsTabs'
import { DictTaskTableHeaderTy, DictTaskTableHeaderTyExt, TaskTableHeaderTyExt } from './ModalTaskConfigColumnsTabs/types'
import { memoChecker } from '../../../utils/globals/components'
import moment from 'moment'
import { SearchBarWithDebounce } from '../../../baseComponents/SearchBar'
import { TableInstanceType } from '../../../baseComponents/TwinTable/types'
import CustomSelect from '../../../forms/CustomSelect'
import { InputCalendarStateFull } from '../../../forms/Input/InputCalendar'
import useTaskHeaderSelectorsDict from '../../../utils/hooks/tasks/useTaskHeaderSelectorsDict'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import NullComponent from '../../../baseComponents/NullComponent'
import { ColumnConfiguration } from '../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import { flushSync } from 'react-dom'


interface TaskScreenTableWhiteBoxProps extends TaskScreenTableProps { }

export const TaskScreenTableWhiteBox: React.FC<TaskScreenTableWhiteBoxProps> = ({ extraWhereParams, ...rest }) => {
    return (
        <WhiteBox className='flex flex-auto py-40 px-50 task_table_wb'>
            <TaskScreenTable {...rest} extraWhereParams={{ ...extraWhereParams }} />
        </WhiteBox>
    )
}

type TaskScreenTableProps = ComponentWithPermissions & TaskScreenTableLogicProps & TaskScreenTableBaseTy & {
    pageData: TaskScreenTableInfoTy
    name: string
    createRowDataLink: CreateLinkProps
    floatingTasks: boolean
    extraCreateTaskParams?: TwinDictionary
    extraHeaders?: { [key: string]: React.FC<any> }
}

const TaskScreenTable: React.FC<TaskScreenTableProps> = ({ name, columns, getDataFrom, createRowDataLink, listenSocketStr, pageData, extraWhereParams, floatingTasks, userPermissions, ...rest }) => {
    const { createTask, headers, setTableInstance, headerTableWhereParams, selectedColsFiltersHeader, setSelectedColsFiltersHeader, memorizedColumns, tableInstance, memorizedHeaders } = useTaskScreenTableLogic({ floatingTasks, columns, name, ...rest })
    const renderMyHeader = renderHeader(headers, memorizedHeaders)
    return (
        <div className='task_screen_table flex flex-col flex-auto w-1'>
            <div className='flex justify-between items-center'>
                <div className='regular18 task_table_title'>{pageData.title}</div>
                <div className='flex items-center'>
                    <ButtonTaskConfigColumnsWithModal name={name} columns={columns} setSelectedColsFiltersHeader={setSelectedColsFiltersHeader} selectedColsFiltersHeader={selectedColsFiltersHeader} headers={headers} tableInstance={tableInstance} />
                    <PermissionChecker userPermissions={userPermissions} permission='create'>
                        <ButtonPrimary className='ml-15' onClick={createTask}>
                            <TwinIcon icon={faPlus} className='mr-8' />
                            {pageData?.newText}
                        </ButtonPrimary>
                    </PermissionChecker>
                </div>
            </div>
            <div className='flex justify-end items-end mb-20 mt-15'>
                {tableInstance ? renderMyHeader : null}
            </div>
            <TaskTable generalSearchbar={headerTableWhereParams.generalSearchBar} {...{ name, getDataFrom, listenSocketStr, columns, createRowDataLink, floatingTasks, selectedColsFiltersHeader, setSelectedColsFiltersHeader, memorizedColumns, extraWhereParams, headerTableWhereParams, setTableInstance }} />
        </div>
    )
}


interface TaskScreenTableLogicProps {
    name: string
    limitPath: string
    floatingTasks: boolean
    extraCreateTaskParams?: TwinDictionary
    columns: ColumnConfiguration[]
}
export const useTaskScreenTableLogic = ({ name, limitPath, floatingTasks, extraCreateTaskParams, columns }: TaskScreenTableLogicProps) => {
    const [tableInstance, setTableInstance] = useState<TableInstanceType>(null)
    const [headerTableWhereParams, setHeaderTableWhereParams] = useState<TableWhereSearchBarTy>({ generalSearchBar: '', start_date: '', end_date: '', finished: 1, visibles: 0, assigned: 1, subtask: 1 })
    const { t } = useTwinTranslation()
    const configHeaders: DictTaskTableHeaderTy = {
        'general_searchbar': {
            id: 'general_searchbar',
            label: t('searchBar', 'Barra de búsqueda'),
        },
        'start_createdAt': {
            id: 'start_createdAt',
            label: t('from', 'Desde') + ' (' + t('createdAtDate', 'Fecha creación') + ')',
        },
        'end_createdAt': {
            id: 'end_createdAt',
            label: t('to', 'Hasta') + ' (' + t('createdAtDate', 'Fecha creación') + ')',
        },
        'start_date': {
            id: 'start_date',
            label: t('from', 'Desde') + ' (' + t('assignedDate', 'Fecha asignada') + ')',
            hideByDefault: true
        },
        'end_date': {
            id: 'end_date',
            label: t('to', 'Hasta') + ' (' + t('assignedDate', 'Fecha asignada') + ')',
            hideByDefault: true
        },
        'finished': {
            id: 'finished',
            label: t('status', 'Estado'),
        },
        'assigned': {
            id: 'assigned',
            label: t('assignation', 'Asignación'),
        },
        'visibles': {
            id: 'visibles',
            label: t('visibles', 'Visibles'),
        },
        'subTasks': {
            id: 'subTasks',
            label: t('subTasks', 'Subtareas'),
        },
    }
    const { selectedColsFiltersHeader, setSelectedColsFiltersHeader, memorizedColumns, memorizedHeaders } = useTaskConfigColumns({ name, columns, headers: configHeaders })

    const { dictAssignation, dictFinished, dictSubTasks, dictVisibles } = useTaskHeaderSelectorsDict()

    const finishedFilterValue = selectedColsFiltersHeader?.filters['finished_filter']?.value
    const assignedFilterValue = selectedColsFiltersHeader?.filters['assigned']?.value
    const visiblesFilterValue = selectedColsFiltersHeader?.filters['visibles']?.value
    const subtaskFilterValue = selectedColsFiltersHeader?.filters['subtask']?.value

    const changeHeaderTableWhereParams = useCallback((field: keyof TableWhereSearchBarTy, value: string | number) => {
        flushSync(async () => {
            let mustUpdate = true
            if ((field === 'end_date' && value !== '') || (field === 'end_createdAt' && value !== '')) {
                const dateEnd = moment(value).endOf('day').toDate()
                value = dateEnd.toUTCString()
            }
            await setHeaderTableWhereParams((old) => {
                if (old[field] !== undefined && old[field] === value) {
                    mustUpdate = false
                    return old
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                return { ...copyOld, [field]: value }
            })
            if (mustUpdate) {
                tableInstance?.getTableDataFromStart()
            }
        })
    }, [setHeaderTableWhereParams, tableInstance])

    const headers: DictTaskTableHeaderTyExt = {
        'general_searchbar': {
            ...configHeaders.general_searchbar,
            Component: SearchBarWithDebounce,
            props: { className: 'search_bar_table mr-auto', placeholder: t('searchKeywordDots', 'Busca por palabra clave...'), onChange: (value: string) => changeHeaderTableWhereParams('generalSearchBar', value), searchValue: '' }
        },
        'start_createdAt': {
            ...configHeaders.start_createdAt,
            Component: InputCalendarStateFull,
            props: { className: 'ml-30', label: t('from', 'Desde'), onlyValids: true, value: headerTableWhereParams['start_createdAt'], onChange: (value: string) => changeHeaderTableWhereParams('start_createdAt', value) }
        },
        'end_createdAt': {
            ...configHeaders.end_createdAt,
            Component: InputCalendarStateFull,
            props: { className: 'ml-30', label: t('to', 'Hasta'), onlyValids: true, value: headerTableWhereParams['end_createdAt'], onChange: (value: string) => changeHeaderTableWhereParams('end_createdAt', value) }
        },
        'start_date': {
            ...configHeaders.start_date,
            Component: InputCalendarStateFull,
            props: { className: 'ml-30', label: t('from', 'Desde'), onlyValids: true, value: headerTableWhereParams['start_date'], onChange: (value: string) => changeHeaderTableWhereParams('start_date', value) },
        },
        'end_date': {
            ...configHeaders.end_date,
            Component: InputCalendarStateFull,
            props: { className: 'ml-30', label: t('to', 'Hasta'), onlyValids: true, value: headerTableWhereParams['end_date'], onChange: (value: string) => changeHeaderTableWhereParams('end_date', value) },
        },
        'finished': {
            ...configHeaders.finished,
            Component: CustomSelect,
            props: { className: 'w-120 ml-30', label: t('status', 'Estado'), items: dictFinished, value: finishedFilterValue || headerTableWhereParams['finished'], onChange: (value: string) => changeHeaderTableWhereParams('finished', value), readOnly: finishedFilterValue !== undefined ? true : false }
        },
        'assigned': {
            ...configHeaders.assigned,
            Component: name !== 'tableTasksByUser' ? CustomSelect : NullComponent,
            props: { className: 'w-120 ml-30', label: t('assignation', 'Asignación'), items: dictAssignation, value: assignedFilterValue || headerTableWhereParams['assigned'], onChange: (value: string) => changeHeaderTableWhereParams('assigned', value), readOnly: assignedFilterValue !== undefined ? true : false }
        },
        'visibles': {
            ...configHeaders.visibles,
            Component: CustomSelect,
            props: { className: 'w-120 ml-30', label: t('visibles', 'Visibles'), items: dictVisibles, value: visiblesFilterValue || headerTableWhereParams['visibles'], onChange: (value: string) => changeHeaderTableWhereParams('visibles', value), readOnly: visiblesFilterValue !== undefined ? true : false },
        },
        'subTasks': {
            ...configHeaders.subTasks,
            Component: CustomSelect,
            props: { className: 'w-120 ml-30', label: t('subTasks', 'Subtareas'), items: dictSubTasks, value: subtaskFilterValue || headerTableWhereParams['subtask'], onChange: (value: string) => changeHeaderTableWhereParams('subtask', value), readOnly: subtaskFilterValue !== undefined ? true : false },
        },
    }

    const navigate = useNavigate()
    const location = useLocation()
    const basePath = getBasePath(location.pathname, limitPath)
    const user = getUserInfo()
    const createTask = useCallback(async () => {
        let WorkingCenterId = undefined
        const workingCenterByDefault = getConfigParam('assignTaskYourWorkingCenterByDefault')
        if (workingCenterByDefault && workingCenterByDefault === 'on') {
            WorkingCenterId = user?.WorkingCenterId
        }
        const createdTask = await twinFetchPostJSON('/api/app/task/createTask', { WorkingCenterId, ...extraCreateTaskParams })
        if (createdTask) {
            if (floatingTasks) {
                addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + createdTask.id, extraData: { id: createdTask.id, opened: true } })
            } else {
                navigate(joinAndRemoveDuplicatedBars(basePath, '/' + createdTask.id))
            }
        }
    }, [navigate, basePath, user?.WorkingCenterId, floatingTasks, extraCreateTaskParams])

    return { createTask, headers, setTableInstance, headerTableWhereParams, selectedColsFiltersHeader, setSelectedColsFiltersHeader, memorizedColumns, tableInstance, memorizedHeaders }
}

const renderHeader = (header: DictTaskTableHeaderTyExt, selectedHeader: TwinDictionary) => {
    const renderHeader: JSX.Element[] = []
    for (const key in selectedHeader) {
        const element = header[key]
        renderHeader.push(<TaskTableHeaderRenderer params={element} key={key} />)
    }
    return renderHeader
}
interface TaskTableHeaderRendererProps {
    params: TaskTableHeaderTyExt

}

export const TaskTableHeaderRenderer: React.FC<TaskTableHeaderRendererProps> = memo(({ params }) => {
    const MyComponent = params?.Component
    if (MyComponent) {
        return <MyComponent key={params.id} {...params.props} />
    }
    return null
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['params']))



export default TaskScreenTable