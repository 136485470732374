import { faArrowRotateBack } from '@fortawesome/pro-light-svg-icons'
import { useCallback } from 'react'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { VerticalSortItem } from '../../../../../baseComponents/Sorts/VerticalSortList/types'
import TwinForm from '../../../../../forms/TwinForm'
import { ModalCreateEditStructureLayout } from '../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import HeaderModalTaskConfigColumns from '../HeaderModalTaskConfigColumns'
import Checkbox from '../../../../../forms/Checkbox'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import TwinIcon from '../../../../../baseComponents/TwinIcon'
import VerticalSortList from '../../../../../baseComponents/Sorts/VerticalSortList'
import { ModalTaskConfigColumnsBaseProps } from '../types'
import { SelectedColumnsTy } from '../../../../../utils/hooks/useConfigColumns'
import { getTaskOnlyDefaultColsAndHeader, SelectedTaskColsFiltersHeader } from '../../../../../utils/hooks/tasks/useTaskConfigColumns'

interface ModalTaskConfigColumnsProps extends ModalTaskConfigColumnsLogicProps {
}

const ModalTaskConfigColumns: React.FC<ModalTaskConfigColumnsProps> = ({ tmpColumns, columns, onSave, headers, ...logic }) => {
    const { changeChecked, changeOrder, resetSelected } = useModalTaskConfigColumnsLogic({ tmpColumns, columns, onSave, headers,  ...logic })
    const { t } = useTwinTranslation()

    const firstColumn: JSX.Element[] = []
    const secondColumn: VerticalSortItem[] = []
    const premiumColumns: JSX.Element[] = []
    for (const key in columns) {
        const item = columns[key]
        if ((item.label || item.simpleLabel) && item.havePermission !== false && !item.justForFilter) {
            if (item.haveModule !== false) {
                const index = Object.keys(tmpColumns.selectedColumns).indexOf(item.id)
                const selected = index !== -1
                firstColumn.push(<div className='mt-15' key={key}><Checkbox label={item.simpleLabel || item.label as any} onChange={() => changeChecked(item.id)} checked={selected} readOnly={item.requiredColumn} /></div>)
                if (selected) {
                    secondColumn.push({
                        name: item.simpleLabel || item.label as any,
                        id: item.id,
                        order: index + 1
                    })
                }
            } else {
                premiumColumns.push(<div className='mt-15' key={key}><Checkbox label={item.simpleLabel || item.label as any} onChange={() => changeChecked(item.id)} checked={true} readOnly={true} /></div>)
            }
        }
    }
    secondColumn.sort((a, b) => a.order - b.order)

    return (
        <TwinForm beforeSubmitHandler={onSave} className='flex flex-col flex-auto'>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: <HeaderModalTaskConfigColumns {...{ tmpColumns, columns, setTmpColumns: logic.setTmpColumns, name: logic.name, headers }} />,
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} className='modal_negative_margin'>
                <div className='overflow-auto h-full w-full'>
                    <div className='flex flex-wrap w-full h-fit '>
                        <div className='w-fit max-w-300'>
                            <div className='flex items-center mb-20'>
                                <h4 className='mr-18 '><TwinTrans transKey='columnsToShow'>Columnas a mostrar</TwinTrans></h4>
                                <div className='flex items-center text-green-21 cursor-pointer' onClick={resetSelected}>
                                    <TwinIcon icon={faArrowRotateBack} fontSize={14} className='mr-8' />
                                    <span className='mt-2 lh-14'><TwinTrans transKey='byDefault'>Por defecto</TwinTrans></span>
                                </div>
                            </div>
                            {firstColumn}
                            {premiumColumns.length ? <div className='mt-20'>
                                <h2><TwinTrans transKey='columnsCouldBuy'>Columnas que podrías comprar</TwinTrans></h2>
                                {premiumColumns}
                            </div> : null}
                        </div>
                        <div className='border-r border-gray-D6 w-1 mx-75' ></div>
                        <div className='flex flex-col min-h-full flex-auto w-1'>
                            <h4 className='mb-20'><TwinTrans transKey='orderSelectedColumns'>Ordena las columnas seleccionadas</TwinTrans></h4>
                            <VerticalSortList items={secondColumn} setItems={(setItemsFake) => changeOrder(setItemsFake(secondColumn))} />
                        </div>
                    </div>
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface ModalTaskConfigColumnsLogicProps extends ModalTaskConfigColumnsBaseProps { }

const useModalTaskConfigColumnsLogic = ({ setTmpColumns, selectedColsFiltersHeader, columns, headers }: ModalTaskConfigColumnsLogicProps) => {
    const changeChecked = useCallback((itemKey: string) => {
        setTmpColumns((oldTmp: SelectedTaskColsFiltersHeader) => {
            let tmpCopy: SelectedTaskColsFiltersHeader = JSON.parse(JSON.stringify(oldTmp))
            const item = tmpCopy.selectedColumns[itemKey]
            if (item) {
                delete tmpCopy.selectedColumns[itemKey]
            } else {
                tmpCopy.selectedColumns = { ...tmpCopy.selectedColumns, [itemKey]: selectedColsFiltersHeader.selectedColumns[itemKey] || {} }
            }
            return { ...tmpCopy }
        })
    }, [selectedColsFiltersHeader, setTmpColumns])

    const changeOrder = useCallback((items: VerticalSortItem[]) => {
        setTmpColumns((oldTmp: SelectedTaskColsFiltersHeader) => {
            let columns: SelectedColumnsTy = {}
            for (const item of items) {
                columns[item.id] = selectedColsFiltersHeader.selectedColumns[item.id] || {}
            }
            return { ...oldTmp, selectedColumns: columns }
        })
    }, [selectedColsFiltersHeader, setTmpColumns])

    const resetSelected = useCallback(() => {
        setTmpColumns((oldTmp: SelectedTaskColsFiltersHeader) => {
            const defaultCols = getTaskOnlyDefaultColsAndHeader(columns, headers)
            const myColumns = { ...oldTmp, ...defaultCols, filters: oldTmp.filters }
            return myColumns
        })
    }, [columns, setTmpColumns, headers])

    return { setTmpColumns, changeChecked, changeOrder, resetSelected }
}


export default ModalTaskConfigColumns