import { faCalendar, faXmark } from '@fortawesome/pro-light-svg-icons'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { OnChangeDateCallback } from 'react-calendar'
import Input, { InputWithLabelMarginIcon, InputWithLabelMarginProps } from '..'
import TwinCalendar from '../../../baseComponents/TwinCalendar'
import TwinIcon from '../../../baseComponents/TwinIcon'
import { displayDate, getDateFormat, isValidDate, stringToDate } from '../../../utils/globals/date'
import useOnClickOutside from '../../../utils/hooks/useOnClickOut'
import { CustomChangeFunction } from '../types'


export type InputCalendarProps = InputCalendarLogicProps & InputWithLabelMarginProps & {
    defaultActiveStartDate?: Date
    onDeleteDate?:()=>void
}

const InputCalendarStateLess: React.FC<InputCalendarProps> = ({ value, label, className, onChange, name, defaultActiveStartDate, onDeleteDate, ...rest }) => {
    const { openCalendar, handleOpen, handleValue, ref, memorizedPattern } = useInputCalendarLogic({ onChange })
    const date = value ? stringToDate(value as any) : undefined
    return (
        <div className={'input_calendar relative ' + (className || '')} ref={ref}>
            <InputWithLabelMarginIcon value={value} label={label} icon={value !== '' && onDeleteDate ? faXmark : faCalendar} onFocus={handleOpen} onChange={onChange} onClickIcon={onDeleteDate} {...rest} pattern={memorizedPattern} className='w-full' />
            {name ? <input type='hidden' name={name} value={date ? date.toDateString() : ''} /> : null}
            {openCalendar && !rest.readOnly ? <TwinCalendar className='fixed z-10' onChange={handleValue} value={date || undefined} defaultActiveStartDate={defaultActiveStartDate}  /> : null}
        </div>
    )
}

export type InputCalendarStateFullProps = InputCalendarStateFullLogicProps & InputCalendarProps & {}

export const InputCalendarStateFull: React.FC<InputCalendarStateFullProps> = ({ value, onChange, onlyValids, onDeleteDate, ...rest }) => {
    const { date, onChangeDate, onBlurParseDate, myOnDeleteDate } = useInputCalendarStateFullLogic({ value, onChange, onlyValids })
    return (
        <InputCalendarStateLess {...rest} value={date} onChange={onChangeDate} onDeleteDate={myOnDeleteDate} onBlur={()=>onBlurParseDate()}  />
    )
}


interface InputCalendarStateFullLogicProps extends InputCalendarLogicProps {
    value?: string
    onlyValids?: boolean
}

interface InputCalendarLogicProps {
    onChange?: CustomChangeFunction
    readOnly?: boolean
}

const useInputCalendarStateFullLogic = ({ onlyValids, onChange, value }: InputCalendarStateFullLogicProps) => {
    const [date, setDate] = useState<string>(value ? displayDate(stringToDate(value)) : '')
    const onBlurParseDate = useCallback(() => {
        if (onlyValids || date === '') {
            if (date === '') {
                onChange?.('')
            } else if (isValidDate(date)) {
                const parsedDate = stringToDate(date)
                if (parsedDate) {
                    return onChange?.(parsedDate.toUTCString())
                }
            }else {
                setDate('')
                onChange?.('')
            }
            return false
        }
        return onChange?.(date)
    }, [date, onChange, onlyValids])

    const onChangeDate = useCallback((value: string) => {
        setDate(value)
    }, [setDate])

    const myOnDeleteDate = useCallback(() => {
        setDate('')
        onChange?.('')
    }, [setDate, onChange])
    
    useEffect(() => {
        if (value) {
            setDate((oldDate) => {
                const myNewDate = displayDate(stringToDate(value))
                if (myNewDate !== oldDate) {
                    return myNewDate
                }
                return oldDate
            })
        }
    }, [value])
    return { date, onBlurParseDate, onChangeDate, myOnDeleteDate }
}

export type RectangularInputCalendarStateLessProps = InputCalendarLogicProps & InputWithLabelMarginProps & {
    onDeleteDate?:()=>void
}

const RectangularInputCalendarStateLess: React.FC<InputCalendarProps> = ({ value, label, className, onChange, readOnly, onDeleteDate, ...rest }) => {
    const { openCalendar, handleOpen, handleValue, ref, memorizedPattern } = useInputCalendarLogic({ onChange, readOnly })
    const date = value ? stringToDate(value as any) : undefined
    return (
        <div className={'input_calendar relative flex flex-auto ' + (className || '')} ref={ref}>
            <div className={'flex flex-auto w-1 items-center border border-gray-D6 px-10 py-3 ' + (readOnly ? ' rectangular_readonly' : '')}>
                <Input className='w-1 flex-auto' value={value} onChange={onChange} onFocus={handleOpen} pattern={memorizedPattern} {...rest} readOnly={readOnly}></Input>
                <TwinIcon className='cursor-pointer' onClick={onDeleteDate && value !== undefined ? onDeleteDate : handleOpen} icon={onDeleteDate && value!== undefined? faXmark : faCalendar} />
            </div>
            {openCalendar && <TwinCalendar className='fixed z-10' onChange={handleValue} value={date || undefined} />}
        </div>
    )
}

export type RectangularInputCalendarStateFullProps = InputCalendarStateFullLogicProps & InputCalendarProps & {}

export const RectangularInputCalendarStateFull: React.FC<RectangularInputCalendarStateFullProps> = ({ value, onChange, onlyValids, ...rest }) => {
    const { date, onBlurParseDate, onChangeDate, myOnDeleteDate } = useInputCalendarStateFullLogic({ value, onChange, onlyValids })
    return (
        <RectangularInputCalendarStateLess {...rest} value={date} onChange={onChangeDate} onBlur={() => onBlurParseDate()} onDeleteDate={myOnDeleteDate}  />
    )
}

const useInputCalendarLogic = ({ onChange, readOnly }: InputCalendarLogicProps) => {
    const [openCalendar, setOpenCalendar] = useState<boolean>(false)
    const ref = useRef<any>(null)
    const memorizedPattern = useMemo(() => {
        const formats = getDateFormat()
        let myFormat = formats[0].replaceAll('-', '/')
        myFormat = myFormat.replace('D', '[0-9]{1,2}')
        myFormat = myFormat.replace('M', '[0-9]{1,2}')
        myFormat = myFormat.replace('YYYY', '(([0-9]{2})|([0-9]{4}))')
        myFormat = myFormat.replace('YY', '(([0-9]{2})|([0-9]{4}))')
        myFormat = '^(' + myFormat + ')|(' + myFormat.replaceAll('/', '-') + ')$'
        return myFormat
    }, [])
    useOnClickOutside(ref, () => setOpenCalendar(false))

    const handleOpen = useCallback(() => {
        if (!readOnly) {
            setOpenCalendar(true)
        }
    }, [readOnly])

    const handleValue: OnChangeDateCallback = useCallback((value, e) => {
        onChange?.(displayDate(value), e)
        if(ref.current){
            const input = ref?.current?.querySelector('.input_field_input')
            input.focus()
            setTimeout(() => {
                input.blur()
            }, 20)
        }
        setOpenCalendar(false)

    }, [onChange, ref])

    return { openCalendar, handleOpen, handleValue, ref, memorizedPattern }
}

export default InputCalendarStateLess